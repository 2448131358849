import * as storage from 'src/utils/storage';

export default async function download(target, option = {}, name = '') {
  try {
    const url = '/download';
    const data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': storage.findByKey('token'),
      },
      body: JSON.stringify({target, ...option}),
    })
    .then(response => response.blob());

    if (null === data) {
      throw new Error('system error');
    }

    let fileName = '';
    switch (target) {
    case 'project':
      fileName = '展覧会別.csv';
      break;
    case 'project-museum':
      fileName = '美術館別.csv';
      break;
    case 'museum-member':
      fileName = '加盟館情報.csv';
      break;
    case 'museum-guest':
      fileName = '非加盟館情報.csv';
      break;
    case 'answer-member':
      fileName = `アンケート回答-${name}-加盟館.csv`;
      break;
    case 'answer-guest':
      fileName = `アンケート回答-${name}-非加盟館.csv`;
      break;
    default:
      fileName = 'notfound.csv';
      break;
    }


    const trash = document.getElementById('trash');
    const link = document.createElement('a');

    if (window.navigator.msSaveOrOpenBlob) {
      // ie11
      const blobObject = new Blob([data]);
      link.addEventListener('click', () => {
        window.navigator.msSaveOrOpenBlob(blobObject, fileName);
      });
    } else {
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.setAttribute('download', fileName);
    }

    trash.appendChild(link);
    link.click();

    setTimeout(() => {
      trash.textContent = null;
    }, 1);

    return {errors: []};
  } catch (e) {
    return {
      errors: [e.message],
      data: {},
    };
  }
}
