import React, {useContext, useEffect} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';

function Notfound() {
  const [app, loading] = useContext(AppContext);

  useEffect(() => {
    loading(false);

    return () => {
      loading(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (true === app.isLoading) {
    return null;
  }

  return (
    <>
      <Header.Member />
      <section>お探しのページが見つかりません</section>
   </>
  );
}

export default Notfound;
