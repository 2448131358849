import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import Context from './context';

const type = 'notification';

function Notification() {
  const [data, dispatch] = useContext(Context);
  const state = data.notification;

  const sendAttribute = {
    name: 'send',
    data: [0, 1],
    state: state.send,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };
 
  const titleAttribute = {
    name: 'title',
    width: 514,
    state: state.title,
    onChange: (e) => dispatch({type, name: e.name, value: e.value}),
  };

  const bodyAttribute = {
    name: 'body',
    rows: 10,
    cols: 80,
    state: state.body,
    onChange: (e) => dispatch({type, name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <h3>メール配信設定</h3>
      <aside>
        メール配信を行う場合はチェックを付けメールタイトル・メール本文を入力してください。<br />
        公開日にメールが送信されます。
      </aside>
      <div><Input.Checkbox {...sendAttribute}>メール配信する</Input.Checkbox></div>
      {1 === state.send.value && (
        <>
          <div><Input.Text {...titleAttribute}>メールタイトル</Input.Text></div>
          <div><Input.Textarea {...bodyAttribute}>メール本文</Input.Textarea></div>
        </>
      )}
    </Section.Register>
  );
}

export default Notification;
