/**
 * set items
 * @param {object} object
 */
export function setItems(object = {}) {
  const keys = Object.keys(object);

  keys.forEach((key) => {
    localStorage.setItem(key, object[key]);
  });
}

/**
 * get items
 * @param {array} keys
 * @return {object}
 */
export function getItems(keys = []) {
  const values = {};

  keys.forEach((key) => {
    values[key] = localStorage.getItem(key) || null;
  });

  return values;
}

/**
 * find by key
 * @param {string} key
 * @return {object}
 */
export function findByKey(key) {
  return localStorage.getItem(key) || null;
}

/**
 * remove items
 * @param {array} keys
 */
export function removeItems(keys = []) {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
}
