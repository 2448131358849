import * as gql from 'src/utils/gql';

export async function findOne(_id) {
  const query = `{
    museum(_id: "${_id}") {
      _id
      role
      name
      admin {
        name
        nameKana
        email
      }
      original {
        name {
          ja
          en
        }
        prefecture
        zip
        address
        link {
          site
          tw
          fb
          ig
        }
        contact {
          department
          tel
          fax
        }
        staff {
          position
          nameFirst
          nameLast
          nameFirstKana
          nameLastKana
          work
          field
        }
      }
      annex {
        name {
          ja
          en
        }
        prefecture
        zip
        address
        link {
          site
          tw
          fb
          ig
        }
        contact {
          department
          tel
          fax
        }
        staff {
          position
          nameFirst
          nameLast
          nameFirstKana
          nameLastKana
          work
          field
        }
      }
      note
      registedBy
    }
  }`;

  const {
    errors,
    data: {
      museum = {},
    },
  } = await gql.query(query);
  return {errors, museum};
}

export async function remove(_id) {
  const query = `removeMuseum($_id: ID!) {
    removeMuseum(_id: $_id)
  }`;
  
  const {errors} = await gql.mutation(query, {_id});
  return {errors};
}

