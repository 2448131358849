import * as gql from 'src/utils/gql';
import upload from 'src/utils/upload';

export async function init(_id) {
  const query = `{
    project(_id: "${_id}") {
      state
      type
      new
      title
      subTitle
      body
      note
      contact
      publishedAt
      proposer
      proposerRole
      period {
        start {
          y
          m
          d
          about
        }
        end {
          y
          m
          d
          about
        }
        any
        note
      }
      file {
        src
        caption
      }
      subFile
      registedBy {
        _id
        role
        original {
          name {
            ja
          }
        }
      }
      registedAt
      updatedAt
    }
    join(projectId: "${_id}") {
      member {
        name
        museum {
          _id
          original {
            name {
              ja
            }
          }
        }
        new
        state
        period {
          start {
            y
            m
            d
            about
          }
          end {
            y
            m
            d
            about
          }
          pending
          note
        }
        note
      }
    }
    tmpJoins(projectId: "${_id}") {
      _id
      move
      member {
        museum
        state
        new
        period {
          start {
            y
            m
            d
            about
          }
          end {
            y
            m
            d
            about
          }
          pending
          note
        }
        note
      }
    }
  }`;
  const {errors, data: {project = {}, join = {}, tmpJoins = [], questionnaires = []}} = await gql.query(query);
  return {errors, project, join, tmpJoins, questionnaires};
}


export async function findTmpJoins(_id) {
  const query = `{
    tmpJoins(projectId: "${_id}") {
      _id
    }
  }`;
  const {errors, data: {tmpJoins = []}} = await gql.query(query);
  return {errors, tmpJoins};
}


export async function findQuestionnaires() {
  const query = `{
    questionnaires(type: "project") {
      _id
      title
    }
  }`;
  const {errors, data: {questionnaires = []}} = await gql.query(query);
  return {errors, questionnaires};
}

async function fileUpload(state) {
  const isSubFile = (null === state.subFile.data) ? false : true;
  const uploaded = state.file.some((item) => null !== item.src.data);

  if (false === uploaded && !isSubFile) {
    return {errors: [], uploads: [], subUpload: null};
  }

  const files = state.file.map((item) => item.src.data);
  const {errors, data: {uploads = [], subUpload = null}} = await upload(files, (isSubFile ? state.subFile.data : null));
  return {errors, uploads, subUpload};
}

export async function register(projectState, joinState, answerState) {
  {
    const {errors, uploads, subUpload} = await fileUpload(projectState);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      projectState.file[index].src.value = path;
    });

    if (subUpload) {
      projectState.subFile.value = subUpload;
    }
  }
  {
    const query = `createProject($input: PostProject!, $join: PostJoin!, $answer: PostAnswer) {
      createProject(input: $input, join: $join, answer: $answer) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, projectState);
    const join = gql.convertToInput({}, joinState);
    const answer = ('' === answerState.questionnaire.value) ? null : gql.convertToInput({}, answerState);
    return await gql.mutation(query, {input, join, answer});
  }
}

export async function edit(_id, projectState, joinState) {
  {
    const {errors, uploads, subUpload} = await fileUpload(projectState);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      if (projectState.file[index].src.data) {
        projectState.file[index].src.value = path;
      }
    });

    if (subUpload) {
      projectState.subFile.value = subUpload;
    }
  }

  {
    const move = {hold: [], consider: [], holdRemand: [], considerRemand: []};
    for (const key in joinState.member) {
      const member = joinState.member[key];

      console.log(member.state.value)

      switch (member.state.value) {
      case 'hold':
        move.hold.push(member.museum.value);
        break;
      case 'consider':
        move.consider.push(member.museum.value);
        break;
      case 'hold-remand':
        move.holdRemand.push(member.museum.value);
        break;
      case 'consider-remand':
        move.considerRemand.push(member.museum.value);
        break;
      default:
        break;
      }

      switch (member.state.value) {
      case 'consider-apply':
      case 'consider-remand':
      case 'hold-apply':
      case 'hold-remand':
      case 'cancel':
        member.state.value = member.state.org;
        member.period.pending.value = member.period.pending.org;
        member.period.start.y.value = member.period.start.y.org;
        member.period.start.m.value = member.period.start.m.org;
        member.period.start.d.value = member.period.start.d.org;
        member.period.start.about.value = member.period.start.about.org;
        member.period.end.y.value = member.period.end.y.org;
        member.period.end.m.value = member.period.end.m.org;
        member.period.end.d.value = member.period.end.d.org;
        member.period.end.about.value = member.period.end.about.org;
        member.period.note.value = member.period.note.org;
        member.note.value = member.note.org;
        break;
      default:
        break;
      }

      console.log(member.state)
    }
    
    const query = `updateProject($_id: ID!, $input: PostProject!, $join: PostJoin!, $move: PostProjectJoinMove!) {
      updateProject(_id: $_id, input: $input, join: $join, move: $move) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, projectState);
    const join = gql.convertToInput({}, joinState);
    return await gql.mutation(query, {_id, input, join, move});
  }
}
