import React, {useContext} from 'react';
import moment from 'moment';
import Badge from 'src/components/badge';
import AppContext from 'src/app/context';
import * as Input from 'src/components/input';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import Context from './context';

const limit = moment(new Date()).subtract(1, 'month');

function Notice() {
  const [app] = useContext(AppContext);
  const [notice] = useContext(Context);

  const createRowJsx = (data) => {
    return data.map((item, index) => {
      const to = `/notice/${item._id}`;
      const at = moment(item.publishedAt);
      const isNew = at.isAfter(limit);

      let badgeType = item.type;
      if  ('member' === badgeType) {
        if (!item.registedBy) {
          badgeType = '';
        } else if ('guest' === item.registedBy.role) {
          badgeType = 'guest';
        }
      }

      return (
        <li key={`notice-${index}`}>
          <span data-width="date">{at.format('YYYY.MM.DD')}</span>
          <span data-width="auto">
            {isNew && (<Badge type="new" />)}
            <Badge type={badgeType} />
            <Input.Link to={to}>{item.title}</Input.Link>
          </span>
        </li>
      );
    });
  };

  const adminRowJsx = createRowJsx(notice.admin);
  const memberRowJsx = createRowJsx(notice.member);

  return (
    <>
      <nav>
        <Input.Button to="/notice">お知らせ一覧</Input.Button>
      </nav>
      <section data-type="card">
        <h3>事務局からのお知らせ</h3>
        <ul className={ChartStyles.ul}>{adminRowJsx}</ul>
      </section>
      <section data-type="card">
        <h3>
          {app.isRoleAdmin && (<>加盟館・非加盟館からのお知らせ</>)}
          {app.isRoleMember && (<>加盟館からのお知らせ</>)}
          {app.isRoleGuest && (<>非加盟館からのお知らせ</>)}
        </h3>
        <ul className={ChartStyles.ul}>{memberRowJsx}</ul>
      </section>
    </>
  );
}

export default Notice;
