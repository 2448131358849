import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Dnd from 'src/components/dnd';
import Context from './context';
import * as Styles from 'src/css/screens/notice-register.module.scss';

const type = 'file';

function File() {
  const [state, dispatch] = useContext(Context);
  const _state = state.file;
  const rowJsx = _state.map((item, index) => {
    const attribute = {
      name: `file${index}`,
      state: item,
      onChange: (e) => dispatch({type, name: index, value: e.file}),
    };

    return (<span key={`file${index}`}><Input.File {...attribute} /></span>);
  });

  const dndState = {
    state: _state,
    callback: (e) => dispatch({type: type, behavior: 'dnd', value: e}),
    add: false,
    remove: false,
  };

  return (
    <section className={Styles.notification}>
      <h3>添付ファイル</h3>
      <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
    </section>
  );
}

export default File;
