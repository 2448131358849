import React from 'react';
import * as Styles from 'src/css/components/badge.module.scss';

function Badge(props) {
  const {
    type = 'default',
    children = null,
  } = props;

  let name = '';
  switch (type) {
  case 'admin':
    name = '事務局';
    break;
  case 'member':
    name = '加盟館';
    break;
  case 'guest':
    name = '非加盟館';
    break;
  case 'text':
    name = '回答';
    break;
  case 'project':
    name = '企画';
    break;
  case 'new':
    name = 'NEW';
    break;
  default:
    name = children;
    break;
  }

  return (
    <span className={Styles.badge} data-type={type}>{name}</span>
  );
}

export default Badge;
