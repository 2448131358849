import React from 'react';
import * as Styles from 'src/css/components/input.module.scss';

function Select(props) {
  const {
    children = null,
    required = false,
    readOnly = false,
  } = props;

  return (
    <div className={Styles.select} data-component="input">
      {null !== children && (
        <label data-required={true === required ? 'true' : 'false'}>
          {children}
        </label>
      )}
      {false === readOnly ? (
        <List {...props} />
      ) : (
        <ReadOnly {...props} />
      )}
    </div>
  );
}

function List(props) {
  const {
    data = [],
    name = '',
    onChange = () => {},
    state: {
      value: defaultValue = '',
      change = false,
    },
    className = null,
  } = props;

  const options = data.map((item, index) => {
    const [label, value] = item; 
    return (<option key={`${name}-${index}`} value={value}>{label}</option>); 
  });

  const attribute = {};
  if (null !== className) {
    attribute.className = className;
  }
  
  const isChange = (true === change ? 'true' : 'false');

  return (
    <select name={name} value={defaultValue} data-change={isChange} data-component="input" {...attribute} onChange={(e) => {
      let value = e.target.value;
      if (/^[0-9.]+$/.test(value)) {
        value = Number(value);
      }

      onChange({name: e.target.name, value: value});
    }}>
      {options}
    </select>
  );
}

function ReadOnly(props) {
  const {
    data = [],
    state: {
      value: defaultValue = '',
    },
  } = props;

  let text = null;
  for (const item of data) {
    const [label, value] = item;

    if ('' !== defaultValue && defaultValue === value) {
      text = label;
      break;
    }
  }
  
  return (
    <span className={Styles.readOnly}>{text}</span>
  );
}




export default Select;
