import React from 'react';
import {Link} from 'react-router-dom';
import * as Styles from 'src/css/components/input.module.scss';

function TextLink(props) {
  const {children = null, to = null} = props;
  return (<Link className={Styles.link} to={to}>{children}</Link>);
}

export default TextLink;
