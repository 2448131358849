import moment from 'moment';
import React, {useContext} from 'react';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import * as Common from 'src/utils/common';
import AppContext from 'src/app/context';
import Badge from 'src/components/badge';
import * as Anchor from 'src/components/anchor';
import * as Input from 'src/components/input';
import Context from './context';

const limit = moment(new Date()).subtract(1, 'month');

function Member() {
  const [, , , tab] = useContext(Context);

  return <Public />;
}

function Public() {
  const [app] = useContext(AppContext);
  const [state, , tabElementId, tab] = useContext(Context);
  const rowJsx = (state || []).map((item, index) => {
    return (<Row key={`${item.type}-${index}`} project={item} tab={tab} />);
  });

  return (<ul className={ChartStyles.ul}>{rowJsx}</ul>);
}

function Row(props) {
  const {project: item, tab} = props;
  const at = moment(item.publishedAt);
  const period = Common.convertProjectPeriodToText(item.period);
  
  const isBadge = ('default' === tab && (at.isAfter(limit) || 1 === item.new)) ? true : false;
  const to = `/project/${'default' !== tab ? `${tab}/` : ''}${item._id}`;

  return (
    <li data-state={item.state}>
      <span data-width="auto">
        {isBadge && (<Badge type="new" />)}
        <Input.Link to={to}>{item.title}</Input.Link>
      </span>
      <span data-width="period">{period}</span>
    </li>
  );
}

export default Member;
