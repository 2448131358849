import React from 'react';

export function convertFilePathToInfo(path) {
  const info = {
    name: '',
    type: '',
  };

  info.name = (path.split('/')).pop();

  if ((new RegExp(`^.+(.png|.jpg)$`)).test(info.name)) {
    info.type = 'image';
  } else {
    info.type = 'other';
  }

  return info;
}

export function convertFeedCodeToJsx(text, prefix = '') {
  return text.split('\n').map((t, index) => {
    return (<React.Fragment key={`${prefix}${index}`}>{t}<br /></React.Fragment>);
  });
}

export function convertProjectPeriodToText(period) {
  const {start = {}, end = {}, any = 0, note = '', pending = 0} = period || {};

  if (1 === any) {
    return '随時';
  }

  if (1 === pending) {
    return '開催時期未定';
  }

  const ymd = (period) => {
    const {y = '', m = '', d = '', about = ''} = period;
    
    let text = ''; 
    if ('' !== y) text += `${y}年 `;
    if ('' !== m) text += `${m}月 `;
    if ('' !== d) text += `${d}日 `;
    if ('' !== about) text += `${about} `;
    return text;
  };
 
  const _start = ymd(start);
  const _end = ymd(end);
  
  let text = '';
  if ('' !== _start) {
    text = `${_start}`;
  }

  if ('' !== _end) {
    text += ` ~ ${_end}`;
  }

  if ('' !== note) {
    text += `${note}`;
  }

  return text;
}
