import React, {useContext} from 'react';
import * as Svg from 'src/components/svg';
import * as Styles from 'src/css/components/loader.module.scss';
import AppContext from 'src/app/context';

function Loader() {
  const [app] = useContext(AppContext);

  const isLoading = (true === app.isLoading ? 'true' : 'false');
  const isSignin = (true === app.isSignin ? 'true' : 'false');
  
  return (
    <div className={Styles.screen} data-loading={isLoading} data-signin={isSignin}>
      <div><Svg.Logo /></div>
    </div>
  );
}

export default Loader;
