import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

const initialState = {
  state: {value: 'consider', error: null},
  name: {value: null, error: null},
  museum: {value: null, error: null},
  period: {
    start: {
      y: {value: '', error: null,},
      m: {value: '', error: null,},
      d: {value: '', error: null,},
      about: {value: '', error: null,},
    },
    end: {
      y: {value: '', error: null,},
      m: {value: '', error: null,},
      d: {value: '', error: null,},
      about: {value: '', error: null,},
    },
    note: {value: '', error: null},
    pending: {value: 0, error: null}, 
  },
  new: {value: 0, error: null},
  note: {value: '', error: null},
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return reducerTypeInitialize(action);
  case 'period':
    return reducerTypePeriod(_state, action);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeInitialize(action) {
  const state = cloneDeep(initialState);
  const {initialState: tmp = null, replace = false} = action;
  
  if (null === tmp) {
    return state;
  }

  if (replace) {
    return tmp;
  }
  
  const join = tmp.member;

  switch (join.state) {
  case 'hold-remand':
  case 'consider':
    state.state.value = 'hold-apply';
    break;
  case 'consider-remand':
    state.state.value = 'consider-apply';
    break;
  default:
    state.state.value = 'cancel';
    break;
  }

  state.museum.value = join.museum;
  state.note.value = join.note;

  state.period.start.y.value = join.period.start.y;
  state.period.start.m.value = join.period.start.m;
  state.period.start.d.value = join.period.start.d;
  state.period.start.about.value = join.period.start.about;
  state.period.end.y.value = join.period.end.y;
  state.period.end.m.value = join.period.end.m;
  state.period.end.d.value = join.period.end.d;
  state.period.end.about.value = join.period.end.about;
  state.period.note.value = join.period.note;
  state.period.pending.value = join.period.pending;

  return state;
}

function  reducerTypePeriod(state, action) {
  const {name = null, key = null, value = null} = action;
  const _state = state.period;
  
  switch (key) {
  case 'start':
  case 'end':
    _state[key][name].value = value;
    _state[key][name].error = checkTypePeriod(name, value);
    break;
  default:
    _state[name].value = value;
    _state[name].error = checkTypePeriod(name, value);
    break;
  }
  return state;
}

function checkTypePeriod(name, value) {
  let error = null;
  
  switch (name) {
  case 'y':
    error = validation(value, {numeric: true, max: 4});
    break;
  case 'm':
  case 'd':
    error = validation(value, {numeric: true, max: 2});
    break;
  case 'note':
    error = validation(value, {max: 50});
    break;
  default:
    break;
  }
  return error;
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;
  state[name].error = checkTypeDefault(name, value);
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;

  switch (name) {
  case 'note':
    error = validation(value, {require: true, max: 200});
    break;
  default:
    break;
  }
  return error;
}


export function checkAll(state) {
  const _state = cloneDeep(state);
  const errors = [];

  _state.note.error = checkTypeDefault('note', _state.note.value);
  errors.push(_state.note.error);

  const period = _state.period;
  period.start.y.error = checkTypePeriod('y', period.start.y.value);
  errors.push(period.start.y.error);

  period.start.m.error = checkTypePeriod('m', period.start.m.value);
  errors.push(period.start.m.error);

  period.start.d.error = checkTypePeriod('d', period.start.d.value);
  errors.push(period.start.d.error);

  period.end.y.error = checkTypePeriod('y', period.end.y.value);
  errors.push(period.end.y.error);

  period.end.m.error = checkTypePeriod('m', period.end.m.value);
  errors.push(period.end.m.error);

  period.end.d.error = checkTypePeriod('d', period.end.d.value);
  errors.push(period.end.d.error);

  period.note.error = checkTypePeriod('note', period.note.value);
  errors.push(period.note.error);

  return {error: errors.some(e => null !== e), _state};
}




export async function register(projectId, state) {
  const query = `createTmpJoinMember($projectId: ID!, $input: PostJoinMember!) {
    createTmpJoinMember(projectId: $projectId, input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  const {errors} = await gql.mutation(query, {projectId, input});
  return {errors, _id: projectId};
}


export async function edit(_id, state) {
  const query = `updateTmpJoinMember($_id: ID!, $input: PostJoinMember!) {
    updateTmpJoinMember(_id: $_id, input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  const {errors} = await gql.mutation(query, {_id, input});
  return {errors, _id};
}
