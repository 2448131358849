import cloneDeep from 'lodash.clonedeep';

export default class Base {
  constructor(props) {
    const [frame, row] = props;

    this.dndSelectors = {
      frame: `${frame}[data-dnd="frame"]`,
      row: `${row}[data-dnd="row"]`,
    };

    this.dndAttributes = {
      over: 'data-over',
      start: 'data-start',
      index: 'data-index',
      sheat: 'data-sheat',
    };

    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.ready = this.ready.bind(this);
    this.dragstart = this.dragstart.bind(this);
    this.dragover = this.dragover.bind(this);
    this.dragend = this.dragend.bind(this);
    this.dragleave = this.dragleave.bind(this);
    this.drop = this.drop.bind(this);
    this.getRow = this.getRow.bind(this);
    this.getRowIndex = this.getRowIndex.bind(this);
    this.getOriginalRowIndex = this.getOriginalRowIndex.bind(this);
    this.getFrame = this.getFrame.bind(this);
  }

  addRow (state, index, initialState = {}) {
    const _state = cloneDeep(state);
    if (null === index) {
      _state.push({});
    } else {
      _state.splice(index + 1, 0, initialState);
    }

    return _state;
  }

  deleteRow(state, index) {
    const _state = cloneDeep(state);
    _state.splice(index, 1);

    return _state;
  }

  ready(e) {
    const frame = this.getFrame(e);
    frame.setAttribute(this.dndAttributes.sheat, 'true');

    const row = this.getRow(e);
    row.draggable = true;
  }

  dragstart(e) {
    const row = this.getRow(e);
    row.setAttribute(this.dndAttributes.start, 'true');
  }

  dragover(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }

    const html = document.querySelector('html');
    if ('IE' === html.getAttribute('data-browser')) {
      return false;
    }

    const row = this.getRow(e);
    if ('true' !== row.getAttribute(this.dndAttributes.start)) {
      const overIndex = this.getRowIndex(e);
      const originalIndex = this.getOriginalRowIndex(e);

      if (overIndex < originalIndex) {
        row.setAttribute(this.dndAttributes.over, 'up');
      } else {
        row.setAttribute(this.dndAttributes.over, 'down');
      }
    }

    return false;
  }

  dragend(e) {
    const row = this.getRow(e);
    row.setAttribute(this.dndAttributes.start, 'false');
    row.draggable = false;
  
    const frame = this.getFrame(e);
    frame.setAttribute(this.dndAttributes.sheat, 'false');

    const rows = frame.querySelectorAll(this.dndSelectors.row);
    for (const row of rows) {
      row.setAttribute(this.dndAttributes.over, 'false'); 
    }
  }

  dragleave(e) {
    const row = this.getRow(e);
    row.setAttribute(this.dndAttributes.over, 'false');
  }

  drop(e, state) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  
    e.preventDefault();
  
    const _state = cloneDeep(state);
    const dropIndex = this.getRowIndex(e);
    const originalIndex = this.getOriginalRowIndex(e);

    if (dropIndex > originalIndex) {
      // 上から下
      _state.splice(dropIndex + 1, 0, _state[originalIndex]);
      _state.splice(originalIndex, 1);
    } else if (dropIndex < originalIndex) {
      // 下から上
      _state.splice(dropIndex, 0, _state[originalIndex]);
      _state.splice(originalIndex + 1, 1);
    }
  
    return _state;
  }

  getRow(e) {
    return e.target.closest(this.dndSelectors.row);
  }

  getRowIndex(e) {
    const row = this.getRow(e);
    return Number(row.getAttribute(this.dndAttributes.index));
  }

  getOriginalRowIndex(e) {
    const frame = this.getFrame(e);
    return Number(frame.querySelector(`${this.dndSelectors.row}[${this.dndAttributes.start}="true"]`).getAttribute(this.dndAttributes.index));
  }

  getFrame(e) {
    return e.target.closest(this.dndSelectors.frame);
  }
}
