import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Dnd from 'src/components/dnd';
import Context from '../context';
import * as Styles from 'src/css/screens/project-register.module.scss';

const type = 'file';

function File() {
  const [, , [state, dispatch]] = useContext(Context);
  const file = state.file;

  const rowJsx = file.map((item, index) => {
    const fileAttribute = {
      name: `file${index}`,
      accept: '.png,.jpg',
      state: item.src, 
      onChange: (e) => dispatch({type, key: index, name: 'src', value: e.file}),
    };

    const captionAttribute = {
      name: 'caption',
      state: item.caption,
      width: 250,
      rows: 1,
      onChange: (e) => dispatch({type, key: index, name: e.name, value: e.value}),
    };

    return (
      <React.Fragment key={`file-row-${index}`}>
        <span className={Styles.file}><Input.File {...fileAttribute} /></span>
        <span className={Styles.caption}><Input.Textarea {...captionAttribute} /></span>
      </React.Fragment>
    );
  });

  const dndState = {
    head: [
      ['ファイル', Styles.file],
      ['キャプション', Styles.caption],
    ],
    state: file,
    callback: (e) => dispatch({type: type, behavior: 'dnd', value: e}),
    add: false,
    remove: false,
  };

  return (
    <section className={Styles.notification}>
      <h3>画像</h3>
      <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
    </section>
  );
}

export default File;
