import React from 'react';
import Base from './base';
import * as Styles from 'src/css/components/dnd.module.scss';

const dnd = new Base(['tbody', 'tr']);

export default function Table(props) {
  const {
    prefix = 'dnd-tr',
    children = [],
    state,
    initialState = null,
    callback,
    add = true,
    remove = true,
  } = props;

  const rowAttributes = {
    onDragStart: dnd.dragstart,
    onDragLeave: dnd.dragleave,
    onDragOver: dnd.dragover,
    onDragEnd: dnd.dragend,
    onDrop: (e) => {
      callback(dnd.drop(e, state));
      return false;
    },
  };

  const dndRows = children.map((item, index) => {
    return (
      <tr className={Styles.tr} key={`${prefix}-${index}`} data-dnd="row" data-index={index} {...rowAttributes}>
        <td className={Styles.thumb}><span onMouseDown={dnd.ready} /></td>
        {item}
        {true === add && (
          <td className={Styles.button}><button onClick={() => callback(dnd.addRow(state, index, initialState))}>＋</button></td>
        )}
        {true === remove && (
          <td className={Styles.button}><button onClick={() => callback(dnd.deleteRow(state, index))}>ー</button></td>
        )}
      </tr>
    );
  });

  return (
    <tbody className={Styles.tbody} data-dnd="frame">{dndRows}</tbody>
  );
}
