import cloneDeep from 'lodash.clonedeep';
import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Dnd from 'src/components/dnd';
import Context from './context';
import * as Store from './store';
import * as Styles from 'src/css/screens/museum-register.module.scss';

const type = 'notification';

function Notification() {
  const [data, dispatch] = useContext(Context);
  const state = data.notification;
  
  const rowJsx = state.map((item, index) => {
    const nameAttribute = {
      name: 'name', 
      state: item.name,
      onChange: (e) => dispatch({type: type, index: index, name: e.name, value: e.value}),
    };

    const emailAttribute = {
      name: 'email', 
      state: item.email,
      width: 300,
      onChange: (e) => dispatch({type: type, index: index, name: e.name, value: e.value}),
    };

    return (
      <React.Fragment key={`notification-${index}`}>
        <span className={Styles.notificationName}><Input.Text {...nameAttribute} /></span>
        <span className={Styles.notificationEmail}><Input.Text {...emailAttribute} /></span>
      </React.Fragment>
    );
  });

  const addAttribute = {
    className: Styles.flatButton,
    onClick: () => dispatch({type: type, behavior: 'add'}),
  };

  const dndState = {
    prefix: `notification-dnd`,
    state: state,
    initialState: cloneDeep(Store.initialNotificationState),
    callback: (e) => {
      dispatch({type: type, behavior: 'dnd', value: e});
    },
    head: [
      ['氏名', Styles.notificationName],
      ['メールアドレス', Styles.notificationEmail],
    ],
  };

  return (
    <section className={Styles.notification}>
      <h3>メール送信先情報</h3>
      <div className={Styles.notificationWrap} data-count={rowJsx.length}>
        {0 < rowJsx.length ? (
          <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
        ) : (
          <div {...addAttribute}>メール送信先情報を追加する</div>
        )}
      </div>
    </section>
  );
}

export default Notification;
