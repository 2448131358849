import React from 'react';
import * as Styles from 'src/css/components/input.module.scss';

function Text(props) {
  const {
    children = null,
    name = '',
    onChange = () => {},
    onKeyPress = null,
    required = false,
    type = 'text',
    width = null,
    state: {
      value = '',
      error = null,
      change = false,
    },
    readOnly = false,
  } = props;

  const attributes = {};
  if (null !== width) {
    attributes.style = {width: `${width}px`};
  }

  if (null !== onKeyPress && 'function' === typeof onKeyPress) {
    attributes.onKeyPress = onKeyPress;
  }

  const isError = (null !== error ? 'true' : 'false');
  const isChange = (true === change ? 'true' : 'false');
  const isRequired = (true === required && false === readOnly ? 'true' : 'false');

  return (
    <div className={Styles.text} data-component="input" data-input-error={isError}>
      {null !== children && (
        <label data-required={isRequired}>
          {children}
        </label>
      )}
      {false === readOnly ? (
        <>
          <input type={type} name={name} value={value} data-change={isChange} autoComplete="new-password" {...attributes} onChange={(e) => {
            onChange({name: e.target.name, value: e.target.value});
          }} />
          <small>{error}</small>
        </>
      ) : (
        <span className={Styles.readOnly}>{value}</span>
      )}
    </div>
  );
}

export default Text;
