import React from 'react';
import DatePicker from "react-datepicker";
import * as Styles from 'src/css/components/input.module.scss';
import ja from 'date-fns/locale/ja'

function Picker(props) {
  const {
    name = '',
    className = '',
    children = null,
    onChange = () => {},
    required = false,
    time = null,
    state: {
      value = '',
      error = null,
    },
    format = 'yyyy/MM/dd HH:mm',
    placeholder = '',
    readOnly = false,
  } = props;

  const isError = (null !== error ? 'true' : 'false');
  const isTime = (true === time ?  'true' : 'false');
  const isRequired = (true === required && false === readOnly ? 'true' : 'false');

  return (
    <div className={`${Styles.date} ${className}`} data-time={isTime} data-input-error={isError}>
      {null !== children && (
        <label  data-required={isRequired}>
          {children}
        </label>
      )}
      <DatePicker
        popperClassName={Styles.popper}
        selected={value}
        showTimeSelect={time}
        timeFormat="HH:mm"
        timeIntervals={10}
        timeCaption="時間"
        dateFormat={format}
        locale={ja}
        placeholderText={placeholder}
        onChange={(e) => {
          onChange({name: name, value: e});
        }}
      />
      <small>{error}</small>
    </div>
  );
}

export default Picker;
