import React, {useContext} from 'react';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import Context from './context';

const type = 'custom';

function Custom() {
  const [state, dispatch] = useContext(Context);
  
  const noteAttribute = {
    name: 'note',
    cols: 100,
    rows: 10,
    state: state.note,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <div><Input.Textarea {...noteAttribute}>備考</Input.Textarea></div>
    </Section.Register>
  );
}

export default Custom;
