import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import AppContext from 'src/app/context';
import * as Screens from 'src/screens';
import Menu from 'src/components/menu';

function Member() {
  const [app] = useContext(AppContext);
  
  return (
    <BrowserRouter>
      <Menu />
      <article>
        <Switch>
          {app.isRoleAdmin && (<Route path="/tool" exact={true} component={Screens.Tool} />)}
          <Route path="/" exact={true} component={Screens.Home} />
          {/* 美術館情報 */}
          {app.isRoleGuest && (<Route path="/museum/:_id(me)/:mode(edit)" exact={true} component={Screens.MuseumRegister} />)}
          {app.isRoleMember && (<Route path="/museum" exact={true} component={Screens.MuseumChart} />)}
          {app.isRoleMember && (<Route path="/museum/:_id(me)/:mode(edit)" exact={true} component={Screens.MuseumRegister} />)}
          {!app.isRoleAdmin && (<Route path="/museum/:_id([0-9a-z]+)" exact={true} component={Screens.MuseumDetail} />)}
          {app.isRoleAdmin && (<Route path="/museum/:tab(guest|admin|draft)*" exact={true} component={Screens.MuseumChart} />)}
          {app.isRoleAdmin && (<Route path="/museum/:tab(guest|admin|draft)*:mode(register)" exact={true} component={Screens.MuseumRegister} />)}
          {app.isRoleAdmin && (<Route path="/museum/:tab(guest|admin|draft)*/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.MuseumRegister} />)}
          {app.isRoleAdmin && (<Route path="/museum/:tab(guest|admin|draft)*/:_id([0-9a-z]+)" exact={true} component={Screens.MuseumDetail} />)}
          {/* お知らせ */}
          <Route path="/notice/:mode(register)" exact={true} component={Screens.NoticeRegister} />
          {app.isRoleAdmin && (<Route path="/notice/:tab(all|draft)*" exact={true} component={Screens.NoticeChart} />)}
          {app.isRoleAdmin && (<Route path="/notice/:tab(all|draft)*/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.NoticeRegister} />)}
          {app.isRoleAdmin && (<Route path="/notice/:tab(all|draft)*/:_id([0-9a-z]+)" exact={true} component={Screens.NoticeDetail} />)}
          {!app.isRoleAdmin && (<Route path="/notice/:tab(proposer|remand)*" exact={true} component={Screens.NoticeChart} />)}
          {!app.isRoleAdmin && (<Route path="/notice/:tab(proposer|remand)*/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.NoticeRegister} />)}
          {!app.isRoleAdmin && (<Route path="/notice/:tab(proposer|remand)*/:_id([0-9a-z]+)" exact={true} component={Screens.NoticeDetail} />)}
          {/* アンケート */}
          {app.isRoleAdmin && (<Route path="/questionnaire/:mode(register)" exact={true} component={Screens.QuestionnaireRegister} />)}
          {app.isRoleAdmin && (<Route path="/questionnaire/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.QuestionnaireRegister} />)}
          <Route path="/questionnaire/:_id([0-9a-z]+)/:tab(member|guest)*" exact={true} component={Screens.QuestionnaireDetail} />
          <Route path="/questionnaire" exact={true} component={Screens.QuestionnaireChart} />
          {/* 企画 */}
          <Route path="/project/:mode(register)" exact={true} component={Screens.ProjectRegister} />
          {app.isRoleAdmin && (<Route path="/project/:tab(all|draft|join)*" exact={true} component={Screens.ProjectChart} />)}
          {app.isRoleAdmin && (<Route path="/project/:tab(all|draft|join)*/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.ProjectRegister} />)}
          {app.isRoleAdmin && (<Route path="/project/:tab(all|draft|join)*/:_id([0-9a-z]+)" exact={true} component={Screens.ProjectDetail} />)}
          {!app.isRoleAdmin && (<Route path="/project/:tab(proposer|join|remand)*" exact={true} component={Screens.ProjectChart} />)}
          {!app.isRoleAdmin && (<Route path="/project/:tab(proposer|join|remand)*/:_id([0-9a-z]+)/:mode(edit)" exact={true} component={Screens.ProjectRegister} />)}
          {!app.isRoleAdmin && (<Route path="/project/:tab(proposer|join|remand)*/:_id([0-9a-z]+)" exact={true} component={Screens.ProjectDetail} />)}
          <Route component={Screens.Notfound} />
        </Switch>
      </article>
    </BrowserRouter>
  );
}

export default Member;
