import cloneDeep from 'lodash.clonedeep';
import validation from 'src/utils/validation';

const initialMemberState = {
  name: {value: '', error: null, view: '', org: null},
  museum: {value: null, error: null, org: null},
  state: {value: 'hold', error: null, org: null},
  period: {
    start: {
      y: {value: '', error: null, org: null},
      m: {value: '', error: null, org: null},
      d: {value: '', error: null, org: null},
      about: {value: '', error: null, org: null},
    },
    end: {
      y: {value: '', error: null, org: null},
      m: {value: '', error: null, org: null},
      d: {value: '', error: null, org: null},
      about: {value: '', error: null, org: null},
    },
    note: {value: '', error: null, org: null},
    pending: {value: 0, error: null, org: null},
  },
  new: {value: 0, error: null, org: null},
  note: {value: '', error: null, org: null},
};

const initialState = {
  member: [],
};

export {initialMemberState};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return reducerTypeInitialize(action);
  case 'member':
    return reducerTypeMember(_state, action);
  case 'period':
    return reducerTypePeriod(_state, action);
  default:
    return _state;
  }
}

function reducerTypeInitialize(action) {
  const state = cloneDeep(initialState);
  const {initialState: join = null, replace = false, tmp = null} = action;
  
  if (null === join) {
    return state;
  }

  if (replace) {
    return join;
  }

  for (const _member of join.member) {
    const _state = cloneDeep(initialMemberState);
    let member = _member;

    if (null === member.museum) {
      // 事務局が登録した参加・検討情報
      _state.name.value = member.name;
    } else {
      // 加盟館が登録した参加・検討情報
      _state.museum.value = member.museum._id;
      _state.name.view = member.museum.original.name.ja;

      const museumId = _state.museum.value;

      // 申請データがあるかチェック
      for (const _tmp of tmp) {
        if (museumId === _tmp.member.museum) {
          // 申請データがある場合は上書き
          member = _tmp.member;
          break;
        }
      }
    }

    setMemberData('value', _state, member);
    setMemberData('org', _state, _member);
    
    // newだけは、常に公開データを使用する
    _state.new.value = _member.new;

    state.member.push(_state);
  }

  return state;
}


function setMemberData(type, a, b) {
  console.log(b.state)
  a.state[type] = b.state;
  a.new[type] = b.new;
  a.period.pending[type] = b.period.pending;
  a.period.start.y[type] = b.period.start.y;
  a.period.start.m[type] = b.period.start.m;
  a.period.start.d[type] = b.period.start.d;
  a.period.start.about[type] = b.period.start.about;
  a.period.end.y[type] = b.period.end.y;
  a.period.end.m[type] = b.period.end.m;
  a.period.end.d[type] = b.period.end.d;
  a.period.end.about[type] = b.period.end.about;
  a.period.note[type] = b.period.note;
  a.note[type] = b.note;
}


function reducerTypeMember(state, action) {
  const {behavior = null, value = null} = action;
  
  switch (behavior) {
  case 'dnd':
    state.member = value;
    break;
  case 'add':
    state.member.push(cloneDeep(initialMemberState));
    break;
  default:
    const {name = null, index = null} = action;
    state.member[index][name].value = value;
    state.member[index][name].error = checkTypeMember(name, value);
    break;
  }

  return state;
}

function checkTypeMember(name, value) {
  let error = null;
  
  switch (name) {
  case 'name':
    error = validation(value, {require: true, max: 50});
    break;
  default:
    break;
  }
  return error;
}

function  reducerTypePeriod(state, action) {
  const {name = null, key = null, index = null, value = null} = action;
  const _state = state.member[index].period;

  switch (key) {
  case 'start':
  case 'end':
    _state[key][name].value = value;
    _state[key][name].error = checkTypePeriod(name, value);
    break;
  default:
    _state[name].value = value;
    _state[name].error = checkTypePeriod(name, value);
    break;
  }
  return state;
}

function checkTypePeriod(name, value) {
  let error = null;
  
  switch (name) {
  case 'y':
    error = validation(value, {numeric: true, max: 4});
    break;
  case 'm':
  case 'd':
    error = validation(value, {numeric: true, max: 2});
    break;
  case 'note':
    error = validation(value, {max: 50});
    break;
  default:
    break;
  }
  return error;
}


export function checkAll(state) {
  const _state = cloneDeep(state);
  const errors = [];

  _state.member.forEach((item, index) => {
    if (null === item.museum.value) {
      item.name.error = checkTypeMember('name', item.name.value);
      errors.push(item.name.error);
    }

    const period = item.period;
    period.start.y.error = checkTypePeriod('y', period.start.y.value);
    errors.push(period.start.y.error);

    period.start.m.error = checkTypePeriod('m', period.start.m.value);
    errors.push(period.start.m.error);

    period.start.d.error = checkTypePeriod('d', period.start.d.value);
    errors.push(period.start.d.error);

    period.end.y.error = checkTypePeriod('y', period.end.y.value);
    errors.push(period.end.y.error);

    period.end.m.error = checkTypePeriod('m', period.end.m.value);
    errors.push(period.end.m.error);

    period.end.d.error = checkTypePeriod('d', period.end.d.value);
    errors.push(period.end.d.error);

    period.note.error = checkTypePeriod('note', period.note.value);
    errors.push(period.note.error);
  });

  return {error: errors.some(e => null !== e), _state};
}