import React, {useContext} from 'react';
import cloneDeep from 'lodash.clonedeep';
import * as Input from 'src/components/input';
import * as Dnd from 'src/components/dnd';
import * as Common from 'src/utils/common';
import AppContext from 'src/app/context';
import Context from '../context';
import * as Styles from 'src/css/screens/project-register.module.scss';
import * as Store from './store';


function Date(props) {
  const [app] = useContext(AppContext);
  const [, , , [, dispatch]] = useContext(Context);
  const {root: key, index, state} = props;
  const type = 'period';

  const yAttribute = {
    name: 'y',
    width: 50,
    state: state.y,
    onChange: (e) => dispatch({type, key, index, name: e.name, value: e.value}),
  };

  const mAttribute = {
    name: 'm',
    width: 50,
    state: state.m,
    onChange: (e) => dispatch({type, key, index, name: e.name, value: e.value}),
  };

  const dAttribute = {
    name: 'd',
    width: 50,
    state: state.d,
    onChange: (e) => dispatch({type, key, index, name: e.name, value: e.value}),
  };

  const aboutAttribute = {
    name: 'about',
    state: state.about,
    data: app.data.project.about,
    onChange: (e) => dispatch({type, key, index, name: e.name, value: e.value}),
  };

  return (
    <ul className={Styles.joinDate}>
      <li><dfn>{'start' === key ? '開幕' : '閉幕'}</dfn></li>
      <li><dfn>西暦&nbsp;&nbsp;</dfn><Input.Text {...yAttribute} /><dfn>年&nbsp;&nbsp;</dfn></li>
      <li><Input.Text {...mAttribute} /><dfn>月&nbsp;&nbsp;</dfn></li>
      <li><Input.Text {...dAttribute} /><dfn>日&nbsp;&nbsp;</dfn></li>
      <li><Input.Select {...aboutAttribute} /></li>
    </ul>
  );
}


function Join() {
  const [app, , , modal] = useContext(AppContext);
  const [, , , [state, dispatch]] = useContext(Context);
  const type = 'member';

  const rowJsx = state.member.map((item, index) => {
    let nameAttribute = {};
    let noteAttribute = null;
    if (null === item.museum.value) {
      nameAttribute = {
        name: 'name', 
        state: item.name,
        width: 160,
        onChange: (e) => dispatch({type, index, name: e.name, value: e.value}),
      };
    } else {
      nameAttribute = {
        readOnly: true,
        state: {value: item.name.view},
      };

      noteAttribute = {
        onClick: () => {
          modal({type: 'preview', jsx: (Common.convertFeedCodeToJsx(item.note.value))});
        },
      }
    }
    
    let joinState = [];
    if (!item.museum.value) {
      joinState = app.data.join.state.filter(e => (-1 < ['hold', 'consider'].indexOf(e[1])));
    } else  {
      joinState = app.data.join.state;
    }
    
    const stateAttribute = {
      name: 'state', 
      data: joinState,
      state: item.state,
      onChange: (e) => dispatch({type, index, name: e.name, value: e.value}),
    };

    const periodNoteAttribute = {
      name: 'note',
      state: item.period.note,
      width: 120,
      onChange: (e) => dispatch({type: 'period', index, name: e.name, value: e.value}),
    };

    const pendingAttribute = {
      name: `pending-${index}`,
      data: [0, 1],
      state: item.period.pending,
      onChange: (e) => dispatch({type: 'period', index, name: 'pending', value: e.value}),
    };

    const newAttribute = {
      name: `member-new-${index}`,
      data: [0, 1],
      state: item.new,
      onChange: (e) => dispatch({type, index, name: 'new', value: e.value}),
    };

    return (
      <React.Fragment key={`join-${index}`}>
        <span className={Styles.joinName}>
          <Input.Text {...nameAttribute} />
        </span>
        <span className={Styles.joinPeriod}>
          <div className={Styles.inputMargin}><Date root="start" index={index} state={item.period.start} /></div>
          <div><Date root="end" index={index} state={item.period.end} /></div>
        </span>
        <span className={Styles.joinNote}>
          <div className={Styles.inputMargin}><Input.Text {...periodNoteAttribute} /></div>
          <div className={Styles.checkBoxWrap}>
            <Input.Checkbox {...pendingAttribute}>未定</Input.Checkbox>
            <Input.Checkbox {...newAttribute}>NEW</Input.Checkbox>
          </div>
        </span>
        <span className={Styles.joinState}>
          <div className={Styles.inputMargin}><Input.Select {...stateAttribute} /></div>
          {noteAttribute && (
            <Input.Button {...noteAttribute}>連絡先</Input.Button>
          )}
        </span>
      </React.Fragment>
    );
  });


  const addAttribute = {
    className: Styles.flatButton,
    onClick: () => dispatch({type, behavior: 'add'}),
  };

  const dndState = {
    prefix: `member-dnd`,
    className: Styles.join,
    state: state.member,
    initialState: cloneDeep(Store.initialMemberState),
    callback: (e) => {
      dispatch({type, behavior: 'dnd', value: e});
    },
    head: [
      ['美術館名', Styles.joinName],
      ['開催・検討時期', Styles.joinPeriod],
      ['補足', Styles.joinNote],
      ['', Styles.joinState],
    ],
  };

  return (
    <section className={Styles.notification}>
      <h3>参加・検討館</h3>
      <div className={Styles.notificationWrap} data-count={rowJsx.length}>
        {0 < rowJsx.length ? (
          <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
        ) : (
          <div {...addAttribute}>参加・検討館を追加する</div>
        )}
      </div>
    </section>
 
 );
}

export default Join;

