import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import Context from './context';
import Main from './main';
import Join from './join';
import * as ScreenStyles from 'src/css/screens/project-detail.module.scss';

function ProjectDetail(props) {
  const [app, loading, toast, modal] = useContext(AppContext);
  const [reloadState, setReloadState] = useState(0);
  const [state, setState] = useState(null);
  const {params: {_id = null, tab = null}} = props.match || {};

  const init = async (_id) => {
    const {errors, project, join, tmpJoinMe} = await Store.find(_id);

    if (0 < errors.length || null === project) {
      setState(null);
      toast('error');
    } else {
      setState({_id, project, join, tmpJoinMe});
    }

    loading(false);
  }

  useEffect(() => {
    init(_id);
    
    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, reloadState]);

  if (true === app.isLoading || null === state) {
    return null;
  }
  
  const removeAttribute = {
    type: 'remove',
    onClick: () => {
      modal({
        type: 'remove',
        okHandler: async () => {
          if (true === app.isLoading) {
            return;
          }

          loading(true);

          const {errors} = await Store.remove(_id);
          if (0 === errors.length) {
            toast('remove');
            props.history.push('/project');
          } else {
            toast('error');
            loading(false);
          }
        },
        cancelHandler: () => {},
      });
    },
  };

  const project = state.project;
  // const canEdit = (app.isRoleAdmin || ('tmp' === project.state || 'remand' === project.state || 'draft' === project.state)) ? true : false;
  const canEdit = ((app.isRoleAdmin || project.registedBy._id === app._id) || ('tmp' === project.state || 'remand' === project.state || 'draft' === project.state)) ? true : false;
  // const canRemove = (app.isRoleAdmin || 'tmp' === project.state) ? true : false;
  const canRemove = ((app.isRoleAdmin || project.registedBy._id === app._id) || 'tmp' === project.state) ? true : false;
  const canJoined = (!app.isRoleAdmin && 'public' === project.state) ? true : false;
  const basePath = `/project${null !== tab ? `/${tab}` : ''}`;

  return (
    <Context.Provider value={[state, () => {setReloadState(reloadState + 1)}]}>
      <Header.Member pathname={props.location.pathname} params={{title: state.project.title}} />
      <nav className={ScreenStyles.nav}>
        <Input.Button onClick={() => props.history.push(basePath)}>戻る</Input.Button>
        <Input.Button type="print" onClick={() => window.print()}>印刷</Input.Button>
        {canEdit && (<Input.Button to={`/project/${_id}/edit`}>編集</Input.Button>)}
        {canRemove && (<Input.Button {...removeAttribute}>削除</Input.Button>)}
      </nav>
      <Main />
      {canJoined && (<Join />)}
    </Context.Provider>
  );
}

export default ProjectDetail;
