import React, {useRef, useEffect, useContext, useReducer} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import Main from './main';
import File from './file';
import Notification from './notification';
import Context from './context';

function QuestionnaireRegister(props) {
  const title = useRef(null);
  const [app, loading, toast] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, null);
  const {params: {_id = null, mode = null}} = props.match || {};

  const init = async () => {
    if (null !== _id && 'edit' === mode) {
      const {errors, questionnaire} = await Store.init(_id);
      
      if (0 < errors.length) {
        toast('error');
      } else if (null === questionnaire) {
        props.history.push('/404');
      } else {
        // 編集
        title.current = questionnaire.title.value;
        dispatch({type: 'initialize', initialState: questionnaire});
      }
    } else {
      // 新規登録
      dispatch({type: 'initialize'});
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }
      
      const {error, _state} = Store.checkAll(state, mode);
      if (true === error) {
        dispatch({type: 'initialize', initialState: _state});
        toast('warning');
        return;
      }

      loading(true);
      const {
        errors,
      } = ('edit' === mode ? await Store.edit(state, _id) : await Store.register(state));

      if (0 === errors.length) {
        toast('save');
        props.history.push('/questionnaire');
      } else {
        toast('error');
        loading(false);
      }
    },
  };

  if (true === app.isLoading || null === state) {
    return null;
  }

  return (
    <Context.Provider value={[state, dispatch]}>
      <Header.Member pathname={props.location.pathname} params={{title: title.current, _id}} />
      <Main />
      <File />
      <Notification />
      <nav data-align="left"><Input.Button {...saveAttribute}>保存</Input.Button></nav>
    </Context.Provider>
  );
}

export default QuestionnaireRegister;
