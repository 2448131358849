import React, {useState, useEffect, useContext} from 'react';
import moment from 'moment';
import AppContext from 'src/app/context';
import * as Common from 'src/utils/common';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import * as Styles from 'src/css/screens/notice-detail.module.scss';

function NoticeDetail(props) {
  const [app, loading, toast, modal] = useContext(AppContext);
  const [state, setState] = useState(null);
  const {params: {_id = null, tab = null}} = props.match || {};

  const init = async (_id) => {
    const {errors, notice} = await Store.find(_id);

    if (0 < errors.length) {
      setState(null);
      toast('error');
    } else {
      setState(notice);
    }

    loading(false);
  }

  useEffect(() => {
    init(_id);
    
    return () => {
      loading(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  if (true === app.isLoading || null === state) {
    return null;
  }
  
  const removeAttribute = {
    type: 'remove',
    onClick: () => {
      modal({
        type: 'remove',
        okHandler: async () => {
          if (true === app.isLoading) {
            return;
          }

          loading(true);

          const {errors} = await Store.remove(_id);
          if (0 === errors.length) {
            toast('remove');
            props.history.push('/notice');
          } else {
            toast('error');
            loading(false);
          }
        },
        cancelHandler: () => {},
      });
    },
  };

  const fileJsx = (state.file.filter(e => '' !== e)).map((src, index) => {
    if ('' !== src) {
      const {name, type} = Common.convertFilePathToInfo(src);
      const attribute = {
        'data-icon': type,
        target: '_blank',
        href: src,
      };
      return (<li key={`file${index}`}><a {...attribute}>{name}</a></li>);
    } else {
      return null;
    }
  });

  const canEdit = (app.isRoleAdmin || ('remand' === state.state || 'draft' === state.state)) ? true : false;
  const canDelete = app.isRoleAdmin;
  const viewRegistedBy = ('member' === state.type && state.registedBy && state.registedBy.original);
  const basePath = `/notice${null !== tab ? `/${tab}` : ''}`;

  return (
    <>
      <Header.Member pathname={props.location.pathname} params={{title: state.title}} />
      <nav>
        <Input.Button onClick={() => props.history.push(basePath)}>戻る</Input.Button>
        {canEdit && (<Input.Button to={`${basePath}/${_id}/edit`}>編集</Input.Button>)}
        {canDelete && (<Input.Button {...removeAttribute}>削除</Input.Button>)}
      </nav>
      <section data-type="card">
        <div className={Styles.date}>{moment(state.publishedAt).format('YYYY.MM.DD')}</div>
        <h2>{state.title}</h2>
        <div className={Styles.body} dangerouslySetInnerHTML={{__html : state.body}} />
        {viewRegistedBy && (
          <div className={Styles.contact}>
            <h3>投稿館名</h3>
            <div>{state.registedBy.original.name.ja}</div>
          </div>
        )}
        {'' !== state.contact && (
          <div className={Styles.contact}>
            <h3>連絡先</h3>
            <div>{Common.convertFeedCodeToJsx(state.contact)}</div>
          </div>
        )}
        {0 < fileJsx.length && (
          <div className={Styles.file}>
            <h3>ファイル</h3>
            <ul>{fileJsx}</ul>
          </div>
        )}
      </section>
    </>
  );
}

export default NoticeDetail;
