import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import Papa from 'papaparse';






function Tool() {
  const [app, loading] = useContext(AppContext);
  const [state, setState] = useState([]);

  useEffect(() => {
    loading(false);

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (true === app.isLoading) {
    return null;
  }



  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = ((e) => {
      const prefectureNames = app.data.prefectures.map(e => e[0]);
      const result = Papa.parse(e.target.result);
      const csv = result.data.slice(1);
      const tmp = [];

      let isAnnex = false;
      let saveName = '';

      for (const line of csv) {
        const [
          nameJa1,
          nameEn1,
          nameJa2,
          nameEn2,
          adminName,
          adminNameKana,
          userId,
          password,
          adminEmail,
          linkSite,
          zip1,
          zip2,
          prefecture,
          address1,
          address2,
          note,
          telfax,
          ,
          staff = '',
        ] = line;

        if (nameJa1 !== saveName) {
          saveName = nameJa1;
          isAnnex = false;
        } else {
          isAnnex = true;
        }

        const museum = {
          id: userId,
          password: password,
          role: 'member',
//          role: 'guest',
          indent: 0,
          name: nameJa1,
          order: tmp.length,
          admin: {
            name: adminName,
            nameKana: adminNameKana,
            email: adminEmail,
          },
          original: {
            name: {
              ja: ('' === nameJa2 ? nameJa1 : nameJa2),
              en: ('' === nameEn2 ? nameEn1 : nameEn2),
            },
            link: {
              site: (isAnnex ? '' : linkSite),
              fb: '',
              tw: '',
              ig: '',
            },
            area: 0,
            prefecture: 0,
            zip: [zip1, zip2],
            address: [address1, address2],
            staff: [],
            contact: [],
          },
          annex: [],
          notification: [],
          note: note,
        };

        const pIndex = prefectureNames.indexOf(prefecture);
        if (-1 < pIndex) {
          museum.original.prefecture = app.data.prefectures[pIndex][1];

          for (const area of app.data.areas) {
            const [, id, group] = area;

            if (-1 < group.indexOf(museum.original.prefecture)) {
              museum.original.area = id;
              break;
            }
          }
        } else {
          continue;
        }

        {
          const contact = (telfax.replace(/\r?\n/g, '|')).split('|');
          for (let i = 0, len = Math.ceil(contact.length / 3); i < len; i++) {
            const department = contact[i * 3] || '';
            const tel = contact[(i * 3) + 1] || '';
            const fax = contact[(i * 3) + 2] || '';

            if ('' === department && '' === tel && '' === fax) {
              continue;
            }

            const item = {
              department,
              tel: ['', '', ''],
              fax: ['', '', ''],
            };

            if ('' !== tel) {
              const [
                tel1 = '',
                tel2 = '',
                tel3 = '',
              ] = (tel.replace('tel:', '')).split('-');
              item.tel = [tel1, tel2, tel3];
            }

            if ('' !== fax) {
              const [
                fax1 = '',
                fax2 = '',
                fax3 = '',
              ] = (fax.replace('fax:', '')).split('-');
              item.fax = [fax1, fax2, fax3];
            }

            museum.original.contact.push(item);
          }
        }

        {
          const cell = (staff.replace(/\r?\n/g, '|')).split('|');
        
          for (let i = 0, len = Math.ceil(cell.length / 5); i < len; i++) {
            const position = cell[i * 5] || '';
            const name = cell[(i * 5) + 1] || '';
            const nameKana = cell[(i * 5) + 2] || '';
            const work = cell[(i * 5) + 3] || '';
            const field = cell[(i * 5) + 4] || '';

            if ('' === position && '' === name && '' === nameKana && '' === work && '' === field) {
              continue;
            }

            const item = {
              position,
              nameFirst: '',
              nameLast: '',
              nameFirstKana: '',
              nameLastKana: '',
              work,
              field,
            };

            if ('' !== name) {
              let [
                last = '',
                first = '',
              ] = name.split('　');

              let [
                l = '',
                f = '',
              ] = name.split(' ');

              if ('' === last && '' !== l) {
                last = l;
              }
              if ('' === first && '' !== f) {
                first = f;
              }

              item.nameFirst = first;
              item.nameLast = last;
            }

            if ('' !== nameKana) {
              let [
                last = '',
                first = '',
              ] = nameKana.split('　');

              let [
                l = '',
                f = '',
              ] = nameKana.split(' ');

              if ('' === last && '' !== l) {
                last = l;
              }
              if ('' === first && '' !== f) {
                first = f;
              }

              item.nameFirstKana = first;
              item.nameLastKana = last;
            }

            museum.original.staff.push(item);
          }
        }

        if (isAnnex) {
          tmp[tmp.length - 1].annex.push(museum.original);
        } else {
          tmp.push(museum);
        }
      }

      setState(tmp);

    });

    reader.readAsText(file);
  } 


  return (
    <section>
      <input type="file" onChange={onChange} />

      <div>
        {JSON.stringify(state)}
      </div>
    </section>
  );
}

export default Tool;




