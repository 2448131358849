import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import * as Svg from 'src/components/svg';
import * as Styles from 'src/css/components/header.module.scss';
import AppContext from 'src/app/context';

function Member(props) {
  const [app] = useContext(AppContext);
  const {pathname = '', params = {}} = props;
  const list = breadcrumb(app, pathname, params);
 
  const count = list.length;
  const listJsx = list.map((item, index) => {
    const [name = '', to = ''] = item;
    const isLast = ((index + 1) === count ? 'true' : 'false');
    return (
      <span key={`breadcrumb-${index}`} data-last={isLast}>
        <Link to={to}>{name}</Link>
      </span>
    );
  });

  const myPageUrl = `/museum/${app._id}${(app.isRoleAdmin ? '/edit' : '')}`;
  listJsx.push(
    <span key="breadcrumb-account">
      <Link to={myPageUrl}><Svg.Icon type="account" />{app.name}</Link>
    </span>
  );

  return (
    <header className={Styles.member}>
      <nav>{listJsx}</nav>
    </header>
  );
}

export default Member;


function breadcrumb(app, pathname, params = {}) {
  const keys = pathname.split('/');
  const items = [];
  
  keys.shift();

  switch (keys[0]) {
  case 'notice':
    return notice(app, keys, params);
  case 'project':
    return project(app, keys, params);
  case 'museum':
    return museum(app, keys, params);
  case 'questionnaire':
    return questionnaire(keys, params); 
  default:
    return items;
  }
}

function project(app, keys, params) {
  const items = [['展覧会企画', '/project']];

  switch (keys[1] || 'chart') {
  case 'chart':
    items.push(['公開', '/project']);
    break;
  case 'all':
    items.push(['企画一覧', '/project/all']);
    break;
  case 'proposer':
    items.push(['提案', '/project/proposer']);
    break;
  case 'remand':
    if (app.isRoleAdmin) {
      items.push(['申請・差戻し', '/project/remand']);
    } else {
      items.push(['差戻し', '/project/remand']);
    }
    break;
  case 'draft':
    items.push(['企画承認依頼', '/project/draft']);
    break;
  case 'join':
    if (app.isRoleAdmin) {
      items.push(['開催・検討承認依頼', '/project/join']);
    } else {
      items.push(['開催・検討', '/project/join']);
    }
    break;
  case 'register':
    items.push(['新規登録']);
    break;
  default:
    const {title = '', _id = ''} = params;
    items.push([title, `/project/${_id}`]);
    break;
  }

  switch (keys[2] || null) {
  case 'edit':
    items.push(['編集']);
    break;
  default:
    break;
  }
 
  return items;
}

function notice(app, keys, params) {
  const items = [['お知らせ', '/notice']];
  
  switch (keys[1] || 'chart') {
  case 'chart':
    items.push(['公開', '/notice']);
    break;
  case 'all':
    items.push(['記事一覧　', '/notice/all']);
    break;
  case 'proposer':
    items.push(['提案', '/notice/proposer']);
    break;
  case 'remand':
    items.push(['差戻し', '/notice/remand']);
    break;
  case 'draft':
    items.push(['承認依頼', '/notice/draft']);
    break;
  case 'register':
    items.push(['新規登録']);
    break;
  default:
    const {title = '', _id = ''} = params;
    items.push([title, `/notice/${_id}`]);
    break;
  }

  switch (keys[2] || null) {
  case 'edit':
    items.push(['編集']);
    break;
  default:
    break;
  }

  return items;
}

function questionnaire(keys, params) {
  const items = [['アンケート', '/questionnaire']];

  switch (keys[1] || 'chart') {
  case 'chart':
    break;
  case 'register':
    items.push(['新規登録']);
    break;
  default:
    const {title = '', _id = '', tab = ''} = params;
    items.push([title, `/questionnaire/${_id}`]);

    switch (tab) {
    case 'member':
      items.push(['加盟館', `/questionnaire/${_id}/member`]);
      break;
    case 'guest':
      items.push(['非加盟館', `/questionnaire/${_id}/guest`]);
      break;
    default:
      break;
    }
    break;
  }

  switch (keys[2] || null) {
  case 'edit':
    items.push(['編集']);
    break;
  default:
    break;
  }

  return items;
}

function museum(app, keys, params) {
  const items = [];
  if (!app.isRoleGuest) {
    items.push(['美術館情報', '/museum']);
  }
  
  switch (keys[1] || 'chart') {
  case 'chart':
  case 'member':
    if (app.isRoleAdmin) {
      items.push(['加盟館', '/museum/member']);
    }
    break;
  case 'draft':
    items.push(['承認依頼', '/museum/draft']);
    break;
  case 'guest':
    items.push(['非加盟館', '/museum/guest']);
    break;
  case 'admin':
    items.push(['事務局', '/museum/admin']);
    break;
  case 'register':
    items.push(['新規登録']);
    break;
  default:
    const {title = '', _id = '', role = ''} = params;

    if ('admin' === role) {
      items.push([title, `/museum/${_id}/edit`]);
    } else {
      items.push([title, `/museum/${_id}`]);
    }
    break;
  }

  switch (keys[2] || null) {
  case 'edit':
    items.push(['編集']);
    break;
  default:
    break;
  }

  return items;
}