import React, {useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import AppContext from 'src/app/context';
import * as Svg from 'src/components/svg';
import * as Styles from 'src/css/components/menu.module.scss';
import * as storage from 'src/utils/storage';

function Menu() {
  const [app] = useContext(AppContext);
  const signout = () => {
    storage.removeItems(['token', 'exp']);
    window.location.href = '/';
  };

  let manual = '/storage/manual/';
  if (app.isRoleAdmin) {
    manual += '[事務局]美連協ポータルサイト操作マニュアル.pdf';
  } else if (app.isRoleMember) {
    manual += '[加盟館]美連協ポータルサイト操作マニュアル.pdf';
  } else {
    manual += '[非加盟館]美連協ポータルサイト操作マニュアル.pdf';
  }

  const manualAttribute = {
    href: manual,
    target: '_blank',
    'data-type': 'manual',
  };

  return (
    <nav className={Styles.nav}>
      <header>
        <h1><Link to="/" className={Styles.logo}><Svg.Logo /></Link></h1>
      </header>
      <ul>
        <li><NavLink to="/" exact activeClassName={Styles.active}>HOME</NavLink></li>
        <li><NavLink to="/notice" activeClassName={Styles.active}>お知らせ</NavLink></li>
        {!app.isRoleGuest && (
          <li><NavLink to="/museum" activeClassName={Styles.active}>美術館情報</NavLink></li>
        )}
        <li><NavLink to="/project" activeClassName={Styles.active}>展覧会企画</NavLink></li>
        <li><NavLink to="/questionnaire" activeClassName={Styles.active}>アンケート</NavLink></li>
        {!app.isRoleGuest && (
          <li><a {...manualAttribute}>マニュアル</a></li>
        )}
      </ul>
      <div className={Styles.signout}>
        <span onClick={signout}><Svg.Icon type="signout" />ログアウト</span>
      </div>
    </nav>
  );
}

export default Menu;
