import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as Screens from 'src/screens';

function Guest() {
  return (
    <BrowserRouter>
      <article>
        <Switch>
          <Route path='/forgot-password' exact={true} component={Screens.ForgotPassword} />
          <Route path='/reset-password/:token' exact={true} component={Screens.ResetPassword} />
          <Route component={Screens.Signin} />
        </Switch>
      </article>
    </BrowserRouter>
  );
}

export default Guest;
