import moment from 'moment';
import React, {useContext} from 'react';
import AppContext from 'src/app/context';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import Badge from 'src/components/badge';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import Context from './context';

function Admin() {
  const [app] = useContext(AppContext);
  const [state, url, tab, limit] = useContext(Context);

  const tabAttribute = {
    active: url,
    to: [
      ['公開', '/notice'],
      ['記事一覧', '/notice/all'],
      ['承認依頼', '/notice/draft'],
    ],
  };

  const rowJsx = state.map((item, index) => {
    const to = `/notice/${'default' !== tab ? `${tab}/` : ''}${item._id}`;
    const at = moment(item.publishedAt);
    const isNew = (('default' === tab) && at.isAfter(limit));

    let label = app.label.states[item.state] || null;
    if ('public' === item.state && at.isAfter(moment())) {
      label = '予約済';
    }

    let badgeType = item.type;
    if  ('member' === badgeType) {
      if (!item.registedBy) {
        badgeType = '';
      } else if ('guest' === item.registedBy.role) {
        badgeType = 'guest';
      }
    }

    return (
      <tr key={`chart-${index}`} data-state={item.state}>
        <td data-strong="true">{label}</td>
        <td>
          {isNew && (<Badge type="new" />)}
          <Badge type={badgeType} />
          <Input.Link to={to}>{item.title}</Input.Link>
        </td>
        <td>{at.format('YYYY.MM.DD')}</td>
        <td>{app.label.targets[item.target] || null}</td>
      </tr>
    );
  });

  return (
    <Section.TabLink {...tabAttribute}>
      <table className={ChartStyles.table}>
        <thead>
          <tr>
            <th data-width="state">公開状態</th>
            <th data-width="auto">タイトル</th>
            <th data-width="date">公開日</th>
            <th data-width="target">対象</th>
          </tr>
        </thead>
        <tbody>{rowJsx}</tbody>
      </table>
    </Section.TabLink>
  );
}

export default Admin;
