import * as gql from 'src/utils/gql';

export async function findAll(tab) {
  const query = `{
    museums(target: "${tab}") {
      _id
      id
      name
      admin {
        name
      }
      indent
      order
      original {
        name {
          ja
          en
        }
        link {
          site
          tw
          fb
          ig
        }
        area
        prefecture
        contact {
          tel
          fax
        }
      }
    }
  }`;

  const {errors, data: {museums = []}} = await gql.query(query);
  return {errors, museums};
}

export async function edit(state) {
  const query = `updateMuseumSortOrder($input: PostMuseumSortOrder!) {
    updateMuseumSortOrder(input: $input)
  }`;

  const input = {
    _ids: state.map(item => item._id),
  };

  return await gql.mutation(query, {input});
}
