import moment from 'moment';
import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import Badge from 'src/components/badge';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as store from './store';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import * as ScreenStyles from 'src/css/screens/questionnaire-chart.module.scss';

const Context = React.createContext([{}, () => {}]);
const limit = moment(new Date()).subtract(1, 'month');

function QuestionnaireChart(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, setState] = useState(null);

  const init = async () => {
    const {errors, questionnaires} = await store.findAll();

    if (0 < errors.length) {
      setState([]);
      toast('error');
    } else {
      setState(questionnaires);
    }

    loading(false);
  }

  useEffect(() => {
    init();
    
    return () => {
      loading(true);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (true === app.isLoading || null === state) {
    return null;
  }

  const canRegister = app.isRoleAdmin;

  return (
    <>
      <Header.Member pathname={props.location.pathname} />
      {canRegister && (<nav><Input.Button to={'/questionnaire/register'}>新規登録</Input.Button></nav>)}
      <Context.Provider value={[state]}>
        <section data-type="card">
          {app.isRoleAdmin ? (
            <AdminChart />
          ) : (
            <MemberChart />
          )}
        </section>
      </Context.Provider>
    </>
  );
}

function AdminChart() {
  const [app] = useContext(AppContext);
  const [state] = useContext(Context);

  const rowJsx = state.map((item, index) => {
    const to = `/questionnaire/${item._id}`;
    const startAt = moment(item.startAt);
    const endAt = moment(item.endAt);
    
    const isNew = startAt.isAfter(limit);
    const isClose = endAt.isBefore(moment());

    let label = app.label.states[item.state] || null;
    if ('public' === item.state && startAt.isAfter(moment())) {
      label = '予約済';
    }

    return (
      <tr key={`chart-${index}`} data-state={item.state}>
        <td data-strong="true">
          {isClose ? (
            <span className={ScreenStyles.strong}>終了</span>
          ) : (
            <>{label}</>
          )}
        </td>
        <td>
          {isNew && (<Badge type="new" />)}
          <Badge type={item.type} />
          <Input.Link to={to}>{item.title}</Input.Link></td>
        <td>{startAt.format('YYYY.MM.DD')}</td>
      </tr>
    );
  });

  return (
    <table className={ChartStyles.table}>
      <thead>
        <tr>
          <th data-width="state">公開状態</th>
          <th data-width="auto">タイトル</th>
          <th data-width="date">公開日</th>
        </tr>
      </thead>
      <tbody>{rowJsx}</tbody>
    </table>
  );
}


function MemberChart() {
  const [state] = useContext(Context);

  const rowJsx = state.map((item, index) => {
    const to = `/questionnaire/${item._id}`;
    const at = moment(item.startAt);
    const isNew = at.isAfter(limit);

    return (
      <li key={`questionnaire-${index}`}>
        <span data-width="date">{at.format('YYYY.MM.DD')}</span>
        <span data-width="auto">
          {isNew && (<Badge type="new" />)}
          <Badge type={item.type} />
          <Input.Link to={to}>{item.title}</Input.Link>
        </span>
      </li>
    );
  });

  return (
    <>
      <ul className={ChartStyles.ul}>{rowJsx}</ul>
      {0 === rowJsx.length  && (
        <div>現在実施中のアンケートはありません</div>
      )}
    </>
  );
}

export default QuestionnaireChart;
