import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import upload from 'src/utils/upload';
import validation from 'src/utils/validation';

const initialState = {
  target: {value: 'all', error: null},
  state: {value: 'private', error: null},
  publishedAt: {value: new Date(), error: null},
  title: {value: '', error: null},
  body: {value: '', error: null},
  contact: {value: '', error: null},
  file: [
    {value: '', error: null, data: null},
    {value: '', error: null, data: null},
    {value: '', error: null, data: null},
  ],
  notification: {
    send: {value: 0, error: null},
    title: {value: '', error: null},
    body: {value: '', error: null},
  },
};

/**
 * update state
 * @param {initialState} state
 * @param {object} action
 * @return {initialState}
 */
export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch(type) {
  case 'initialize':
    return reducerTypeInitialize(_state, action);
  case 'notification':
    return reducerTypeNotification(_state, action);
  case 'file':
    return reducerTypeFile(_state, action);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeInitialize(state, action) {
  const {isRoleAdmin = false, isRoleMember = false, isRoleGuest = false, replace = false} = action;
  const _initialState = action.initialState || cloneDeep(initialState);

  if (!isRoleAdmin && !replace) {
    _initialState.state.value = 'draft';

    if (isRoleMember) {
      _initialState.target.value = 'member';
    } else if (isRoleGuest) {
      _initialState.target.value = 'guest';
    }
  }

  return _initialState;
}

function reducerTypeFile(state, action) {
  const {behavior = null, name = null, value = null} = action;
  const _state = state.file;

  switch (behavior) {
  case 'dnd':
    state.file = value;
    break;
  default:
    if (null === value) {
      _state[name].value = '';
      _state[name].data = null;
    } else {
      _state[name].value = value.name;
      _state[name].data = value;
    }
    
    _state[name].error = checkTypeFile(value);
    break;
  }

  return state;
}

function checkTypeFile(value) {
  return validation(value, {fileSizeMax: 2000000});
}

function reducerTypeNotification(state, action) {
  const {name = null, value = null} = action;
  const _state = state.notification;
  _state[name].value = value;
  _state[name].error = checkTypeNotification(name, value);

  switch (name) {
  case 'send':
    _state.title.value = '';
    _state.body.value = '';
    break;
  default:
    break;
  }
  
  return state;
}

function checkTypeNotification(name, value) {
  let error = null;
  switch (name) {
  case 'title':
    error = validation(value, {max: 100});
    break;
  case 'body':
    error = validation(value, {max: 500});
    break;
  default:
    break;
  }

  return error;
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;
  state[name].error = checkTypeDefault(name, value);
  
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;
  switch (name) {
  case 'title':
    error = validation(value, {require: true, max: 100});
    break;
  case 'body':
    error = validation(value, {require: true, max: 4000});
    break;
  case 'publishedAt':
    error = validation(value, {require: true});
    break;
  case 'contact':
    error = validation(value, {max: 1000});
    break;
  default:
    break;
  }

  return error;
}


export function checkAll(state) {
  const _state = cloneDeep(state);
  let error = false;

  for (const key of ['title', 'body', 'publishedAt', 'contact']) {
    const item = _state[key];
    item.error = checkTypeDefault(key, item.value);

    if (null !== item.error) {
      error = true;
    }
  }
  
  for (const item of _state.file) {
    item.error = checkTypeFile(item.data);

    if (null !== item.error) {
      error = true;
    }
  }
  
  for (const key of ['title', 'body']) {
    const item = _state.notification[key];
    item.error = checkTypeNotification(key, item.value);

    if (null !== item.error) {
      error = true;
    }
  }
 
  return {error, _state};
}

export async function init(_id, isRoleAdmin) {
  const query = `{
    notice(_id: "${_id}") {
      body
      file
      contact
      type
      notification {
        send
        title
        body
      }
      publishedAt
      state
      target
      title
      registedBy {
        _id
        role
        name
        original {
          name {
            ja
          }
        }
      }
      registedAt
    }
  }`;

  const {
    errors,
    data,
  } = await gql.query(query);

  if (0 < errors.length || null === data.notice) {
    return {
      errors: errors,
      notice: null,
    }
  }

  const _state = cloneDeep(initialState);
  const {
    body,
    file,
    publishedAt,
    state,
    target,
    title,
    contact,
    notification,
    registedBy,
    type,
  } = data.notice; 

  _state.body.value = body;
  _state.publishedAt.value = new Date(publishedAt);
  _state.state.value = state;
  _state.target.value = target;
  _state.title.value = title;
  _state.contact.value = contact;
  
  for (let i = 0, len = file.length; i < len; i++) {
    if (!_state.file[i]) {
      continue;
    }
    _state.file[i].value = file[i];
  }

  _state.notification.send.value = notification.send;
  _state.notification.title.value = notification.title;
  _state.notification.body.value = notification.body;

  let owner = null;
  if (registedBy) {
    owner = {
      _id: registedBy._id,
      role: registedBy.role,
      name: '',
    };

    if ('admin' === registedBy.role) {
      owner.name = registedBy.name;
    } else {
      owner.name = registedBy.original.name.ja;
    }
  }

  return {
    errors,
    notice: _state,
    owner,
    type,
  };
}


async function fileUpload(state) {
  const uploaded = state.file.some((item) => null !== item.data);
  if (false === uploaded) {
    return {errors: [], uploads: []};
  }

  const files = state.file.map((item) => item.data);
  const {errors, data: {uploads = []}} = await upload(files);
  return {errors, uploads};
}

export async function register(state) {
  // 先にファイルをアップロード（結果にアップロードファイルのパスが入っている
  {
    const {errors, uploads} = await fileUpload(state);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      state.file[index].value = path;
    });
  }
  {
    const query = `createNotice($input: PostNotice!) {
      createNotice(input: $input) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, state);
    const {errors} = await gql.mutation(query, {input});
    return {errors};
  }
}

export async function edit(state, _id) {
  // 先にファイルをアップロード（結果にアップロードファイルのパスが入っている
  {
    const {errors, uploads} = await fileUpload(state);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      if (null !== state.file[index].data) {
        state.file[index].value = path;
      }
    });
  }
  {
    const query = `updateNotice($_id: ID!, $input: PostNotice!) {
      updateNotice(_id: $_id, input: $input) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, state);
    const {errors} = await gql.mutation(query, {_id, input});
    return {errors, _id};
  }
}
