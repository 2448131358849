import moment from 'moment';

/**
 * validation
 * @param {*} value
 * @param {object} types
 * @return {string}
 */
export default function validation(value, types = {}) {
  const keys = Object.keys(types);
  let message = null;

  main:
  for (let i = 0, iLen = keys.length; i < iLen; i++) {
    const key = keys[i];

    switch (key) {
    case 'require':
      if (true === types[key] && !required(value)) {
        message = '未入力です。';
        break main;
      }
      break;
    case 'alphaNumId':
      if (true === types[key] && !alphaNumId(value)) {
        message = '半角英数字で入力してください。';
        break main;
      }
      break;
    case 'alphaNum':
      if (true === types[key] && !alphaNum(value)) {
        message = '半角英数字で入力してください。';
        break main;
      }
      break;
    case 'numeric':
      if (true === types[key] && !numeric(value)) {
        message = '半角数字で入力してください。';
        break main;
      }
      break;
    case 'numericDecimal':
      if (true === types[key] && !numeric(value, 'decimal')) {
        message = '半角数字で入力してください。';
        break main;
      }
      break;
    case 'max':
      if (!max(value, types[key])) {
        message = `${types[key]}文字以内で入力してください。`;
        break main;
      }
      break;
    case 'digit':
      if (!digit(value, types[key])) {
        message = `${types[key][0]}桁（小数点以下 ${types[key][1]}桁）で入力してください。`;
        break main;
      }
      break;
    case 'min':
      if (!min(value, types[key], keys.indexOf('require'))) {
        message = `${types[key]}文字以上で入力してください。`;
        break main;
      }
      break;
    case 'email':
      if (true === types[key] && !email(value)) {
        message = 'メールアドレスの形式が誤っています。';
        break main;
      }
      break;
    case 'date':
      switch (types[key]) {
      case 'past':
        if (!date(value, 'past')) {
          message = '未来の日付を入力してください。';
          break main;
        }
        break;
      default:
        break;
      }
      break;
    case 'fileSizeMax':
      if (!fileSizeMax(value, types[key])) {
        message = `ファイルサイズの上限（${types[key] / 1000000}M）を超えています。`;
        break main;
      }
      break;
    default:
      break;
    }
  }

  return message;
};

/**
 * 必須入力チェック
 * @param {*} value
 * @return {boolean}
 */
function required(value = null) {
  let result = false;

  if (null === value) {
    return result;
  }

  switch (typeof value) {
  case 'string':
    result = (0 < (value.trim()).length ? true : false);
    break;
  case 'object':
    if (true === Array.isArray(value)) {
      result = (0 < (Object.keys(value)).length ? true : false);
    } else {
      result = value ? true : false;
    }
    break;
  case 'boolean':
    result = value ? true : false;
    break;
  default:
    result = true;
    break;
  }

  return result;
}

/**
 * max
 * @param {*} value
 * @param {*} max
 * @return {boolean}
 */
function max(value = null, max) {
  let result = false;

  if (null === value) {
    value = '';
  }

  if (!('number' === typeof value || 'string' === typeof value)) {
    return result;
  }

  if (max >= String(value).length) {
    result = true;
  }

  return result;
}

/**
 * digit
 * @param {*} value
 * @param {*} digits
 * @return {boolean}
 */
function digit(value = null, digits = [0, 0]) {
  let result = false;

  if (null === value) {
    value = '';
  }

  if (!('number' === typeof value || 'string' === typeof value)) {
    return result;
  }

  const values = String(value).split('.');
  const regex1 = new RegExp(`^([1-9][0-9]{0,${digits[0] - 1}}|0)$`);
  const regex2 = new RegExp(`^[0-9]{0,${digits[1]}}$`);

  if (regex1.test(values[0]) && (!values[1] || regex2.test(values[1]))) {
    result = true;
  }

  return result;
}


/**
 * min
 * @param {*} value
 * @param {*} min
 * @return {boolean}
 */
function min(value = null, min, require = 1) {
  let result = false;

  if (null === value) {
    value = '';
  }

  if (!('number' === typeof value || 'string' === typeof value)) {
    return result;
  }

  if (min <= String(value).length || (0 === String(value).length && -1 === require)) {
    result = true;
  }

  return result;
}

/**
 * email format
 * @param {string} value
 * @return {boolean}
 */
function email(value = null) {
  let result = false;

  //eslint-disable-next-line
  const regex = /^[a-zA-Z0-9-_\.\\+]+@[a-zA-Z0-9-_\.]+$/;
//  const regex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-\\+]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  if (!value || regex.test(value)) {
    result = true;
  }

  return result;
}

/**
 * numeric
 * @param {number} value
 * @param {string} type
 * @return {boolean}
 */
function numeric(value = null, type = null) {
  let result = false;
  let regex = null;

  switch (type) {
  case 'decimal':
    //eslint-disable-next-line
    regex = /^[0-9.]+$/;
    break;
  default:
    //eslint-disable-next-line
    regex = /^[0-9]+$/;
    break;
  }

  if (!value || regex.test(value)) {
    result = true;
  }

  return result;
}

/**
 * date
 * @param {string} value
 * @param {string} type
 * @return {boolean}
 */
function date(value, type) {
  let result = false;
  const now = moment();

  switch (type) {
  case 'past':
    result = now.isBefore(moment(new Date(value)));
    break;
  default:
    break;
  }

  return result;
}

/**
 * alpha num
 * @param {string} value
 * @return {boolean}
 */
function alphaNum(value) {
  let result = false;
  const regex = /^[0-9a-zA-Z]+$/;

  if (!value || regex.test(value)) {
    result = true;
  }

  return result;
}

/**
 * alpha num id
 * @param {string} value
 * @return {boolean}
 */
function alphaNumId(value) {
  let result = false;
  const regex = /^[\\.@0-9a-zA-Z_-]+$/;

  if (!value || regex.test(value)) {
    result = true;
  }

  return result;
}

function fileSizeMax(value, max) {
  let result = false;
  const {size = null} = value || {};

  if (null === size || max > size) {
    result = true;
  }
  
  return result;
}
