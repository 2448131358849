import * as gql from 'src/utils/gql';

export async function findAll(isRoleAdmin, tab) {
  const query = `{
    projects(action: "${tab}") {
      _id
      state
      type
      new
      title
      publishedAt
      proposer
      period {
        start {
          y
          m
          d
          about
        }
        end {
          y
          m
          d
          about
        }
        any
        note
      }
      registedBy {
        role
        original {
          name {
            ja
          }
        }
      }
    }
  }`;

  const {errors, data: {projects = []}} = await gql.query(query);

  // const tmp = {};
  // if (0 === errors.length) {
  //   for (const project of projects) {
  //     console.log(project)
  //     const type = project.type;
  //     if (!(type in tmp)) {
  //       tmp[type] = [];
  //     }
      
  //     tmp[type].push(project);
  //   }
  // }

  return {errors, projects: projects};
}

export async function edit(state) {
  const query = `updateProjectSortOrder($input: PostProjectSortOrder!) {
    updateProjectSortOrder(input: $input)
  }`;

  const input = {
    _ids: state.map(item => item._id),
  };

  return await gql.mutation(query, {input});
}

