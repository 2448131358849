import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import Context from './context';
import * as Store from './store';
import Notice from './notice';
import Count from './count';

function Home() {
  const [app, loading, toast] = useContext(AppContext);
  const [state, setState] = useState(null);

  const init = async () => {
    const {errors, notice, count} = (app.isRoleAdmin) ? await Store.findAdmin() : await Store.findMember();

    if (0 < errors.length) {
      toast('error');
    } else {
      setState({notice, count});
    }

    loading(false);
  };

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (true === app.isLoading || null === state) {
    return null;
  }

  return (
    <Context.Provider value={[state.notice, state.count]}>
      <Header.Member />
      <Notice />
      <Count />
    </Context.Provider>
  );
}

export default Home;
