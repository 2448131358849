import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

const initialState = {
  password: {value: '', error: null, confirm: ''},
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch(type) {
  case 'initialize':
    return action.initialState || cloneDeep(initialState);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;

  switch (name) {
  case 'rePassword':
    state.password.confirm = value;
    state.password.error = checkTypeDefault(name, state.password);
    break;
  default:
    state[name].value = value;
    state[name].error = checkTypeDefault(name, value);
    break;
  }

  return state;
}

function checkTypeDefault(name, value) {
  let error = null;
  switch (name) {
  case 'password':
    error = validation(value, {require: true, alphaNum: true, min: 6, max: 20});
    break;
  case 'rePassword':
    const {value: password, confirm} = value;
    if (password !== confirm) {
      error = 'パスワードが一致しません。';
    }
    break;
  default:
    break;
  }

  return error;
}

export function checkError(state) {
  const _state = cloneDeep(state);
  let error = false;

  const check = [];
  check.push(checkTypeDefault('password', _state.password.value));
  check.push(checkTypeDefault('rePassword', _state.password));

  const passwordError = check.filter((e) => (null !== e));
  if (0 < passwordError.length) {
    error = true;
    _state.password.error = passwordError[0];
  }

  return (error ? _state : null);
}

export async function checkToken(token) {
  const query = `{tokenValidity}`;
  return await gql.query(query, {token});
}

export async function reset(state, token) {
  const query = `resetPassword($input: PostResetPassword!) {
    resetPassword(input: $input)
  }`;

  const input = gql.convertToInput({}, state);
  return await gql.mutation(query, {input}, {token});
}
