import React, {useEffect, useContext, useReducer, useRef} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import * as Store from './store';
import Annex from './annex';
import Context from './context';
import Custom from './custom';
import Notification from './notification';
import Original from './original';
import User from './user';


function MuseumRegister(props) {
  const title = useRef(null);
  const role = useRef(null);
  const isTmp = useRef(null);
  const [app, loading, toast] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, null);

  const {params: {_id = null, mode = 'register'}} = props.match || {};

  const init = async () => {
    if (null !== _id && 'edit' === mode) {
      const {errors, museum, tmp} = ('me' === _id ? await Store.findMe(app._id) : await Store.findOne(_id));
      isTmp.current = tmp;

      if (0 < errors.length) {
        toast('error');
      } else if (null === museum) {
        props.history.push('/404');
      } else {
        // 編集
        role.current = museum.role.value;

        if ('admin' === museum.role.value) {
          title.current = museum.name.value;
        } else {
          title.current = museum.original.name.ja.value;
        }

        dispatch({type: 'initialize', initialState: museum});
      }
    } else {
      // 新規登録
      dispatch({type: 'initialize'});
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);
    
  const saveAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }

      const {error, _state} = await Store.checkError(state, mode, _id);
      if (true === error) {
        dispatch({type: 'initialize', initialState: _state});
        toast('warning');
        return;
      }
      loading(true);

      let response = {}; 
      switch (mode) {
      case 'edit':
        response = ('me' === _id ? await Store.registerTmp(state) : await Store.edit(state, _id));
        break;
      default:
        response = await Store.register(state);
        break;
      }

      const {errors} = response;
      if (0 === errors.length) {
        toast('save');

        if (app.isRoleGuest) {
          props.history.push('/');
        } else {
          props.history.push('/museum');
        }
      } else {
        toast('error');
        loading(false);
      }
    },
  };

  if (true === app.isLoading || null === state) {
    return null;
  }

  const isSelectedRoleAdmin = ('admin' === state.role.value);
  const canSave = ('admin' === app.role || false === isTmp.current); // 管理者・申請データが無い場合は編集化

  const  headerParams = {
    title: title.current,
    _id: ('me' === _id) ? app._id : _id,
    role: role.current,
  };

  return (
    <Context.Provider value={[state, dispatch, mode]}>
      <Header.Member pathname={props.location.pathname} params={headerParams} />
      {!canSave && 'admin' !== app.role && (
        <Section.Strong>
          承認依頼中です。<br />
          修正申請される場合は、承認完了後に再度申請を行ってください。
        </Section.Strong>
      )}
      <User />
      {!isSelectedRoleAdmin && (<Original />)}
      {!isSelectedRoleAdmin && (<Notification />)}
      <Custom />
      {!isSelectedRoleAdmin && (<Annex />)}
      {canSave && 'admin' !== app.role && (
        <Section.Strong>
          申請された内容は承認後に反映されます。（即反映はされません。）<br />
          追加で修正申請される場合は、承認完了後に再度申請を行ってください。<br />
          承認が完了されているかどうかは、「【美連協ポータル】美術館情報が承認されました」をご確認ください。
        </Section.Strong>
      )}
      {canSave && (
        <nav data-align="left">
          <Input.Button {...saveAttribute}>
            {'admin' !== app.role && (<>申請</>)}
            {'admin' === app.role && isTmp.current && (<>承認</>)}
            {'admin' === app.role && !isTmp.current && (<>保存</>)}
          </Input.Button>
        </nav>
      )}
    </Context.Provider>
  );
}

export default MuseumRegister;
