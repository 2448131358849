import * as gql from 'src/utils/gql';

export async function init(_id, role) {
  const query = `{
    questionnaire(_id: "${_id}") {
      title
      body
      type
      startAt
      endAt
      file
    }
    answers(questionnaire: "${_id}") {
      text
      projectId
      registedBy
      registedAt
    }
    museums(target: "${role}") {
      _id
      indent
      original {
        area
        name {
          ja
        }
      }
    }
  }`;

  const {
    errors,
    data: {
      questionnaire = null,
      answers = null,
      museums = null,
    },
  } = await gql.query(query);
  return {errors, questionnaire, answers, museums};
}


export async function remove(_id) {
  const query = `removeQuestionnaire($_id: ID!) {
    removeQuestionnaire(_id: $_id)
  }`;
  
  const {errors} = await gql.mutation(query, {_id});
  return {errors};
}

