import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import download from 'src/utils/download';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import Context from '../context';
import Main from '../main';
import Answer from './answer';

function Admin(props) {
  const [app, loading, toast, modal] = useContext(AppContext);
  const [state, setState] = useState(null);
  const {params: {_id, tab = 'member'}} = props.match || {};

  const init = async (_id) => {
    const {errors, questionnaire, answers, museums} = await Store.init(_id, tab);

    if (0 < errors.length || null === questionnaire) {
      setState(null);
      toast('error');
    } else {
      setState({questionnaire, answers, museums});
    }

    loading(false);
  }

  useEffect(() => {
    init(_id);
    
    return () => {
      loading(true);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  if (true === app.isLoading || null === state) {
    return null;
  }
  
  const removeAttribute = {
    type: 'remove',
    onClick: () => {
      modal({
        type: 'remove',
        okHandler: async () => {
          if (true === app.isLoading) {
            return;
          }

          loading(true);

          const {errors} = await Store.remove(_id);
          if (0 === errors.length) {
            toast('remove');
            props.history.push('/questionnaire');
          } else {
            toast('error');
            loading(false);
          }
        },
        cancelHandler: () => {},
      });
    },
  };

  const {title} = state.questionnaire;

  const downloadAttribute = {
    type: 'download',
    onClick: () => download(('member' === tab ? 'answer-member' : 'answer-guest'), {_id}, title),
  };

  return (
    <Context.Provider value={[props.match.params, props.history, state]}>
      <Header.Member pathname={props.location.pathname} params={{title, _id, tab}} />
      <nav>
        <Input.Button onClick={() => props.history.push('/questionnaire')}>戻る</Input.Button>
        <Input.Button {...downloadAttribute}>回答ダウンロード</Input.Button>
        <Input.Button to={`/questionnaire/${_id}/edit`}>編集</Input.Button>
        <Input.Button {...removeAttribute}>削除</Input.Button>
      </nav>
      <Main />
      <Answer />
    </Context.Provider>
  );
}

export default Admin;
