import React, {useContext} from 'react';
import * as Styles from 'src/css/components/input.module.scss';
import AppContext from 'src/app/context';

function File(props) {
  const {
    accept = '.png,.jpg,.pdf,.doc,.docx,.xlsx',
    children = '選択',
    name = '',
    onChange = null,
//    require = false,
    state,
  } = props;
  const {
    value = '',
    error = null,
    data = null,
  } = state;

  const isError = (null !== error ? 'true' : 'false');

  const changeAction = async (e) => {
    if ('function' !== typeof onChange) {
      return;
    }

    const response = {name, file: null};
    const files = e.target.files;
    if (1 === files.length) {
      response.file = files[0];
    }

    onChange(response);
  };

  const isUploaded = (null !== data || '' !== value) ? true : false;

  return (
    <div className={Styles.file} data-component="input" data-input-error={isError}>
      {isUploaded ? (
        <>
          <span className={Styles.removeButton} onClick={() => onChange({name, file: null})}>削除</span>
          <Preview state={state} />
        </>
      ) : (
        <>
          <label className={Styles.fileButton} htmlFor={name}>{children}</label>
          <input type='file' id={name} name={name} accept={accept} onChange={changeAction} />
        </>
      )}
      <small>{error}</small>
    </div>
  );
}

function Preview(props) {
  const [, , , modal] = useContext(AppContext);
  const {value = '', data = null} = props.state;

  const action = (null !== data) ? 'selected' : 'uploaded';
  let name = '';
  let type = '';

  switch (action) {
  case 'selected':
    name = data.name;   
    type = getFileType(name);
    break;
  case 'uploaded':
    name = (value.split('/')).pop();
    type = getFileType(name);
    break;
  default:
    break;
  }

  const attribute = {className: Styles.preview};
  if ('selected' === action && 'image' === type) {
    // ファイル選択 - 画像
    attribute['data-icon'] = 'image';
    attribute.onClick = () => modal({type: 'image-file', file: data});
  } else if ('selected' === action && 'other' === type) {
    // ファイル選択 - 画像以外
  } else if ('uploaded' === action && 'image' === type) {
    // アップロード済み - 画像
    attribute['data-icon'] = 'image';
    attribute.onClick = () => modal({type: 'image', src: value});
  } else if ('uploaded' === action && 'other' === type) {
    // アップロード済み - 画像以外
    attribute['data-icon'] = 'other';
    attribute.href = value;
    attribute.target = '_blank';
  } 

  return (
    <a {...attribute}>{name}</a>
  );
}

function getFileType(name) {
  if ((new RegExp(`^.+(.png|.jpg)$`)).test(name)) {
    return 'image';
  } else {
    return 'other';
  }
}

export default File;
