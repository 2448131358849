import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import AppContext from 'src/app/context';
import Context from '../context';
import Join from '../join';
import Period from './period';
import File from './file';
import SubFile from './sub-file';

function Project() {
  const [app] = useContext(AppContext);
  const [, mode, [state, dispatch], , , org] = useContext(Context);

  let canEditPropose = true;
  if ('edit' === mode) {
    canEditPropose = !state.proposer.readOnly;
  } else {
    canEditPropose = app.isRoleAdmin;
  }

  const proposerAttribute = {
    name: 'proposer',
    required: (app.isRoleAdmin ? true : false),
    readOnly: !canEditPropose,
    width: 504,
    state: state.proposer,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const titleAttribute = {
    name: 'title',
    required: true,
    width: 504,
    state: state.title,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const subTitleAttribute = {
    name: 'subTitle',
    width: 504,
    state: state.subTitle,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const bodyAttribute = {
    name: 'body',
    required: true,
    cols: 94,
    rows: 14,
    state: state.body,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const newAttribute = {
    name: 'new',
    data: [0, 1],
    state: state.new,
    space: true,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const typeAttribute = {
    name: 'type',
    data: app.data.project.type,
    state: state.type,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  if (!app.isRoleAdmin) {
    typeAttribute.readOnly = true;
    typeAttribute.data = app.data.project.type.filter(([, key]) => 'new' === key);
  }

  const stateAttribute = {
    name: 'state',
    state: state.state,
    data: app.data.project.states,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };
  
  if (!app.isRoleAdmin) {
    let filter = [];
    switch (org.state || null) {
    case 'remand':
    case 'draft':
      filter = ['draft', 'cancel'];
      break;
    default:
      filter = ['draft', 'tmp'];
      break;
    }

    stateAttribute.data = app.data.project.states.filter(e => (-1 < filter.indexOf(e[1])));
  }

  const publishedAtAttribute = {
    name: 'publishedAt',
    time: true,
    required: true,
    state: state.publishedAt,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const noteAttribute = {
    name: 'note',
    cols: 94,
    rows: 8,
    state: state.note,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const contactAttribute = {
    name: 'contact',
    cols: 94,
    rows: 4,
    state: state.contact,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  return (
    <>
      <Section.Register>
        <ul>
          <li><Input.Select {...stateAttribute}>公開状態</Input.Select></li>
          <li><Input.Select {...typeAttribute}>カテゴリ</Input.Select></li>
          <li><Input.Date {...publishedAtAttribute}>公開日</Input.Date></li>
          {app.isRoleAdmin && (
            <li><Input.Checkbox {...newAttribute}>Newアイコンを表示させる</Input.Checkbox></li>
          )}
        </ul>
      </Section.Register>
      <Section.Register>
        <div><Input.Text {...titleAttribute}>企画名</Input.Text></div>
        <div><Input.Text {...subTitleAttribute}>副題</Input.Text></div>
        <div><Input.Textarea {...bodyAttribute}>企画概要</Input.Textarea></div>
      </Section.Register>
      <File />
      <Period />
      {/* {(app.isRoleAdmin) && (<Join />)} */}
      <Join />
      <Section.Register>
        <div><Input.Textarea {...noteAttribute}>備考</Input.Textarea></div>
      </Section.Register>
      <SubFile />
      <Section.Register>
        <div><Input.Text {...proposerAttribute}>提案館</Input.Text></div>
        <div><Input.Textarea {...contactAttribute}>連絡先（担当者名、メールアドレス、TEL）</Input.Textarea></div>
      </Section.Register>
    </>
  );
}

export default Project;

