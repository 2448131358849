import React, {useContext, useState, useEffect} from 'react';
import download from 'src/utils/download';
import AppContext from 'src/app/context';
import * as Anchor from 'src/components/anchor';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import * as Store from './store';
import Context from './context';
import Admin from './admin';
import Member from './member';

const tabElementId = 'project-tab-area';

function Project(props) {
  const [app, loading, toast] = useContext(AppContext);
  const {params: {tab = 'default'}} = props.match;
  const [state, setState] = useState(null);

  const init = async () => {
    const {errors, projects} = await Store.findAll(app.isRoleAdmin, tab);

    if (0 < errors.length) {
      setState([]);
      toast('error');
    } else {
      setState(projects);
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  if (true === app.isLoading || null === state) {
    return null;
  }


  const to = [];
  if (app.isRoleAdmin) {
    to.push(['公開', '/project']);
    to.push(['企画一覧', '/project/all']);
    to.push(['企画承認依頼', '/project/draft']);
    to.push(['開催・検討承認依頼', '/project/join']);
  } else {
    to.push(['公開', '/project']);
    to.push(['提案', '/project/proposer']);
    to.push(['開催・検討', '/project/join']);
    to.push(['差戻し', '/project/remand']);
  }

  const tabAttribute = {
    id: tabElementId,
    to,
    active: props.history.location.pathname,
    padding: ('default' === tab) ? false : true,
  };

  const canDownload = app.isRoleAdmin;

  return (
    <Context.Provider value={[state, props.history, tabElementId, tab, setState]}>
      <Header.Member pathname={props.location.pathname} />
      <nav>
        {canDownload && (<Input.Button type="download" onClick={() => download('project-museum')}>美術館別ダウンロード</Input.Button>)}
        {canDownload && (<Input.Button type="download" onClick={() => download('project')}>展覧会別ダウンロード</Input.Button>)}
        <Input.Button to="/project/register">新規登録</Input.Button>
      </nav>
      <>
        <Section.TabLink {...tabAttribute}>
          {app.isRoleAdmin ? (<Admin />) : (<Member />)}
        </Section.TabLink>
      </>
    </Context.Provider>
  );
}

export default Project;
