import React, {useContext} from 'react';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import AppContext from 'src/app/context';
import Context from './context';
import * as Styles from 'src/css/screens/museum-register.module.scss';
import Admin from './admin';

const type = 'user';

function User() {
  const [app] = useContext(AppContext);
  const [state, dispatch, mode] = useContext(Context);
  
  const roleAttribute = {
    name: 'role',
    readOnly: !app.isRoleAdmin,
    data: app.data.roles,
    state: state.role,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const idAttribute = {
    name: 'id',
    readOnly: ('register' !== mode ? true : false),
    required: true,
    data: app.data.roles,
    state: state.id,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const nameAttribute = {
    name: 'name',
    required: true,
    width: 300,
    data: app.data.roles,
    state: state.name,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const indentAttribute = {
    name: 'indent',
    data: [0, 1],
    state: state.indent,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const passwordAttribute = {
    name: 'password',
    type: 'password',
    required: ('register' === mode ? true : false),
    width: 205,
    state: state.password,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value, mode: mode}),
  };

  const rePasswordAttribute = {
    name: 'rePassword',
    type: 'password',
    width: 205,
    state: {value: state.password.confirm, error: null},
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <h3>ユーザー情報</h3>
      <ul>
        <li><Input.Text {...idAttribute}>ID</Input.Text></li>
        <li><Input.Select {...roleAttribute}>権限</Input.Select></li>
        <li><Input.Text {...nameAttribute}>アカウント名</Input.Text></li>
        {app.isRoleAdmin && (
          <li className={Styles.indent}><Input.Checkbox {...indentAttribute}>分館表示する</Input.Checkbox></li>
        )}
      </ul>
      {app.isRoleAdmin && (
        <ul>
          <li><Input.Text {...passwordAttribute}>パスワード</Input.Text></li>
          <li><Input.Text {...rePasswordAttribute}>パスワード(確認)</Input.Text></li>
        </ul>
      )}
      <Admin />
    </Section.Register>
  );
}

export default User;
