import React from 'react';
import anime from 'animejs/lib/anime.es.js';
import * as Styles from 'src/css/components/anchor.module.scss';

const componentId = 'component-anchor';

function scroll(id) {
  const html = document.querySelector('html');
  const clientRect = document.getElementById(id).getBoundingClientRect();
  const py = window.pageYOffset + clientRect.top;

  if ('IE' ===  html.getAttribute('data-browser')) {
    window.scrollTo(0, py);
  } else {
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

    anime({
      targets: scrollElement,
      scrollTop: py,
      duration: 500,
      easing: 'easeInOutQuad',
    });
  }
}

export function Nav(props) {
  const {
    id = componentId,
    data = [],
  } = props;

  const count = data.length;

  const rowJsx = data.map((item) => {
    const [name, id] = item;
    const attribute = {
      // onClick: () => document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'}),
      onClick: () => scroll(id),
    };

    return (
      <li key={`anchor-${id}`} style={{width: `calc(100% / ${count})`}}>
        <a {...attribute}><span>{name}</span></a>
      </li>
    );
  });

  return (
    <ul id={id} className={Styles.nav}>{rowJsx}</ul>
  );
}

export function Back(props) {
  const {
    to = componentId,
  } = props;

  const attribute = {
    // onClick: () => document.getElementById(to).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'}),
    onClick: () => scroll(to),
  };

  return (
    <div className={Styles.back}>
      <a {...attribute}><span>{props.children}</span></a>
    </div>
  );
}
