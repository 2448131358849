import React, {useReducer, useContext, useEffect} from 'react';
import AppContext from 'src/app/context';
import * as Footer from 'src/components/footer';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import * as Styles from 'src/css/screens/reset-password.module.scss';

function ResetPassword(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, null);
  const {token} = props.match.params;

  const init = async () => {
    const {errors} = await Store.checkToken(token);

    if (0 < errors.length) {
      props.history.push('/');
    }
    
    dispatch({type: 'initialize'});
    loading(false);
  };

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (true === app.isLoading || null === state) {
    return null;
  }

  const passwordAttribute = {
    name: 'password',
    type: 'password',
    state: state.password,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const rePasswordAttribute = {
    name: 'rePassword',
    type: 'password',
    state: {value: state.password.confirm},
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const resetAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }

      const error = Store.checkError(state);
      if (null !== error) {
        dispatch({type: 'initialize', initialState: error});
        toast('warning');
        return;
      }
      loading(true);

      const {errors} = await Store.reset(state, token);
      if (0 < errors.length) {
        toast('error');
      } else {
        toast('send');
        props.history.push('/');
      }

      loading(false);
    },
  };


  return (
    <>
      <Header.Guest />
      <section className={Styles.section}>
        <div><Input.Text {...passwordAttribute}>パスワード</Input.Text></div>
        <div><Input.Text {...rePasswordAttribute}>パスワード（確認）</Input.Text></div>
        <div className={Styles.button}><Input.Button {...resetAttribute}>送信</Input.Button></div>
      </section>
      <Footer.Guest />
    </>
  );
}

export default ResetPassword;
