import React, {useRef, useEffect, useContext, useReducer} from 'react';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import * as Store from './store';
import Main from './main';
import File from './file';
import Owner from './owner'
import Notification from './notification';
import Context from './context';

function NoticeRegister(props) {
  const title = useRef(null);
  const owner = useRef(null);
  const type = useRef(null);
  const [app, loading, toast] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, null);
  const {params: {_id = null, mode = null}} = props.match || {};

  const init = async () => {
    if (null !== _id && 'edit' === mode) {
      const {errors, notice, owner: _owner, type: _type} = await Store.init(_id);
      if (0 < errors.length) {
        toast('error');
      } else if (null === notice) {
        props.history.push('/404');
      } else {
        // 編集
        title.current = notice.title.value;
        owner.current = _owner;
        type.current = _type;
        dispatch({type: 'initialize', initialState: notice, isRoleAdmin: app.isRoleAdmin});
      }
    } else {
      // 新規登録
      dispatch({type: 'initialize', isRoleAdmin: app.isRoleAdmin, isRoleMember: app.isRoleMember, isRoleGuest: app.isRoleGuest});
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }
      
      const {error, _state} = Store.checkAll(state, mode);
      if (true === error) {
        dispatch({type: 'initialize', initialState: _state, isRoleAdmin: app.isRoleAdmin, replace: true});
        toast('warning');
        return;
      }

      loading(true);
      const {
        errors,
      } = ('edit' === mode ? await Store.edit(state, _id) : await Store.register(state));

      if (0 === errors.length) {
        toast('save');
        props.history.push('/notice');
      } else {
        toast('error');
        loading(false);
      }
    },
  };

  if (true === app.isLoading || null === state) {
    return null;
  }

  return (
    <Context.Provider value={[state, dispatch, mode, owner.current, type.current]}>
      <Header.Member pathname={props.location.pathname} params={{title: title.current, _id}} />
      <Main />
      <Owner />
      <File />
      <Notification />
      {!app.isRoleAdmin && (
        <Section.Strong>
          申請された内容は承認後に公開されます。<br />
          承認が完了されているかどうかは、「【美連協ポータル】「お知らせ」が公開されました」をご確認ください。
        </Section.Strong>
      )}
      <nav data-align="left">
        <Input.Button {...saveAttribute}>
          {app.isRoleAdmin && (<>保存</>)}
          {!app.isRoleAdmin && (<>申請</>)}
        </Input.Button>
      </nav>
    </Context.Provider>
  );
}

export default NoticeRegister;
