import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import upload from 'src/utils/upload';
import validation from 'src/utils/validation';

const initialState = {
  target: {value: 'all', error: null},
  state: {value: 'private', error: null},
  startAt: {value: new Date(), error: null},
  endAt: {value: '', error: null},
  title: {value: '', error: null},
  body: {value: '', error: null},
  type: {value: 'text', error: null},
  file: [
    {value: '', error: null, data: null},
    {value: '', error: null, data: null},
    {value: '', error: null, data: null},
  ],
  notification: {
    send: {value: 0, error: null},
    title: {value: '', error: null},
    body: {value: '', error: null},
  },
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch(type) {
  case 'initialize':
    return action.initialState || cloneDeep(initialState);
  case 'notification':
    return reducerTypeNotification(_state, action);
  case 'file':
    return reducerTypeFile(_state, action);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeFile(state, action) {
  const {behavior = null, name = null, value = null} = action;
  const _state = state.file;

  switch (behavior) {
  case 'dnd':
    state.file = value;
    break;
  default:
    if (null === value) {
      _state[name].value = '';
      _state[name].data = null;
    } else {
      _state[name].value = value.name;
      _state[name].data = value;
    }
    
    _state[name].error = checkTypeFile(value);
    break;
  }

  return state;
}

function checkTypeFile(value) {
  return validation(value, {fileSizeMax: 2000000});
}

function reducerTypeNotification(state, action) {
  const {name = null, value = null} = action;
  const _state = state.notification;
  _state[name].value = value;
  _state[name].error = checkTypeNotification(name, value);

  switch (name) {
  case 'send':
    _state.title.value = '';
    _state.body.value = '';
    break;
  default:
    break;
  }
  
  return state;
}

function checkTypeNotification(name, value) {
  let error = null;
  switch (name) {
  case 'title':
    error = validation(value, {max: 100});
    break;
  case 'body':
    error = validation(value, {max: 500});
    break;
  default:
    break;
  }

  return error;
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;

  switch (name) {
  case 'startAt':
    state[name].error = checkTypeDefault(name, [value, state.endAt.value]);
    break;
  case 'endAt':
    state[name].error = checkTypeDefault(name, [state.startAt.value, value]);
    break;
  default:
    state[name].error = checkTypeDefault(name, value);
    break;
  }
  
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;
  switch (name) {
  case 'title':
    error = validation(value, {require: true, max: 100});
    break;
  case 'body':
    error = validation(value, {require: true, max: 2000});
    break;
  case 'startAt':
    {
      const [start, end] = value;
      error = validation(start, {require: true});
      if (!error && '' !== end && start > end) {
        error = '終了日より前を選択してください。';
      }
    }   
    break;
  case 'endAt':
    {
      const [start, end] = value;
      error = validation(end, {require: true});
      if (!error && '' !== start && start > end) {
        error = '開始日より後を選択してください。';
      }
    }    
    break;
  default:
    break;
  }

  return error;
}


export function checkAll(state) {
  const _state = cloneDeep(state);
  let error = false;

  for (const key of ['title', 'body']) {
    const item = _state[key];
    item.error = checkTypeDefault(key, item.value);

    if (null !== item.error) {
      error = true;
    }
  }

  const startAt = _state.startAt;
  const endAt = _state.endAt;
  startAt.error = checkTypeDefault('startAt', [startAt.value, endAt.value]);
  if (null !== startAt.error) {
    error = true;
  }
  endAt.error = checkTypeDefault('endAt', [startAt.value, endAt.value]);
  if (null !== endAt.error) {
    error = true;
  }

  for (const item of _state.file) {
    item.error = checkTypeFile(item.data);

    if (null !== item.error) {
      error = true;
    }
  }
  
  for (const key of ['title', 'body']) {
    const item = _state.notification[key];
    item.error = checkTypeNotification(key, item.value);

    if (null !== item.error) {
      error = true;
    }
  }
 
  return {error, _state};
}

export async function init(_id) {
  const query = `{
    questionnaire(_id: "${_id}") {
      body
      file
      notification {
        send
        title
        body
      }
      startAt
      endAt
      state
      type
      target
      title
    }
  }`;

  const {errors, data} = await gql.query(query);
  if (0 < errors.length || null === data.questionnaire) {
    return {errors, questionnaire: null};
  }

  const _state = cloneDeep(initialState);
  const {
    body,
    file,
    startAt,
    endAt,
    state,
    target,
    title,
    type,
    notification,
  } = data.questionnaire; 

  _state.body.value = body;
  _state.startAt.value = new Date(startAt);
  _state.endAt.value = new Date(endAt);
  _state.state.value = state;
  _state.type.value = type;
  _state.target.value = target;
  _state.title.value = title;
  
  for (let i = 0, len = file.length; i < len; i++) {
    if (!_state.file[i]) {
      continue;
    }
    _state.file[i].value = file[i];
  }

  _state.notification.send.value = notification.send;
  _state.notification.title.value = notification.title;
  _state.notification.body.value = notification.body;

  return {errors, questionnaire: _state};
}


async function fileUpload(state) {
  const uploaded = state.file.some((item) => null !== item.data);
  if (false === uploaded) {
    return {errors: [], uploads: []};
  }

  const files = state.file.map((item) => item.data);
  const {errors, data: {uploads = []}} = await upload(files);
  return {errors, uploads};
}

export async function register(state) {
  // 先にファイルをアップロード（結果にアップロードファイルのパスが入っている
  {
    const {errors, uploads} = await fileUpload(state);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      state.file[index].value = path;
    });
  }
  {
    const query = `createQuestionnaire($input: PostQuestionnaire!) {
      createQuestionnaire(input: $input) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, state);
    const {errors, data: {createQuestionnaire = {}}} = await gql.mutation(query, {input});
    const _id = createQuestionnaire._id || null;
    return {errors, _id};
  }
}

export async function edit(state, _id) {
  // 先にファイルをアップロード（結果にアップロードファイルのパスが入っている
  {
    const {errors, uploads} = await fileUpload(state);
    if (0 < errors.length) {
      return {errors};
    }

    uploads.forEach((path, index) => {
      if (null !== state.file[index].data) {
        state.file[index].value = path;
      }
    });
  }
  {
    const query = `updateQuestionnaire($_id: ID!, $input: PostQuestionnaire!) {
      updateQuestionnaire(_id: $_id, input: $input) {
        _id
      }
    }`;

    const input = gql.convertToInput({}, state);
    const {errors} = await gql.mutation(query, {_id, input});
    return {errors, _id};
  }
}
