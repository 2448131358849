import cloneDeep from 'lodash.clonedeep';
import validation from 'src/utils/validation';

const initialState = {
  title: {value: '', error: null},
  subTitle: {value: '', error: null},
  new: {value: 0, error: null},
  proposer: {value: '', error: null, readOnly: false},
  type: {value: 'new', error: null},
  state: {value: 'tmp', error: null},
  period: {
    start: {
      y: {value: '', error: null,},
      m: {value: '', error: null,},
      d: {value: '', error: null,},
      about: {value: '', error: null,},
    },
    end: {
      y: {value: '', error: null,},
      m: {value: '', error: null,},
      d: {value: '', error: null,},
      about: {value: '', error: null,},
    },
    note: {value: '', error: null},
    any: {value: 0, error: null},
  },
  body: {value: '', error: null},
  note: {value: '', error: null},
  contact: {value: '', error: null},
  publishedAt: {value: new Date(), error: null},
  file: [
    {src: {value: '', error: null, data: null}, caption: {value: '', error: null}},
    {src: {value: '', error: null, data: null}, caption: {value: '', error: null}},
    {src: {value: '', error: null, data: null}, caption: {value: '', error: null}},
  ],
  subFile: {value: '', error: null, data: null},
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return reducerTypeInitialize(action);
  case 'period':
    return reducerTypePeriod(_state, action);
  case 'file':
    return reducerTypeFile(_state, action);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeInitialize(action) {
  const state = cloneDeep(initialState);
  const {initialState: project = null, replace = false, proposer = null, isRoleAdmin = false, isRegister = false} = action;
  
  if (null === project) {
    if (proposer) {
      state.proposer.value = proposer;
      state.state.value = 'draft';
    } 

    return state;
  }

  if (replace) {
    return project;
  }

  state.title.value = project.title;
  state.subTitle.value = project.subTitle;
  state.new.value = project.new;

  const {role = null, original = null} = project.registedBy || {};

  if (('admin' === role || isRegister) || !original) {
    state.proposer.value = project.proposer;
  } else {
    state.proposer.value = original.name.ja;
  }

  // 企画作成者が事務局以外の場合は申請館の編集不可
  if ((role && 'admin' !== role) || !isRegister) {
    state.proposer.readOnly = true;
  }

  state.type.value = project.type;

  if (!isRoleAdmin && 'remand' === project.state) {
    // 編集者が事務局以外、かつ差戻しの場合は、承認申請を初期選択とする
    state.state.value = 'draft';
  } else {
    state.state.value = project.state;
  }
  
  state.period.start.y.value = project.period.start.y;
  state.period.start.m.value = project.period.start.m;
  state.period.start.d.value = project.period.start.d;
  state.period.start.about.value = project.period.start.about;
  state.period.end.y.value = project.period.end.y;
  state.period.end.m.value = project.period.end.m;
  state.period.end.d.value = project.period.end.d;
  state.period.end.about.value = project.period.end.about;
  state.period.note.value = project.period.note;
  state.period.any.value = project.period.any;

  state.body.value = project.body;
  state.note.value = project.note;
  state.contact.value = project.contact;
  state.publishedAt.value = new Date(project.publishedAt);

  project.file.forEach((item, index) => {
    state.file[index].src.value = item.src;
    state.file[index].caption.value = item.caption;
  });
  
  state.subFile.value = project.subFile;

  return state;
}


function  reducerTypePeriod(state, action) {
  const {name = null, key = null, value = null} = action;
  const _state = state.period;
  
  switch (key) {
  case 'start':
  case 'end':
    _state[key][name].value = value;
    _state[key][name].error = checkTypePeriod(name, value);
    break;
  default:
    _state[name].value = value;
    _state[name].error = checkTypePeriod(name, value);
    break;
  }
  return state;
}

function checkTypePeriod(name, value) {
  let error = null;
  
  switch (name) {
  case 'y':
    error = validation(value, {numeric: true, max: 4});
    break;
  case 'm':
  case 'd':
    error = validation(value, {numeric: true, max: 2});
    break;
  case 'note':
    error = validation(value, {max: 100});
    break;
  default:
    break;
  }
  return error;
}

function reducerTypeFile(state, action) {
  const {behavior = null, key = 0, name = null, value = null} = action;
  const file = state.file;

  if ('dnd' === behavior) {
    state.file = value;
  } else {
    switch (name) {
    case 'src':
      if (null === value) {
        file[key][name].value = '';
        file[key][name].data = null;
      } else {
        file[key][name].value = value.name;
        file[key][name].data = value;
      }
      break;
    case 'caption':
      file[key][name].value = value;
      break;
    default:
      break;
    }
    
    file[key][name].error = checkTypeFile(name, value);
  }

  return state;
}

function checkTypeFile(name, value) {
  let error = null;

  switch (name) {
  case 'src':
    error = validation(value, {fileSizeMax: 2000000});
    break;
  case 'caption':
    error = validation(value, {max: 200});
    break;
  default:
    break;
  }
  return error;
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;

  switch (name) {
  case 'subFile':
    if (null === value) {
      state[name].value = '';
      state[name].data = null;
    } else {
      state[name].value = value.name;
      state[name].data = value;
    }
    break;
  default:
    state[name].value = value;
    break;
  }

  state[name].error = checkTypeDefault(name, value);
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;

  switch (name) {
  case 'proposer':
    error = validation(value, {require: true, max: 100});
    break;
  case 'title':
    error = validation(value, {require: true, max: 100});
    break;
  case 'subTitle':
    error = validation(value, {max: 100});
    break;
  case 'body':
    error = validation(value, {require: true, max: 2000});
    break;
  case 'note':
  case 'contact':
    error = validation(value, {max: 1000});
    break;
  case 'subFile':
    error = validation(value, {fileSizeMax: 4000000});
    break;
  default:
    break;
  }
  return error;
}






export function checkAll(state) {
  const _state = cloneDeep(state);
  const errors = [];

  for (const key of ['title', 'subTitle', 'body', 'proposer', 'note', 'contact', 'publishedAt', 'subFile']) {
    const item = _state[key];
    item.error = checkTypeDefault(key, item.value);
    errors.push(item.error);
  }

  for (const item of _state.file) {
    item.src.error = checkTypeFile('src', item.src.data);
    errors.push(item.src.error);
    
    item.caption.error = checkTypeFile('caption', item.caption.value);
    errors.push(item.caption.error);
  }

  const period = _state.period;
  period.start.y.error = checkTypePeriod('y', period.start.y.value);
  errors.push(period.start.y.error);

  period.start.m.error = checkTypePeriod('m', period.start.m.value);
  errors.push(period.start.m.error);

  period.start.d.error = checkTypePeriod('d', period.start.d.value);
  errors.push(period.start.d.error);

  period.start.about.error = checkTypePeriod('about', period.start.about.value);
  errors.push(period.start.about.error);

  period.end.y.error = checkTypePeriod('y', period.end.y.value);
  errors.push(period.end.y.error);

  period.end.m.error = checkTypePeriod('m', period.end.m.value);
  errors.push(period.end.m.error);

  period.end.d.error = checkTypePeriod('d', period.end.d.value);
  errors.push(period.end.d.error);

  period.end.about.error = checkTypePeriod('about', period.end.about.value);
  errors.push(period.end.about.error);

  period.note.error = checkTypePeriod('note', period.note.value);
  errors.push(period.note.error);
  return {error: errors.some(e => null !== e), _state};
}

