import React, {useState, useEffect, useReducer} from 'react';
import moment from 'moment';
import * as storage from 'src/utils/storage';
import Context from './context';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import Toast from 'src/components/toast';
import * as Router from 'src/router';
import * as Store from './store';
import "react-datepicker/dist/react-datepicker.css";
import 'src/css/app.scss';

let timer = 0;

function App() {
  const [start, setStartState] = useState(false);
  const [state, dispatch] = useReducer(Store.reducer, Store.initialState);

  useEffect(() => {
    initialyze();
  }, []);

  useEffect(() => {
    if (state.isSignin) {
      checkTokenExpire(dispatch);
    }
  }, [state.isSignin]);

  // 初期処理
  const initialyze = async () => {
    // ローカルストレージにtokenが存在した場合は
    if (null !== storage.findByKey('token')) {
      const {errors, data} = await Store.init();

      if (0 === errors.length) {
        dispatch({type: 'initialize', value: data});
      }
    }

    setStartState(true);
  };

  const setLoaderState = (action) => {
    setDataUrl();

    if (true === action) {
      timer = (new Date()).getTime();
      dispatch({type: 'loader', value: true});
    } else {
      // ローディング表示の時間調整
      setTimeout(() => {
        window.scrollTo(0, 0);
        dispatch({type: 'loader', value: false});
      }, (1500 > ((new Date()).getTime() - timer) ? 1500 : 1)); // 1500: css animation duration
    }
  };

  const setToastState = (action) => {
    dispatch({type: 'toast', value: action});
  };

  const setModalState = (action) => {
    dispatch({type: 'modal', value: action});
  };

  // 初期処理が終わるまで画面を描画しない
  if (false === start) {
    return null;
  }

  const isSignin = (state.isSignin ? 'true' : 'false');

  return (
    <Context.Provider value={[state, setLoaderState, setToastState, setModalState, dispatch]}>
      <Toast />
      <Modal />
      <Loader />
      <main data-signin={isSignin}>
        {state.isSignin ? (
          <Router.Member />
        ) : (
          <Router.Guest />
        )}
      </main>
      <span id="trash" />
    </Context.Provider>
  );
}

function setDataUrl() {
  const html = document.querySelector('html');
  html.setAttribute('data-url', window.location.pathname);
}


let checkTimer = null;
function checkTokenExpire(dispatch) {
  const exp = moment.unix(storage.findByKey('exp'));

  checkTimer = setInterval(() => {
    if (exp.isBefore(moment())) {
      clearInterval(checkTimer);
      const modal = {
        message: 'セッションが切れました。<br />再度ログインしてください。',
        okHandler: () => { window.location.href = '/'; },
        closeHandler: () => { window.location.href = '/'; },
      };

      dispatch({type: 'modal', value: modal});
    }
  }, 1000 * 30);
}

export default App;
