import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import Badge from 'src/components/badge';
import React, {useContext} from 'react';
import * as ScreenStyles from 'src/css/screens/project-chart.module.scss';
import * as Common from 'src/utils/common';
import AppContext from 'src/app/context';
import * as Dnd from 'src/components/dnd';
import * as Anchor from 'src/components/anchor';
import * as Input from 'src/components/input';
import * as Store from './store';
import Context from './context';

const limit = moment(new Date()).subtract(1, 'month');

function Admin() {
  const [, , , tab] = useContext(Context);
  
  return (<>{'default' === tab ? (<Public />) : (<Private />)}</>);
}


function Public() {
  const [app, loading, toast] = useContext(AppContext);
  const [state, , tabElementId, tab, setState] = useContext(Context);

  const rowJsx = (state || []).map((item, index) => {
    return (<Row key={`${item.type}-${index}`} project={item} tab={tab} />);
  });

  const dndLineState = (state || []).map(e => e.state);

  const dndState = {
    prefix: `type-dnd`,
    add: false,
    remove: false,
    state: state,
    callback: (e) => {
      const _state = cloneDeep(state);
      _state = e;
      setState(_state);
    },
    head: [
      ['公開状態', ScreenStyles.state],
      ['企画名', ScreenStyles.title],
      ['提案館', ScreenStyles.proposer],
      ['開催可能時期', ScreenStyles.period],
      ['公開日', ScreenStyles.date],
    ],
    lineAttribute: {
      'state': dndLineState,
    },
  };

  return (
    <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
  );
}

function Private() {
  const [app] = useContext(AppContext);
  const [state, , , tab] = useContext(Context);
  
  const rowJsx = (state || []).map((item, index) => {
    return (<Row key={`${item.type}-${index}`} project={item} tab={tab} />);
  });

  const dndState = {
    add: false,
    remove: false,
    dnd: false,
    head: [
      ['公開状態', ScreenStyles.state],
      ['企画名', ScreenStyles.title],
      ['提案館', ScreenStyles.proposer],
      ['開催可能時期', ScreenStyles.period],
      ['公開日', ScreenStyles.date],
    ],
  };

  return (<Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>);
}


function Row(props) {
  const [app] = useContext(AppContext);
  const {project: item, tab} = props;
  const period = Common.convertProjectPeriodToText(item.period);

  const {role = null, original = null} = item.registedBy || {};
  let proposer = '';
  if ('admin' === role || !original) {
    proposer = item.proposer;
  } else {
    proposer = original.name.ja;
  }

  const at = moment(item.publishedAt);
  let label = app.label.project.states[item.state] || null;
  if ('public' === item.state && at.isAfter(moment())) {
    label = '予約済';
  }

  const to = `/project/${'default' !== tab ? `${tab}/` : ''}${item._id}`;
  const isBadge = ('default' === tab && (at.isAfter(limit) || 1 === item.new)) ? true : false;

  return (
    <>
      <span className={ScreenStyles.state} data-strong="true">{label}</span>
      <span className={ScreenStyles.title}>
        {isBadge && (<Badge type="new" />)}
        <Input.Link to={to}>{item.title}</Input.Link>
      </span>
      <span className={ScreenStyles.proposer}>{proposer}</span>
      <span className={ScreenStyles.period}>{period}</span>
      <span className={ScreenStyles.date}>{at.format('YYYY.MM.DD')}</span>
    </>
  );
}

export default Admin;
