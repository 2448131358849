import React, {useContext} from 'react';
import AppContext from 'src/app/context';
import Admin from './admin';
import Member from './member';

function QuestionnaireDetail(props) {
  const [app] = useContext(AppContext);

  return (
    <>
      {app.isRoleAdmin ? (
        <Admin {...props} />
      ) : (
        <Member {...props} />
      )}
    </>
  );
}

export default QuestionnaireDetail;
