import cloneDeep from 'lodash.clonedeep';
import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import AppContext from 'src/app/context';
import * as Styles from 'src/css/screens/museum-register.module.scss';
import * as Dnd from 'src/components/dnd';
import * as Store from './store';

const BasicContext = React.createContext([{}, () => {}]);

function Basic(props) {
  return (
    <BasicContext.Provider value={[props.data, props.dispatch]}>
      <Name />
      <Link />
      <div>
        <Prefecture />
        <Zip />
      </div>
      <Address />
      <Contact />
      <Staff />
    </BasicContext.Provider>
  );
}

function Name() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.name;
  const target = 'name';

  const jaAttribute = {
    name: 'ja', 
    required: true,
    width: 310,
    state: state.ja,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  const enAttribute = {
    name: 'en', 
    width: 310,
    state: state.en,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  return (
    <div className={Styles.basicName}>
      <Input.Text {...jaAttribute}>美術館名</Input.Text>
      <Input.Text {...enAttribute}>美術館名（英語）</Input.Text>
    </div>
  );
}


function Link() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.link;
  const target = 'link';
  const width = 640;

  const siteAttribute = {
    name: 'site', 
    width: width,
    state: state.site,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  const twAttribute = {
    name: 'tw', 
    width: width,
    state: state.tw,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  const fbAttribute = {
    name: 'fb', 
    width: width,
    state: state.fb,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  const igAttribute = {
    name: 'ig', 
    width: width,
    state: state.ig,
    onChange: (e) => dispatch({keys: [target], name: e.name, value: e.value}),
  };

  return (
    <div>
      <div><Input.Text {...siteAttribute}>ウェブサイトURL</Input.Text></div>
      <div><Input.Text {...twAttribute}>Twitter</Input.Text></div>
      <div><Input.Text {...fbAttribute}>Facebook</Input.Text></div>
      <div><Input.Text {...igAttribute}>Instagram</Input.Text></div>
    </div>
  );
}




function Prefecture() {
  const [app] = useContext(AppContext);
  const [data, dispatch] = useContext(BasicContext);
  const state = data.prefecture;

  // 99:事務局　管理者のみ選択可能
  const prefectures = (app.isRoleAdmin ? app.data.prefectures : app.data.prefectures.slice(0, -1));

  const prefectureAttribute = {
    className: Styles.basicprefecture,
    name: 'prefecture',
    data: prefectures,
    state,
    onChange: (e) => {
      let area = 1;
      for (const _area of app.data.areas) {
        const [, id, group] = _area; 
        if (-1 < group.indexOf(e.value)) {
          area = id;
          break;
        }
      }

      dispatch({name: e.name, value: e.value, area});
    },
  };

  return (
    <>
      <Input.Select {...prefectureAttribute}>都道府県</Input.Select>
    </>
  );
}



function Zip() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.zip;
  const target = 'zip';
  const width = 60;
  
  const zip1Attribute = {
    type: 'number',
    required: true,
    width: width,
    state: state[0],
    onChange: (e) => dispatch({keys: [target], name: 0, value: e.value}),
  };

  const zip2Attribute = {
    type: 'number',
    width: width,
    state: state[1],
    onChange: (e) => dispatch({keys: [target], name: 1, value: e.value}),
  };

  return (
    <>
      <Input.Text {...zip1Attribute}>郵便番号</Input.Text>
      <i />
      <Input.Text {...zip2Attribute}></Input.Text>
    </>
  );
}


function Address() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.address;
  const target = 'address';
  const width = 640;
  
  const address1Attribute = {
    required: true,
    width: width,
    state: state[0],
    onChange: (e) => dispatch({keys: [target], name: 0, value: e.value}),
  };

  const address2Attribute = {
    width: width,
    state: state[1],
    onChange: (e) => dispatch({keys: [target], name: 1, value: e.value}),
  };

  return (
    <div>
      <div><Input.Text {...address1Attribute}>市区町村・番地</Input.Text></div>
      <div><Input.Text {...address2Attribute}>建物名等</Input.Text></div>
    </div>
  );
}

function Staff() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.staff;
  const target = 'staff';
  const width = 80;

  const rowJsx = state.map((staff, index) => {
    const positionAttribute = {
      name: 'position',
      state: staff.position,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const nameFirstAttribute = {
      name: 'nameFirst', 
      width: width,
      state: staff.nameFirst,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const nameLastAttribute = {
      name: 'nameLast', 
      width: width,
      state: staff.nameLast,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const nameFirstKanaAttribute = {
      name: 'nameFirstKana',
      width: width,
      state: staff.nameFirstKana,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const nameLastKanaAttribute = {
      name: 'nameLastKana',
      width: width,
      state: staff.nameLastKana,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const workAttribute = {
      name: 'work', 
      state: staff.work,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const fieldAttribute = {
      name: 'field',
      state: staff.field,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    return (
      <React.Fragment key={`staff-${index}`}>
        <span className={Styles.basicStaffPosition}>
          <Input.Text {...positionAttribute} />
        </span>
        <span className={Styles.basicStaffName}>
          <Input.Text {...nameLastAttribute} />
          <Input.Text {...nameFirstAttribute} />
        </span>
        <span className={Styles.basicStaffNameKana}>
          <Input.Text {...nameLastKanaAttribute} />
          <Input.Text {...nameFirstKanaAttribute} />
        </span>
        <span className={Styles.basicStaffWork}>
          <Input.Text {...workAttribute} />
        </span>
        <span className={Styles.basicStaffField}>
          <Input.Text {...fieldAttribute} />
        </span>
      </React.Fragment>
    );
  });

  const addAttribute = {
    className: Styles.flatButton,
    onClick: () => dispatch({behavior: 'add', name: target}),
  };

  const dndState = {
    prefix: `staff-dnd`,
    state: state,
    initialState: cloneDeep(Store.initialStaffState),
    callback: (e) => {
      dispatch({behavior: 'dnd', name: target, value: e});
    },
    head: [
      ['役職', Styles.basicStaffPosition],
      ['氏名', Styles.basicStaffName],
      ['ふりがな', Styles.basicStaffNameKana],
      ['担当業務', Styles.basicStaffWork],
      ['専門研究分野', Styles.basicStaffField],
    ],
  };

  return (
    <div>
      <h4>職員名簿</h4>
      <div className={Styles.basicStaffWrap} data-count={rowJsx.length}>
        {0 < rowJsx.length ? (
          <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
        ) : (
          <div {...addAttribute}>職員名簿を追加する</div>
        )}
      </div>
    </div>
  );
}

function Contact() {
  const [data, dispatch] = useContext(BasicContext);
  const state = data.contact;
  const target = 'contact';
  const width = 60;

  const rowJsx = state.map((contact, index) => {
    const departmentAttribute = {
      name: 'department',
      state: contact.department,
      onChange: (e) => dispatch({keys: [target, index], name: e.name, value: e.value}),
    };

    const tel1Attribute = {
      name: 'tel',
      type: 'number',
      width: width,
      state: contact.tel[0],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 0, value: e.value}),
    };

    const tel2Attribute = {
      name: 'tel', 
      type: 'number',
      width: width,
      state: contact.tel[1],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 1, value: e.value}),
    };

    const tel3Attribute = {
      name: 'tel', 
      type: 'number',
      width: width,
      state: contact.tel[2],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 2, value: e.value}),
    };

    const fax1Attribute = {
      name: 'fax', 
      type: 'number',
      width: width,
      state: contact.fax[0],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 0, value: e.value}),
    };

    const fax2Attribute = {
      name: 'fax', 
      type: 'number',
      width: width,
      state: contact.fax[1],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 1, value: e.value}),
    };

    const fax3Attribute = {
      name: 'fax', 
      type: 'number',
      width: width,
      state: contact.fax[2],
      onChange: (e) => dispatch({keys: [target, index, e.name], name: 2, value: e.value}),
    };

   return (
      <React.Fragment key={`contact-${index}`}>
        <span className={Styles.basicContactName}>
          <Input.Text {...departmentAttribute} />
        </span>
        <span className={Styles.basicContactTel}>
          <Input.Text {...tel1Attribute} /><i />
          <Input.Text {...tel2Attribute} /><i />
          <Input.Text {...tel3Attribute} />
        </span>
        <span className={Styles.basicContactFax}>
          <Input.Text {...fax1Attribute} /><i />
          <Input.Text {...fax2Attribute} /><i />
          <Input.Text {...fax3Attribute} />
        </span>
      </React.Fragment>
    );
  });

  const addAttribute = {
    className: Styles.flatButton,
    onClick: () => dispatch({behavior: 'add', name: target}),
  };

  const dndState = {
    prefix: `contact-dnd`,
    state: state,
    initialState: cloneDeep(Store.initialContactState),
    callback: (e) => {
      dispatch({behavior: 'dnd', name: target, value: e});
    },
    head: [
      ['部署名', Styles.basicContactName],
      ['TEL', Styles.basicContactTel],
      ['FAX', Styles.basicContactFax],
    ],
  };

  return (
    <div>
      <h4>TEL/FAX</h4>
      <div className={Styles.basicContactWrap} data-count={rowJsx.length}>
      {0 < rowJsx.length ? (
        <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
      ) : (
        <div {...addAttribute}>TEL/FAXを追加する</div>
      )}
      </div>
    </div>
  );
}


export default Basic;
