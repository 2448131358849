import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

const initialState = {
  input: {value: '', error: null},
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch(type) {
  case 'initialize':
    return action.initialState || cloneDeep(initialState);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;
  state[name].error = checkTypeDefault(name, value);
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;
  switch (name) {
  case 'input':
    error = validation(value, {require: true, max: 100});
    break;
  default:
    break;
  }

  return error;
}

export function checkError(state) {
  const _state = cloneDeep(state);
  let error = false;

  _state.input.error = checkTypeDefault('input', _state.input.value);
  if (null !== _state.input.error) {
    error = true;
  }

  return (error ? _state : null);
}

export async function issue(state) {
  const query = `{
    forgotPassword(input: "${state.input.value}")
  }`;

  return await gql.query(query);
}
