import React from 'react';
import * as Styles from 'src/css/components/input.module.scss';

function Checkbox(props) {
  const {
    name = '',
    state: {
      value = '',
    },
    data = [0, 1], // [off, on]
    onChange = () => {},
    children = null,
    space = false,
    readOnly = false,
  } = props;

  const checked = (data[1] === value ? true : false);
  const isSpace = (space ? 'true' : 'false');

  return (
    <div className={Styles.checkbox} data-space={isSpace} data-component="input">
      <input type="checkbox" name={name} id={name} checked={checked} onChange={(e) => {
        if (readOnly) {
          return;
        }

        if (true === e.target.checked) {
          onChange({name: name, value: data[1]});
        } else {
          onChange({name: name, value: data[0]});
        }
      }} />
      <label htmlFor={name}>{children}</label>
    </div>
  );
}

export default Checkbox;
