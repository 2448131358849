import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import AppContext from 'src/app/context';
import Context from './context';
import * as Styles from 'src/css/screens/notice-register.module.scss';

function Main() {
  const [app] = useContext(AppContext);
  const [state, dispatch, mode] = useContext(Context);
  
  const titleAttribute = {
    name: 'title',
    required: true,
    width: 600,
    state: state.title,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const bodyAttribute = {
    name: 'body',
    required: true,
    state: state.body,
    title: state.title.value,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const targetAttribute = {
    name: 'target',
    data: app.data.targets,
    state: state.target,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  if (app.isRoleMember) {
    targetAttribute.readOnly = true;
    targetAttribute.data = app.data.targets.filter(([, key]) => 'member' === key);
  }

  if (app.isRoleGuest) {
    targetAttribute.readOnly = true;
    targetAttribute.data = app.data.targets.filter(([, key]) => 'guest' === key);
  }

  const stateAttribute = {
    name: 'state',
    data: app.data.states,
    state: state.state,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };
  
  if (!app.isRoleAdmin) {
    if ('register' === mode) {
      stateAttribute.readOnly = true;
      stateAttribute.data = app.data.states.filter(([, key]) => 'draft' === key);
    } else {
      stateAttribute.data = app.data.states.filter(([, key]) => ('draft' === key || 'cancel' === key));
    }
  }

  const dateAttribute = {
    name: 'publishedAt',
    time: true,
    required: true,
    state: state.publishedAt,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <ul>
        <li><Input.Select {...targetAttribute}>対象</Input.Select></li>
        <li><Input.Select {...stateAttribute}>公開状態</Input.Select></li>
        <li className={Styles.date}><Input.Date {...dateAttribute}>公開日</Input.Date></li>
      </ul>
      <div><Input.Text {...titleAttribute}>タイトル</Input.Text></div>
      <div><Input.Editor {...bodyAttribute}>本文</Input.Editor></div>
    </Section.Register>
  );
}

export default Main;
