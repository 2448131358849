import React, {useRef, useEffect, useContext, useState, useReducer} from 'react';
import moment from 'moment';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import * as Store from './store';
import Context from './context';
import Project from './project';
import * as ProjectStore from './project/store';
// import Join from './join';
import * as JoinStore from './join/store';
import Answer from './answer';
import * as AnswerStore from './answer/store';
import * as Styles from 'src/css/screens/project-register.module.scss';


function ProjectRegister(props) {
  const [app, loading, toast, modal] = useContext(AppContext);
  const [projectState, projectDispatch] = useReducer(ProjectStore.reducer, null);
  const [joinState, joinDispatch] = useReducer(JoinStore.reducer, null);
  const [answerState, answerDispatch] = useReducer(AnswerStore.reducer, null);
  const [state, setState] = useState(null);
  const title = useRef(null);
  const at = useRef(null);
  const org = useRef({});
  const {params: {_id = null, mode = 'register'}} = props.match || {};

  const init = async () => {
    if (null !== _id && 'edit' === mode) {
      const {errors, project, join, tmpJoins} = await Store.init(_id);

      if (0 < errors.length) {
        toast('error');
      } else if (null === project) {
        props.history.push('/404');
      } else {
        // 編集
        title.current = project.title;
        org.current = {
          state: project.state,
          tmpJoins: tmpJoins || [],
        };
        at.current = {
          registed: project.publishedAt,
          updated: project.updatedAt,
        };
        const isRegister = (project.registedBy._id === app._id);
        
        projectDispatch({type: 'initialize', initialState: project, isRoleAdmin: app.isRoleAdmin, isRegister: isRegister});
        joinDispatch({type: 'initialize', initialState: join, tmp: tmpJoins});
        setState({'state': true, 'isRegister': isRegister});
      }
    } else {
      // 新規登録
      const {errors, questionnaires} = await Store.findQuestionnaires();
      if (0 < errors.length) {
        toast('error');
      } else {
        projectDispatch({type: 'initialize', proposer: (app.isRoleAdmin ? null : app.museumName)});
        joinDispatch({type: 'initialize'});
        answerDispatch({type: 'initialize', initialState: questionnaires});
        setState({'state': true});
      }
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (true === app.isLoading || null === state) {
    return null;
  }
  

  const saveAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }

      if ((app.isRoleAdmin) && 'edit' === mode) {
        const {errors: tmpJoinsError, tmpJoins} = await Store.findTmpJoins(_id);

        if (0 < tmpJoinsError.length) {
          toast('error');
          return;
        }

        let updateJoin = false;
        if (tmpJoins.length !== org.current.tmpJoins.length) {
          updateJoin = true;
        } else {
          const ids = tmpJoins.map(e => e._id);
          const baseIds = org.current.tmpJoins.map(e => e._id);
          updateJoin = baseIds.some((id) => {
            return -1 === ids.indexOf(id);
          });
        }

        if (updateJoin) {
          modal({
            message: '参加・検討館情報が更新されています。<br />ページを再読み込みします。',
            okHandler: () => {},
            closeHandler: () => {
              window.location.reload();
            }, 
          });
          return;
        }
      }

      {
        const errors = [];
        const {error: projectError, _state: _projectState} = ProjectStore.checkAll(projectState);
        errors.push(projectError);
        const {error: joinError, _state: _joinState} = JoinStore.checkAll(joinState);
        errors.push(joinError);

        if (errors.some(e => true === e)) {
          projectDispatch({type: 'initialize', initialState: _projectState, replace: true});
          joinDispatch({type: 'initialize', initialState: _joinState, replace: true});
          toast('warning');
          return;
        }
      }

      {
        loading(true);
        const {errors} = ('register' === mode ? await Store.register(projectState, joinState, answerState) : await Store.edit(_id, projectState, joinState));

        if (0 === errors.length) {
          toast('save');
          props.history.push('/project');
        } else {
          toast('error');
          loading(false);
        }
      }
    },
  };

  return (
    <Context.Provider value={[_id, mode, [projectState, projectDispatch], [joinState, joinDispatch], [answerState, answerDispatch], org.current]}>
      <Header.Member pathname={props.location.pathname} params={{title: title.current, _id}} />
      {app.isRoleAdmin && at.current && 'edit' === mode && (
        <section className={Styles.at}>
          <div><span>提案時期：</span>{moment(at.current.registed).format('YYYY/MM/DD HH:mm:ss')}</div>
          <div><span>更新時期：</span>{moment(at.current.updated).format('YYYY/MM/DD HH:mm:ss')}</div>
        </section>
      )}
      {!app.isRoleAdmin && 'register' === mode && (<Answer />)}
      <Project />
      {/*
      {app.isRoleAdmin && (<Join />)}
      */}
      {!app.isRoleAdmin && (
        <Section.Strong>
          申請された内容は承認後に公開されます。<br />
          承認が完了されているかどうかは、「【美連協ポータル】提案企画が公開されました」をご確認ください。
        </Section.Strong>
      )}
      <nav data-align="left">
        <Input.Button {...saveAttribute}>
          {(!app.isRoleAdmin && !state.isRegister) && (<>申請</>)}
          {(app.isRoleAdmin || state.isRegister) && (<>保存</>)}
        </Input.Button>
      </nav>
    </Context.Provider>
  );
}

export default ProjectRegister;
