import React from 'react';
import * as Styles from 'src/css/components/footer.module.scss';

function Guest() {
  return (
    <footer className={Styles.guest}>
      <div>&copy; 2013 The Japan Association of Art Museums.</div>
    </footer>
  );
}

export default Guest;
