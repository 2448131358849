import * as gql from 'src/utils/gql';

export async function find(_id) {
  const query = `{
    project(_id: "${_id}") {
      state
      type
      new
      title
      subTitle
      body
      file {
        src
        caption
      }
      subFile
      note
      contact
      proposer
      period {
        start {
          y
          m
          d
          about
        }
        end {
          y
          m
          d
          about
        }
        any
        note
      }
      registedBy {
        _id
        role
        original {
          name {
            ja
          }
        }
      }
      publishedAt
      registedAt
      updatedAt
    }
    join(projectId: "${_id}") {
      member {
        name
        new
        museum {
          _id
          original {
            name {
              ja
            }
          }
        }
        state
        period {
          start {
            y
            m
            d
            about
          }
          end {
            y
            m
            d
            about
          }
          pending
          note
        }
      }
    }
    tmpJoinMe(projectId: "${_id}") {
      _id
      move
      member {
        state
        period {
          start {
            y
            m
            d
            about
          }
          end {
            y
            m
            d
            about
          }
          pending
          note
        }
        note
      }
    }
  }`;

  const {errors, data: {project = {}, join = null, tmpJoinMe = null}} = await gql.query(query);
  return {errors, project, join, tmpJoinMe};
}

export async function remove(_id) {
  const query = `removeProject($_id: ID!) {
    removeProject(_id: $_id)
  }`;
  
  const {errors} = await gql.mutation(query, {_id});
  return {errors};
}
