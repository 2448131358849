import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import * as Common from 'src/utils/common';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import Context from '../context';
import Main from '../main';
import Answer from './answer';
import * as Store from './store';

function Member(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [refresh, setRefreshState] = useState(false);
  const [state, setState] = useState(null);
  const {params: {_id}} = props.match || {};

  const init = async (_id) => {
    const {errors, questionnaire, answer} = await Store.find(_id);

    if (0 < errors.length || null === questionnaire) {
      setState(null);
      toast('error');
    } else {
      setState({questionnaire, answer});
    }

    loading(false);
  }

  useEffect(() => {
    init(_id);
    
    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, refresh]);

  if (true === app.isLoading || null === state) {
    return null;
  }

  const canAnswer = (null === state.answer);
  const {title, type} = state.questionnaire;

  return (
    <Context.Provider value={[_id, props.history, state, setRefreshState]}>
      <Header.Member pathname={props.location.pathname} params={{title}} />
      <nav>
        <Input.Button onClick={() => props.history.push('/questionnaire')}>戻る</Input.Button>
      </nav>
      <Main />
      {canAnswer && 'text' === type && (<Answer />)}
      {canAnswer && 'project' === type && (
        <section>
          <Input.Button to="/project/register">アンケート用展覧会企画を作成する</Input.Button>
        </section>
      )}
      {!canAnswer && (
        <section data-type="card">
          {'text' === type && (<><h3>回答</h3>{Common.convertFeedCodeToJsx(state.answer.text)}</>)} 
          {'project' === type && (<Input.Button to={`/project/${state.answer.projectId}`}>回答した企画をみる</Input.Button>)} 
        </section>
      )}
    </Context.Provider>
  );
}

export default Member;
