import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import AppContext from 'src/app/context';
import Context from './context';
import * as Styles from 'src/css/screens/home.module.scss';

function Count() {
  const [app] = useContext(AppContext);

  if (app.isRoleAdmin) {
    return (<Admin />);
  } else {
    return (<Member />);
  }
}

function Admin() {
  const [, count] = useContext(Context);

  return (
    <section data-type="card" className={Styles.count}>
      <h3>承認待ち</h3>
      <ul>
        <li><Link to="/museum/draft">登録情報編集: {count.draft.museums}件</Link></li>
        <li><Link to="/notice/draft">お知らせ: {count.draft.notices}件</Link></li>
        <li><Link to="/project/draft">企画: {count.draft.projects}件</Link></li>
        <li><Link to="/project/join">企画 開催・検討: {count.join.projects}件</Link></li>
      </ul>
    </section>
  );
}

function Member() {
  const [, count] = useContext(Context);

  return (
    <section data-type="card" className={Styles.count}>
      <h3>申請差戻し</h3>
      <ul>
        <li><Link to="/notice/remand">お知らせ: {count.remand.notices}件</Link></li>
        <li><Link to="/project/remand">企画: {count.remand.projects}件</Link></li>
      </ul>
    </section>
  );
}

export default Count;
