import cloneDeep from 'lodash.clonedeep';

const initialState = {
  questionnaire: {value: '', error: null, data: []},
  text: {value: '', error: null},
};

export function reducer(state, action) {
  const {initialState: questionnaires, type = null, name = null, value = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    const data = questionnaires.map((item) => {
      const {_id, title} = item;
      return [title, _id];
    });

    const state = cloneDeep(initialState);
    if (0 < data.length) {
      state.questionnaire.value = ''; 
      state.questionnaire.data = [['必要に応じて選択', ''], ...data];
    }

    return state;
  default:
    console.log(action);
    _state[name].value = value;
    return _state;
  }
}
