import React, {useState, useEffect, useReducer, useContext} from 'react';
import AppContext from 'src/app/context';
import * as Footer from 'src/components/footer';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as AppStore from 'src/app/store';
import {Link} from 'react-router-dom';
import * as Store from './store';
import * as Styles from 'src/css/screens/signin.module.scss';

function Signin() {
  const [app, loading, , , dispatch] = useContext(AppContext);
  const [state, setState] = useReducer(Store.reducer, Store.initialState);
  const [message, setMesaage] = useState(null);

  useEffect(() => {
    loading(false);

    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const enter = (e) => {
    if (13 === e.charCode) {
      signin();
    }
  };

  const idAttribute = {
    name: 'id',
    onKeyPress: enter,
    state: state.id,
    onChange: (e) => setState({name: e.name, value: e.value}),
  };

  const passwordAttribute = {
    name: 'password',
    type: 'password',
    onKeyPress: enter,
    state: state.password,
    onChange: (e) => setState({name: e.name, value: e.value}),
  };

  const signin = async () => {
    if (true === app.isLoading) {
      return;
    }

    // 入力チェック
    if (true === Store.isError(state)) {
      // エラーメッセージをセット
      for (const [key, item] of Object.entries(state)) {
        setState({name: key, value: item.value});
      }
      return;
    }

    loading(true);

    // token発行
    {
      const {errors} = await Store.signin(state);
      if (0 < errors.length) {
        setMesaage('IDまたはパスワードが違います');
        loading(false);
        return;
      }
    }

    // 初期データ設定
    {
      const {errors, data} = await AppStore.init();
      if (0 < errors.length) {
        setMesaage('ログインに失敗しました');
        loading(false);
        return;
      }

      dispatch({type: 'initialize', value: data});
    }
  };

  const signinAttribute = {onClick: signin};

  const bannersAttribute = {
    lion: {href: 'https://www.lion.co.jp/ja/', target: '_blank'},
    dnp: {href: 'https://www.dnp.co.jp/', target: '_blank'},
    sonpojp: {href: 'https://www.sjnk.co.jp/', target: '_blank'},
  };

  return (
    <>
      <Header.Guest className={Styles.header} />
      <section className={Styles.section}>
        {null !== message && (
          <small>{message}</small>
        )}
        <div><Input.Text {...idAttribute}>ID</Input.Text></div>
        <div><Input.Text {...passwordAttribute}>パスワード</Input.Text></div>
        <ul className={Styles.buttonRow}>
          <li className={Styles.forgotPassword}><Link to="/forgot-password">パスワードをお忘れの方はこちら。</Link></li>
          <li><Input.Button {...signinAttribute}>ログイン</Input.Button></li>
        </ul>
      </section>
      <Footer.Guest />
    </>
  );
}

export default Signin;
