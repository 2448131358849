import React, {useRef, useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import Context from './context';
import Basic from './basic';
import * as Styles from 'src/css/screens/museum-register.module.scss';

const type = 'annex';

function Annex() {
  const [state, dispatch] = useContext(Context);
  const frameRef = useRef();
  const add = () => {
    frameRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    dispatch({type: type, behavior: 'add'});
  };

  const addAttribute = {
    className: Styles.flatButton,
    onClick: add,
  };

  const tab = [];
  const bodyJsx = [];
  state.annex.forEach((item, index) => {
    tab.push(`分館（${index + 1}）`);

    const removeAttribute = {
      type: 'remove',
      onClick: () => {
        frameRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        dispatch({type: type, behavior: 'remove', index: index});
      },
    };

    bodyJsx.push(
      <React.Fragment key={`annex-${index}`}>
        <Basic data={item} dispatch={(value) => {
          dispatch({type: type, index: index, value: value});
        }} />
        <div className={Styles.annexRemoveButton}>
          <Input.Button {...removeAttribute}>削除</Input.Button>
        </div>
      </React.Fragment>
    );
  });

  const tabAttribute = {
    type: 'register',
    tab: tab,
    onClick: add,
  };

  return (
    <span ref={frameRef}>
      {0 < bodyJsx.length ? (
        <Section.Tab {...tabAttribute}>{bodyJsx}</Section.Tab>
      ) : (
        <section>
          <div {...addAttribute}>分館を追加する</div>
        </section>
      )}
    </span>
  );
}

export default Annex;
