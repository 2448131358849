import React, {useReducer, useContext, useEffect} from 'react';
import AppContext from 'src/app/context';
import * as Footer from 'src/components/footer';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as Store from './store';
import * as Styles from 'src/css/screens/forgot-password.module.scss';

function ForgotPassword(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, null);

  useEffect(() => {
    dispatch({type: 'initialize'});
    loading(false);
    
    return () => {
      loading(true);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (true === app.isLoading || null === state) {
    return null;
  }

  const inputAttribute = {
    name: 'input',
    state: state.input,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const sendAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }

      const error = Store.checkError(state);
      if (null !== error) {
        dispatch({type: 'initialize', initialState: error});
        toast('warning');
        return;
      }
      loading(true);

      const {errors} = await Store.issue(state);
      if (0 < errors.length) {
        toast('error');
        loading(false);
      } else {
        toast('send');
        props.history.push('/');
      }

    },
  };


  return (
    <>
      <Header.Guest />
      <section className={Styles.section}>
        <aside>
          IDまたは代表メールアドレスを入力して送信ボタンを押下してください。<br />
          <br />
          代表メールアドレスへパスワード再発行用のメールが送信されます。
        </aside>
        <div><Input.Text {...inputAttribute} /></div>
        <div className={Styles.button}><Input.Button {...sendAttribute}>送信</Input.Button></div>
      </section>
      <Footer.Guest />
    </>
  );
}

export default ForgotPassword;
