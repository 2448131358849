import * as gql from 'src/utils/gql';

export async function findAll() {
  const query = `{
    questionnaires {
      _id
      state
      title
      type
      target
      startAt
      endAt
    }
  }`;

  const {errors, data: {questionnaires = []}} = await gql.query(query);
  return {errors, questionnaires};
}





