import moment from 'moment';
import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import Badge from 'src/components/badge';
import * as StylesChart from 'src/css/screens/chart.module.scss';
import Context from './context';

function Member() {
  const [state, url, tab, limit] = useContext(Context);

  const tabAttribute = {
    active: url,
    to: [
      ['公開', '/notice'],
      ['提案', '/notice/proposer'],
      ['差戻し', '/notice/remand'],
    ],
  };

  const rowJsx = state.map((item, index) => {
    const to = `/notice/${'default' !== tab ? `${tab}/` : ''}${item._id}`;
    const at = moment(item.publishedAt);
    const isNew = ('default' === tab && at.isAfter(limit));

    let badgeType = item.type;
    if  ('member' === badgeType) {
      if (!item.registedBy) {
        badgeType = '';
      } else if ('guest' === item.registedBy.role) {
        badgeType = 'guest';
      }
    }

    return (
      <li key={`notice-${index}`}>
        <span data-width="date">{at.format('YYYY.MM.DD')}</span>
        <span data-width="auto">
          {isNew && (<Badge type="new" />)}
          <Badge type={badgeType} />
          <Input.Link to={to}>{item.title}</Input.Link>
        </span>
      </li>
    );
  });

  return (
    <Section.TabLink {...tabAttribute}>
      <ul className={StylesChart.ul}>{rowJsx}</ul>
    </Section.TabLink>
  );
}

export default Member;
