import React, {useReducer, useContext} from 'react';
import AppContext from 'src/app/context';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import * as Store from './store';
import Context from '../context';

function Answer() {
  const [_id, , , refresh] = useContext(Context);
  const [app, loading, toast, modal] = useContext(AppContext);
  const [state, dispatch] = useReducer(Store.reducer, Store.initialState);
  
  const textAttribute = {
    name: 'text',
    cols: 100,
    rows: 10,
    state: state.text,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const save = async () => {
    if (true === app.isLoading) {
      return;
    }
      
    const {error, _state} = Store.checkAll(state);
    if (true === error) {
      dispatch({type: 'initialize', initialState: _state});
      toast('warning');
      return;
    }

    loading(true);
    const {errors} = await Store.register(_id, state);

    if (0 === errors.length) {
      toast('save');
      refresh(true);
    } else {
      toast('error');
      loading(false);
    }
  };

  const saveAttribute = {
    onClick: () => {
      modal({
        message: '回答しますか？<br />（回答は一度きりです）',
        okHandler: save,
        cancelHandler: () => {},
      });
    },
  };

  return (
    <>
      <Section.Register>
        <h3>回答</h3>
        <Input.Textarea {...textAttribute} />
      </Section.Register>
      <nav data-align="left"><Input.Button {...saveAttribute}>送信</Input.Button></nav>
    </>
  );
}

export default Answer;
