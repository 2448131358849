import React, {useState, useContext} from 'react';
import {Editor as Tinymce} from '@tinymce/tinymce-react';
import Button from './button';
import AppContext from 'src/app/context';
import * as Styles from 'src/css/components/input.module.scss';



function Preview(props) {
  const [, , , modal] = useContext(AppContext);
  const {title, contents} = props;

  const attribute = {
    onClick: () => {
      modal({type: 'preview', contents: `${(title ? `<h2>${title}</h2>` : '')}${contents}`});
    },
  };

  return (
    <div className={Styles.preview}>
      <Button {...attribute}>プレビュー</Button>
    </div>
  );
}

function Editor(props) {
  const [focus, setFocusState] = useState('off');

  const {
    name = '',
    children = null,
    height = 500,
    onChange = () => {},
    required = false,
    state: {
      value = '',
      error = null,
    },
    readOnly = false,
    title = null,
  } = props;

  const isError = (null !== error ? 'true' : 'false');
  const isRequired = (true === required && false === readOnly ? 'true' : 'false');

  return (
    <div className={Styles.editor} data-input-error={isError} data-focus={focus}>
      <div className={Styles.head}>
        {null !== children && (
          <label  data-required={isRequired}>{children}</label>
        )}
        <Preview title={title} contents={value} />
      </div>
      <div className={Styles.wrap} style={{height: `${height + 8}px`}}>
        <Tinymce
          initialValue={value}
          apiKey="ctja130jjk0x8exy5czlu3qpdavidb6du9aouiofqlqr1zir"
          init={{
            height: height,
            menubar: false,
            statusbar: false,
            content_css: [
              '//fonts.googleapis.com/css?family=Noto+Serif+JP:300,400,500,600,700&display=swap'
            ],
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code',
              'insertdatetime media table paste code wordcount'
            ],
            toolbar: 'formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | removeformat',
          }}
          onFocus={() => {setFocusState('on')}}
          onBlur={() => {setFocusState('off')}}
          onChange={(e) => {
            onChange({name: name, value: e.target.getContent()});
          }}
        />
      </div>
      <small>{error}</small>
    </div>
  );
}

export default Editor;
