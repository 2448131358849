import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Dnd from 'src/components/dnd';
import Context from '../context';

function SubFile() {
  const [, , [state, dispatch]] = useContext(Context);
  const rowJsx = [];
  const dndState = {
    add: false,
    remove: false,
    dnd: false,
  };

  const fileAttribute = {
    name: 'subFfile',
    accept: '.png,.jpg,.pdf,.doc,.docx,.xlsx,.zip',
    state: state.subFile, 
    onChange: (e) => dispatch({name: 'subFile', value: e.file}),
  };

  rowJsx.push(
    <React.Fragment key="sub-file">
      <span><Input.File {...fileAttribute} /></span>
    </React.Fragment>
  );

  return (
    <section>
      <h3>追加資料</h3>
      <Dnd.Ul {...dndState}>{rowJsx}</Dnd.Ul>
    </section>
  );
}

export default SubFile;
