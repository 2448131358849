import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import AppContext from 'src/app/context';
import Context from './context';

function Owner() {
  const [app] = useContext(AppContext);
  const [state, dispatch, mode, owner, type] = useContext(Context);

  let ownerName = '';
  if ('edit' === mode) {
    ownerName = (owner) ? owner.name : '';
  } else {
    if (app.isRoleAdmin) {
      ownerName = app.name;
    } else {
      ownerName = app.museumName;
    }
  }

  const ownerAttribute = {
    readOnly: true,
    state: {value: ownerName, error: null},
  };

  const contactAttribute = {
    name: 'contact',
    rows: 4,
    cols: 60,
    state: state.contact,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };


  const viewOwner = (!app.isRoleAdmin || 'member' === type) ? true : false;

  return (
    <Section.Register>
      {viewOwner && (
        <div><Input.Text {...ownerAttribute}>申請館</Input.Text></div>
      )}
      <div><Input.Textarea {...contactAttribute}>連絡先</Input.Textarea></div>
    </Section.Register>
  );
}

export default Owner;
