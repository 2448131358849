import cloneDeep from 'lodash.clonedeep';
import React, {useContext} from 'react';
import moment from 'moment';
import AppContext from 'src/app/context';
import * as Common from 'src/utils/common';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import * as Anchor from 'src/components/anchor';
import Context from '../context';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import * as ScreenStyles from 'src/css/screens/questionnaire-detail.module.scss';

function sortToArea(areas, data) {
  const _areas = areas.map((item) => {
    const [name, id] = item;
    return {name, id, museums: []};
  });

  for (const item of data) {
    for (const _area of _areas) {
      if (item.area === _area.id) {
        _area.museums.push(item);
        break;
      }
    }
  }

  return _areas;
}


function Answer() {
  const [app, , , modal] = useContext(AppContext);
  const [params, history, state] = useContext(Context);
  const {questionnaire, answers, museums} = state;
  const type = questionnaire.type;

  const answerIds = answers.map(item => item.registedBy);
  const _museums = museums.map((item) => {
    const {_id, indent, original: {area, name: {ja}}} = item;
    const index = answerIds.indexOf(_id);

    let answer = null;
    if (-1 < index) {
      answer = {
        text: answers[index].text,
        projectId: answers[index].projectId,
        registedAt: answers[index].registedAt,
      };
    }

    return {
      name: ja,
      indent,
      area,
      answer,
    };
  });

  const _areas = sortToArea(cloneDeep(app.data.areas), _museums);
  _areas.forEach((area, index) => {
    area.id = `area-${area.id}`;
  });

  const tabElementId = 'answer-tab';

  const areaJsx = _areas.map((area, index) => {
    const rowJsx = area.museums.map((item, index) => {
      const {name, indent, answer} = item;

      const buttonAttribute = {};
      if (answer) {
        if ('text' === type) {
          buttonAttribute.onClick = () => {
            modal({type: 'preview', jsx: Common.convertFeedCodeToJsx(answer.text)});
          };
        } else if ('project' === type) {
          buttonAttribute.to = `/project/${answer.projectId}`;
        }
      }

      return (
        <li key={`${area.id}-item-${index}`}>
          <span className={ScreenStyles.button}>
            {answer && (
              <Input.Button {...buttonAttribute}>
                {'text' === type && (<>回答</>)}
                {'project' === type && (<>企画</>)}
              </Input.Button>
            )}
          </span>
          <span data-width="date">{answer ? moment(answer.registedAt).format('YYYY.MM.DD') : ''}</span>
          <span data-width="auto" data-indent={indent} className={ScreenStyles.indent}>{name}</span>
        </li>
      );
    });

    const {name, id} = area;
    return (
      <section data-type="card" key={`area-${index}`} id={id} className={ScreenStyles.area}>
        <h3>{name}</h3>
        <ul className={ChartStyles.head}>
          <li className={ScreenStyles.button}></li>
          <li data-width="date">回答日</li>
          <li data-width="auto">美術館名</li>
        </ul>
        <ul className={ChartStyles.ul}>{rowJsx}</ul>
        <Anchor.Back to={tabElementId}>TOPへ戻る</Anchor.Back>
      </section>
    );
  });

  const {_id, tab} = params;
  const tabAttribute = {
    id: tabElementId,
    to: [
      ['加盟館', `/questionnaire/${_id}/member`],
      ['非加盟館', `/questionnaire/${_id}/guest`],
    ],
    active: `${history.location.pathname}${void 0 === tab ? '/member' : ''}`,
    padding: false,
  };

  return (
    <>
      <Section.TabLink {...tabAttribute}>
        <Anchor.Nav data={_areas.map(e => [e.name, e.id])} />
      </Section.TabLink>
      {areaJsx}
    </>
  );
}

export default Answer;
