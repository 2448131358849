import React, {useReducer, useEffect, useContext, useRef} from 'react';
import * as Input from 'src/components/input';
import AppContext from 'src/app/context';
import * as Section from 'src/components/section';
import * as Store from './store';
import DetailContext from '../context';

const Context = React.createContext([{}, () => {}]);


function Join() {
  const control = useRef(null);
  const [app, loading, toast] = useContext(AppContext);
  const [detailState, reload] = useContext(DetailContext);
  const [state, dispatch] = useReducer(Store.reducer, null);
  const join = detailState.tmpJoinMe;


  const init = async () => {
    let useStateKey = [];
    let canEdit = false;
    const orgState = (join ? join.member.state : null);

    switch (orgState) {
    case 'consider-remand': // 検討差戻し
      canEdit = true;
      useStateKey = ['consider-apply', 'cancel'];
      break;
    case 'consider': // 検討
    case 'hold-remand': // 開催差戻し
      canEdit = true;
      useStateKey = ['hold-apply', 'cancel'];
      break;
    case 'consider-apply': // 検討申請中
    case 'hold-apply': // 開催申請中
    case 'hold': // 開催
    case 'cancel': // 取り消し
      useStateKey = ['cancel'];
      break;
    default: // 新規
      // useStateKey = ['consider-apply'];
        useStateKey = ['consider'];
      canEdit = true;
      break;
    }

    control.current = {
      state: app.data.join.state.filter(e => (-1 < useStateKey.indexOf(e[1]))),
      orgState,
      canEdit,
    };

    if (join) {
      dispatch({type: 'initialize', initialState: join});
    } else {
      dispatch({type: 'initialize'});
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (null === state) {
    return null;
  }

  const saveAttribute = {
    onClick: async () => {
      if (true === app.isLoading) {
        return;
      }
      
      const {error, _state} = Store.checkAll(state);
      if (true === error) {
        dispatch({type: 'initialize', initialState: _state, replace: true});
        toast('warning');
        return;
      }

      loading(true);
      const {errors} = (join ? await Store.edit(join._id, state) : await Store.register(detailState._id, state));

      if (0 === errors.length) {
        toast('save');
        reload();
      } else {
        toast('error');
        loading(false);
      }
    },
  };

  return (
    <Context.Provider value={[state, dispatch, control.current]}>
      <Main />
      {'cancel' !== control.current.orgState && (
        <nav data-align="left"><Input.Button {...saveAttribute}>申請</Input.Button></nav>
      )}
    </Context.Provider>
  );
}



function Main() {
  const [state, dispatch, controle] = useContext(Context);
  
  const noteAttribute = {
    name: 'note',
    state: state.note,
    rows: 8,
    cols: 78,
    required: true,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const periodNoteAttribute = {
    name: 'note',
    state: state.period.note,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type: 'period', name: e.name, value: e.value}),
  };
 
  const pendingAttribute = {
    name: 'pending',
    data: [0, 1],
    state: state.period.pending,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type: 'period', name: e.name, value: e.value}),
  };

  const stateAttribute = {
    name: 'state',
    data: controle.state,
    state: state.state,
    readOnly: ('cancel' === controle.orgState ? true : false),
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  return (
    <div data-print="off">
      {'cancel' === controle.orgState && (
        <Section.Strong>取下げ申請中です</Section.Strong>
      )}
      <Section.Register>
        <h3>開催検討</h3>
        <ul>
          <li><Input.Select {...stateAttribute} /></li>
          <li><Input.Checkbox {...pendingAttribute}>詳細未定</Input.Checkbox></li>
        </ul>
        <Date root="start" state={state.period.start} />
        <Date root="end" state={state.period.end} />
        <div><Input.Text {...periodNoteAttribute}>備考</Input.Text></div>
        <div><Input.Textarea {...noteAttribute}>連絡先（担当者名、TEL、メールアドレスを記載ください。）</Input.Textarea></div>
      </Section.Register>
    </div>
  );
}


function Date(props) {
  const [app] = useContext(AppContext);
  const [, dispatch, controle] = useContext(Context);
  const {root: key, state} = props;
  const type = 'period';

  const yAttribute = {
    name: 'y',
    width: 100,
    state: state.y,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const mAttribute = {
    name: 'm',
    width: 50,
    state: state.m,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const dAttribute = {
    name: 'd',
    width: 50,
    state: state.d,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const aboutAttribute = {
    name: 'about',
    state: state.about,
    data: app.data.project.about,
    readOnly: !controle.canEdit,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  return (
    <ul>
      <li><dfn>{'start' === key ? '開幕' : '閉幕'}</dfn></li>
      <li><dfn>西暦</dfn>&nbsp;&nbsp;<Input.Text {...yAttribute} /><dfn>年</dfn></li>
      <li><Input.Text {...mAttribute} /><dfn>月</dfn></li>
      <li><Input.Text {...dAttribute} /><dfn>日</dfn></li>
      <li><Input.Select {...aboutAttribute} /></li>
    </ul>
  );
}



export default Join;
