import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import * as Styles from 'src/css/components/section.module.scss';


export function TabLink(props) {
  const {
    id = null,
    className = '',
    active = '',
    prefix = '',
    to = [],
    children = null,
    padding = true,
  } = props;

  const count = to.length;
  const rowJsx = to.map((item, index) => {
    const [title, to] = item;
    const attribute = {
      key: `${prefix}tablink-${index}`,
      to: to,
      style: {width: `calc(100% / ${count})`},
      'data-active': (to === active ? 'true' : 'false'),
    };

    return (<Link {...attribute}>{title}</Link>);
  });


  const attribute = {
    className: `${Styles.tabLink} ${className}`,
  };

  if (null !== id) {
    attribute.id = id;
  }

  const isPadding = (padding ? 'true' : 'false');
  
  return (
    <section data-type="card" data-padding={isPadding} {...attribute}>
      <nav className={Styles.head}>{rowJsx}</nav>
      <div className={Styles.wrap}>{children}</div>
    </section>
  );
}

export function Tab(props) {
  const [state, setState] = useState(0);
  const {
    className = '',
    prefix = '',
    type = 'normal',
    tab,
    children,
    onClick = () => {},
    padding = true,
  } = props;

  useEffect(() => {
    setState((state >= tab.length ? state - 1 : state));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.length]);
 
  const addButtonWidth = ('register' === type ? 60 : 0);
  const count = tab.length;
  const tabJsx = tab.map((item, index) => {
    const attribute = {
      key: `${prefix}tab-${index}`,
      onClick: () => {
        setState(index);
      },
      style: {width: `calc((100% - ${addButtonWidth}px) / ${count})`},
      'data-active': (index === state ? 'true' : 'false'),
    };

    return (
      <a {...attribute}><span>{item}</span></a>
    );
  });

  if ('register' === type) {
    const attribute = {
      className: Styles.addButton,
      style: {width: `${addButtonWidth}px`},
      onClick: () => {
        setState(tab.length);
        onClick();
      },
    };

    tabJsx.push(<em key={`${prefix}tab-add`} {...attribute} />);
  }

  const bodyJsx = children.map((item, index) => {
    const attribute = {
      key: `${prefix}tab-body-${index}`,
      className: Styles.li,
      'data-active': (index === state ? 'true' : 'false'),
    };

    return (<li {...attribute}>{item}</li>);
  });
 
  const isPadding = (padding ? 'true' : 'false');
  
  return (
    <section data-type={type} data-padding={isPadding} className={`${Styles.tab} ${className}`}>
      <nav className={Styles.nav}>{tabJsx}</nav>
      <ul className={Styles.ul}>{bodyJsx}</ul>
    </section>
  );
}

export function Register(props) {
  const {children} = props;

  return (
    <section className={Styles.register}>{children}</section>
  );
}

export function Strong(props) {
  const {
    className = '',
    children
  } = props;

  return (
    <section data-border="in" className={`${Styles.strong} ${className}`}>{children}</section>
  );
}
