import React from 'react';

function Icon(props) {
  const {type = null} = props;

  switch (type) {
  case 'signout':
    return (<svg id="icon-signout" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M135.65 58.4v122.66l36.06 27.31V94.47h83.16V58.4zM171.72 417.54v-71.66l.1-42.12-.1.07v-.2l-36.07 27.3V453.6h119.22v-36.06H171.8zM171.72 277.77l22.2-16.85a6.19 6.19 0 000-9.84l-22.2-16.84-36.07-27.31-26.33-19.94a6.16 6.16 0 00-9.89 4.95v27.3c0 3.4-2.73 6.18-6.13 6.18H6.18A6.16 6.16 0 000 231.56v48.89c0 3.4 2.78 6.13 6.18 6.13h87.13c3.4 0 6.12 2.78 6.12 6.18v27.3a6.16 6.16 0 009.9 4.95l26.32-19.94 36.06-27.3zM496.08 64.89l-12.16-3.5-10.3-2.99L314.8 12.55a27.37 27.37 0 00-7.47-1.09 26.78 26.78 0 00-26.7 26.8V473.75a26.78 26.78 0 0026.7 26.79c2.47 0 4.94-.36 7.47-1.09l158.83-45.85 10.3-2.99 12.16-3.5A22.02 22.02 0 00512 425.94V86.06c0-9.84-6.5-18.44-15.92-21.17zM325.66 277.36V234.7a5.02 5.02 0 015.04-5.05h16.75a5.02 5.02 0 015.05 5.05v42.66c0 2.78-2.27 5-5.05 5H330.7a5.01 5.01 0 01-5.04-5z"/></svg>);
  case 'account':
    return (<svg id="icon-account" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.8 0 0 114.88 0 256a254.61 254.61 0 0073.04 179.04C121.52 484.72 186.48 512 256 512c69.44 0 134.4-27.28 182.88-76.96C486 386.88 512 323.28 512 256 512 114.88 397.12 0 256 0zM103.87 410.46c42.4-26.71 97.17-55.98 101-56.86h2.41v-46c-5.52-2.88-9.28-10-11.84-17.04a80.45 80.45 0 01-4.02-15.8l-.01-.2h-.02l-.03-.16c-17.52 5.84-20.08-26.96-20.88-35.28-.8-7.52 2.56-12.8 4.88-14.88a40.7 40.7 0 01-1.92-5.04v-.08c-6.72-30.4-2.8-39.28 10.64-63.52 13.6-24.56 47.6-48.24 67.44-47.92 1.52 0 3.04 0 4.48.16 23.84 1.6 36.72 13.36 46.88 31.52 6.16 0 16.88 8.16 24.96 16.24a70.56 70.56 0 018.48 9.76c5.6 8 6.72 20.88 5.84 32.72-.64 8.08-2.08 15.76-3.68 21.04v.08a30.76 30.76 0 01-1.92 5.04c2.4 2.08 5.68 7.36 4.96 14.88-.88 8.32-3.44 41.12-20.88 35.28 0 .16 0 .32-.08.48-.8 5.52-3.68 25.84-15.84 32.72v46h2.32c3.58.82 51.35 26.24 92.07 51.3a838 838 0 018.95 5.6c-40.72 40.3-94.47 62.38-152.06 62.38-52.38 0-101.67-18.36-140.88-52.09-3.84-3.3-7.61-6.72-11.25-10.33z" /></svg>);
  case 'home':
    return (<svg id="icon-home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.87 284.24l.13-.1-98.37-98.57V76.1H358.8v32.03l-.11 22.43-102.5-102.7-.1-.1-.11-.12L40.47 243.6.09 284.02l.03.02-.12.1 50.8 34.52 1.23-1.21.78-.65v167.57H459.2v-98.4l.1-69.26 1.97 1.95 42.65-29 7.97-5.4-.02-.02zm-255.9-171.42l168.71 169.2v167.83H225.46v-165.7h-78.08v165.7H87.32V282.01l168.49-169.03.16-.16z"/><path d="M286 374.77v.16h80.84v-90.78H286v90.62zm20.71-20.56v-49.35h39.42v49.35H306.7z"/></svg>);
  case 'tw':
    return (<svg id="icon-tw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.56 0 0 114.64 0 256s114.56 256 256 256c141.36 0 256-114.64 256-256S397.36 0 256 0zm5.84 222.71l.16.01a54.83 54.83 0 0153.36-67.28c15.76 0 30 6.64 40 17.36a109.4 109.4 0 0028.94-10.19c1.95-.97 3.9-1.93 5.8-3a54.64 54.64 0 01-17.57 25.47 50.89 50.89 0 01-6.46 4.52c.1 0 .2-.03.3-.04l-.29.2a108.84 108.84 0 0025.71-6.28c1.87-.66 3.74-1.34 5.6-2.09a110.93 110.93 0 01-27.23 28.13c.16 2.4.16 4.72.16 7.12 0 72.4-55.12 155.92-155.92 155.92a154.6 154.6 0 01-84-24.64 109.46 109.46 0 0064.83-12.14c.33-.18.67-.32 1-.5.98-.54 1.93-1.15 2.89-1.71 4.27-2.48 8.43-5.16 12.4-8.2l-.2-.03.2-.14a54.83 54.83 0 01-47.92-30.06 55.11 55.11 0 01-3.18-7.92c3.33.63 6.73.95 10.22.95 3.64 0 7.13-.46 10.55-1.13 1.3-.2 2.61-.37 3.9-.64l-.3-.09c.1-.03.22-.03.33-.07a54.84 54.84 0 01-44-53.75v-.64a54.86 54.86 0 0019.15 6.29c1.87.28 3.75.52 5.64.64l-.2-.16.29.02a54.71 54.71 0 01-24.4-45.6c0-8.59 2.1-16.71 5.67-23.98.6-1.16 1.13-2.35 1.81-3.48a155.27 155.27 0 00111.18 57.01c.53.04 1.05.14 1.59.17l-.01-.05z" fill="#4b4b4b"/></svg>);
  case 'fb':
    return (<svg id="icon-fb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M483.76 0H28.24A28.23 28.23 0 000 28.24v455.44A28.25 28.25 0 0028.24 512h219.68V340.96H173.52V254.8h74.4v-63.6c0-13.8 1.58-26.42 4.57-37.82.1-.35.22-.66.31-1 .87-3.22 1.85-6.34 2.95-9.36.87-2.37 1.87-4.63 2.88-6.89.42-.94.79-1.93 1.23-2.85 1.46-3.02 3.05-5.93 4.76-8.73l.24-.42a94.52 94.52 0 0112.45-16.06l.32-.35a91.97 91.97 0 016.37-5.85c.49-.42.95-.88 1.45-1.28 1.73-1.42 3.59-2.7 5.43-3.99 1.1-.76 2.15-1.58 3.28-2.3 1.2-.77 2.48-1.42 3.72-2.14 1.92-1.1 3.82-2.25 5.84-3.23.35-.17.73-.3 1.08-.48 15.57-7.35 33.75-11.17 53.92-11.17 31.52 0 58.64 2.4 66.48 3.44v77.04h-45.6c-35.76 0-42.72 17.04-42.72 42v55.04h76.46l8.9.02-11.12 86.14h-74.24V512h146.88A28.25 28.25 0 00512 483.68V28.24A28.23 28.23 0 00483.76 0z" /></svg>);
  case 'ig':
    return (<svg id="icon-ig" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M175.6 409.73h160.8c38.64 0 70.09-31.45 70.09-70.12V172.39c0-38.67-31.45-70.12-70.08-70.12H175.59a70.2 70.2 0 00-70.12 70.12v167.22a70.2 70.2 0 0070.12 70.12zm182.34-277.86a22.2 22.2 0 11-.01 44.36 22.2 22.2 0 01.01-44.36zm-101.96 25.08c54.65 0 99.07 44.43 99.07 99.03 0 54.65-44.42 99.07-99.07 99.07-54.6 0-99.03-44.42-99.03-99.07 0-54.6 44.42-99.03 99.03-99.03z" /><path d="M255.98 324.17c37.6 0 68.2-30.6 68.2-68.19 0-37.59-30.61-68.15-68.2-68.15a68.22 68.22 0 00-68.15 68.15c0 37.59 30.56 68.2 68.15 68.2z" /><path d="M403.29 0H108.72C48.66 0 0 48.66 0 108.72v294.57C0 463.34 48.66 512 108.72 512h294.56C463.34 512 512 463.34 512 403.29V108.72C512 48.66 463.34 0 403.29 0zM66.88 172.39c0-60.05 48.66-108.71 108.71-108.71h160.82c60 0 108.67 48.66 108.67 108.71v167.22c0 60.05-48.66 108.71-108.67 108.71H175.59c-60.05 0-108.71-48.66-108.71-108.71V172.39z" /></svg>);
  default:
    return null;
  }
}

export default Icon;
