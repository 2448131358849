import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import AppContext from 'src/app/context';
import Context from '../context';
// import * as Styles from 'src/css/screens/notice-register.module.scss';

const type = 'period';

function Date(props) {
  const [app] = useContext(AppContext);
  const [, , [, dispatch]] = useContext(Context);
  const {root: key, state} = props;

  const yAttribute = {
    name: 'y',
    type: 'number',
    width: 50,
    state: state.y,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const mAttribute = {
    name: 'm',
    type: 'number',
    width: 50,
    state: state.m,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const dAttribute = {
    name: 'd',
    type: 'number',
    width: 50,
    state: state.d,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  const aboutAttribute = {
    name: 'about',
    state: state.about,
    data: app.data.project.about,
    onChange: (e) => dispatch({type, key, name: e.name, value: e.value}),
  };

  return (
    <ul>
      <li><dfn>{'start' === key ? '開幕' : '閉幕'}</dfn></li>
      <li><dfn>西暦</dfn>&nbsp;&nbsp;<Input.Text {...yAttribute} /><dfn>年</dfn></li>
      <li><Input.Text {...mAttribute} /><dfn>月</dfn></li>
      <li><Input.Text {...dAttribute} /><dfn>日</dfn></li>
      <li><Input.Select {...aboutAttribute} /></li>
    </ul>
  );
}

function Period() {
  const [, , [state, dispatch]] = useContext(Context);
  const {start, end, note, any} = state.period;

  const noteAttribute = {
    name: 'note',
    state: note,
    width: 300,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const anyAttribute = {
    name: 'any',
    data: [0, 1],
    state: any,
    space: true,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <Date root="start" state={start} />
      <Date root="end" state={end} />
      <ul>
        <li><Input.Text {...noteAttribute}>補足</Input.Text></li>
        <li><Input.Checkbox {...anyAttribute}>随時開催可能</Input.Checkbox></li>
      </ul>
    </Section.Register>
  );
}

export default Period;
