import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import Context from '../context';

function Answer() {
  const [, , , , [state, dispatch]] = useContext(Context);

  if (0 === state.questionnaire.data.length) {
    return null;
  }

  const questionnaireAttribute = {
    name: 'questionnaire',
    state: state.questionnaire,
    data: state.questionnaire.data,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <ul>
        <li><Input.Select {...questionnaireAttribute}>アンケート</Input.Select></li>
      </ul>
    </Section.Register>
  );
}

export default Answer;
