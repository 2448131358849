import React, {useState, useEffect, useContext} from 'react';
import AppContext from 'src/app/context';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import * as ScreenStyles from 'src/css/screens/museum-detail.module.scss';
import * as Header from 'src/components/header';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import * as Svg from 'src/components/svg';
import * as Store from './store';

const Context = React.createContext([{}, () => {}]);

function MuseumDetail(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, setState] = useState(null);

  const {params: {_id = null, tab = null}} = props.match || {};

  const init = async (_id) => {
    const {errors, museum} = await Store.findOne(_id);

    if (0 < errors.length) {
      setState(null);
      toast('error');
    } else {
      setState(museum);
    }

    loading(false);
  }

  useEffect(() => {
    init(_id);
    
    return () => {
      loading(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  if (true === app.isLoading || null === state) {
    return null;
  }

  const title = ('admin' === state.role ? state.name : state.original.name.ja);

  return (
    <>
      <Header.Member pathname={props.location.pathname} params={{title, _id}} />
      <Context.Provider value={[props, state, _id, tab]}>
        <Navigation />
        {'admin' === state.role ? (
          <Admin />
        ) : (
          <>
            <Original />
            <Annex />
          </>
        )}
      </Context.Provider>
    </>
  );
}

function Admin() {
  const [, state] = useContext(Context);
  return (
    <section data-type="card">
      <h4>アカウント名</h4>
      <div>{state.name}</div>
      <br />
      <h4>管理者氏名</h4>
      <div>{state.admin.name}</div>
      <br />
      <h4>メールアドレス</h4>
      <div>{state.admin.email}</div>
    </section>
  ); 
}

function Navigation() {
  const [app, loading, toast, modal] = useContext(AppContext);
  const [props, state, _id, tab] = useContext(Context);

  const removeAttribute = {
    type: 'remove',
    onClick: () => {
      modal({
        type: 'remove',
        okHandler: async () => {
          if (true === app.isLoading) {
            return;
          }

          loading(true);

          const {errors} = await Store.remove(_id);
          if (0 === errors.length) {
            toast('remove');
            props.history.push('/museum');
          } else {
            toast('error');
            loading(false);
          }
        },
        cancelHandler: () => {},
      });
    },
  };

  const canEdit = (('admin' === app.role || app._id === state._id) ? true : false);
  const canRemove = ('admin' === app.role ? true : false);
  const basePath = (app.isRoleGuest) ? '/' : `/museum${null !== tab ? `/${tab}` : ''}`;

  return (
    <nav>
      <Input.Button onClick={() => props.history.push(basePath)}>戻る</Input.Button>
      {canEdit && (<Input.Button to={('admin' === app.role ? `${basePath}/${_id}/edit` : '/museum/me/edit')}>編集</Input.Button>)}
      {canRemove && (<Input.Button {...removeAttribute}>削除</Input.Button>)}
    </nav>
  );
}


function Basic(props) {
  const [app] = useContext(AppContext);
  const {state} = props;
  const prefectures = app.data.prefectures;
  
  const ids = prefectures.map((e) => e[1]);
  const index = ids.indexOf(state.prefecture);
  const prefecture = (-1 < index ? prefectures[index] : prefectures[0]);
  
  return (
    <div className={ScreenStyles.basic}>
      <h2>{state.name.ja}</h2>
      <h3>{state.name.en}</h3>
      {99 !== state.prefecture && (
        <div className={ScreenStyles.address}>
          <div>&#12306;{state.zip[0]} - {state.zip[1]}</div>
          <div>{prefecture[0]} {state.address[0]}</div>
          {'' !== state.address[1] && (
            <div>{state.address[1]}</div>
          )}
        </div>
      )}
      <Link state={state.link} />
      <Contact state={state.contact} />
      <Staff state={state.staff} />
    </div>
  );
}

function Link(props) {
  const {state} = props;

  return (
    <div className={ScreenStyles.link}>
      {'' !== state.site && (<a href={state.site} data-icon="home" target="_blank" rel="noopener noreferrer"><Svg.Icon type="home" /></a>)}
      {'' !== state.tw && (<a href={state.tw} data-icon="tw" target="_blank" rel="noopener noreferrer"><Svg.Icon type="tw" /></a>)}
      {'' !== state.fb && (<a href={state.fb} data-icon="fb" target="_blank" rel="noopener noreferrer"><Svg.Icon type="fb" /></a>)}
      {'' !== state.ig && (<a href={state.ig} data-icon="ig" target="_blank" rel="noopener noreferrer"><Svg.Icon type="ig" /></a>)}
    </div>
  );
}

function Contact(props) {
  const {state} = props;
  
  if (0 === state.length) {
    return null;
  }

  const rowJsx = state.map((item, index) => {
    return (
      <tr key={`contact-${index}`}>
        <td>{item.department}</td>
        <td>
          {item.tel[0]}{'' !== item.tel[0] && ('' !== item.tel[1] || '' !== item.tel[2]) && (<>-</>)}
          {item.tel[1]}{'' !== item.tel[1] && '' !== item.tel[2] && (<>-</>)}
          {item.tel[2]}
        </td>
        <td>
          {item.fax[0]}{'' !== item.fax[0] && ('' !== item.fax[1] || '' !== item.fax[2]) && (<>-</>)}
          {item.fax[1]}{'' !== item.fax[1] && '' !== item.fax[2] && (<>-</>)}
          {item.fax[2]}
        </td>
      </tr>
    );
  });

  return (
    <div className={ScreenStyles.contact}>
      <h4>TEL/FAX</h4>
      <table className={ChartStyles.table}>
        <thead>
          <tr>
            <th>部署名</th>
            <th>TEL</th>
            <th>FAX</th>
          </tr>
        </thead>
        <tbody>
          {rowJsx}
        </tbody>
      </table>
    </div>
  );
}

function Staff(props) {
  const {state} = props;
  
  if (0 === state.length) {
    return null;
  }

  const rowJsx = state.map((item, index) => {
    return (
      <tr key={`staff-${index}`}>
        <td>{item.position}</td>
        <td>{item.nameLast} {item.nameFirst}</td>
        <td>{item.nameLastKana} {item.nameFirstKana}</td>
        <td>{item.work}</td>
        <td>{item.field}</td>
      </tr>
    );
  });

  return (
    <div className={ScreenStyles.staff}>
      <h4>職員名簿</h4>
      <table className={ChartStyles.table}>
        <thead>
          <tr>
            <th>役職</th>
            <th>氏名</th>
            <th>ふりがな</th>
            <th>担当業務</th>
            <th>専門研究分野</th>
          </tr>
        </thead>
        <tbody>
          {rowJsx}
        </tbody>
      </table>
    </div>
  );
}

function Original() {
  const [, state] = useContext(Context);

  return (
    <section data-type="card">
      <Basic state={state.original} />
    </section>
  );
}

function Annex() {
  const [, state] = useContext(Context);
  
  if (0 === state.annex.length) {
    return null;
  }

  const tab = [];
  const bodyJsx = [];
  state.annex.forEach((item, index) => {
    tab.push(`分館（${index + 1}）`);
    bodyJsx.push(<Basic state={item} key={`annex${index}`} />);
  });

  return (
    <Section.Tab tab={tab}>{bodyJsx}</Section.Tab>
  );
}

export default MuseumDetail;
