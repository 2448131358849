import cloneDeep from 'lodash.clonedeep';
import React, {useState, useEffect, useContext} from 'react';
import download from 'src/utils/download';
import * as ChartStyles from 'src/css/screens/chart.module.scss';
import * as ScreenStyles from 'src/css/screens/museum-chart.module.scss';
import AppContext from 'src/app/context';
import * as Dnd from 'src/components/dnd';
import * as Anchor from 'src/components/anchor';
import * as Section from 'src/components/section';
import * as Input from 'src/components/input';
import * as Svg from 'src/components/svg';
import * as Header from 'src/components/header';
import * as Store from './store';

const Context = React.createContext([{}, () => {}]);
const tabElementId = 'museum-tab';

function MuseumChart(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, setState] = useState(null);
  const {params: {tab = 'member'}} = props.match;

  const init = async () => {
    const {errors, museums} = await Store.findAll(tab);

    if (0 < errors.length) {
      setState([]);
      toast('error');
    } else {
      setState('admin' === tab ? museums : sortToArea(app, museums));
    }

    loading(false);
  }

  useEffect(() => {
    init();

    return () => {
      loading(true);
      setState(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  if (true === app.isLoading || null === state) {
    return null;
  }
      
  return (
    <Context.Provider value={[state, tab, setState, props.history]}>
      <Header.Member pathname={props.location.pathname} />
      {'admin' === app.role ? (
        <AdminChart />
      ) : (
        <MemberChart />
      )}
    </Context.Provider>
  );
}

function sortToArea(app, data) {
  const areas = app.data.areas.map((item) => {
    const [name, id] = item;
    return {name, id, museums: []};
  });

  for (const item of data) {
    const {area} = item.original;

    for (const _area of areas) {
      if (area === _area.id) {
        _area.museums.push(item);
        break;
      }
    }
  }

  return areas;
}


function Row(props) {
  const {_id, indent, original: {name, link, contact}} = props.item;
  const tab = props.tab;
  const to = `/museum${null !== tab && 'member' !== tab ? `/${tab}` : ''}/${_id}`;

  return (
    <>
      <span className={ScreenStyles.name} data-indent={indent}><Input.Link to={to}>{name.ja}</Input.Link></span>
      <span className={ScreenStyles.link}>
        {'' !== link.site && (<a href={link.site} data-icon="home" target="_blank" rel="noopener noreferrer"><Svg.Icon type="home" /></a>)}
        {'' !== link.tw && (<a href={link.tw} data-icon="tw" target="_blank" rel="noopener noreferrer"><Svg.Icon type="tw" /></a>)}
        {'' !== link.fb && (<a href={link.fb} data-icon="fb" target="_blank" rel="noopener noreferrer"><Svg.Icon type="fb" /></a>)}
        {'' !== link.ig && (<a href={link.ig} data-icon="ig" target="_blank" rel="noopener noreferrer"><Svg.Icon type="ig" /></a>)}
      </span>
      <span className={ScreenStyles.tel}>
        {0 < contact.length && (
          <>
            {contact[0].tel[0]}{'' !== contact[0].tel[0] && ('' !== contact[0].tel[1] || contact[0].tel[2]) && (<>-</>)}
            {contact[0].tel[1]}{'' !== contact[0].tel[1] && '' !== contact[0].tel[2] && (<>-</>)}
            {contact[0].tel[2]}
          </>
        )}
      </span>
      <span className={ScreenStyles.fax}>
        {0 < contact.length && (
          <>
            {contact[0].fax[0]}{'' !== contact[0].fax[0] && ('' !== contact[0].fax[1] || '' !== contact[0].fax[2]) && (<>-</>)}
            {contact[0].fax[1]}{'' !== contact[0].fax[1] && '' !== contact[0].fax[2] && (<>-</>)}
            {contact[0].fax[2]}
          </>
        )}
      </span>
    </>
  );
}

function AdminChart() {
  const [app] = useContext(AppContext);
  const [, tab, , history] = useContext(Context);

  const tabAttribute = {
    id: tabElementId,
    to: [
      ['加盟館', '/museum'],
      ['非加盟館', '/museum/guest'],
      ['事務局', '/museum/admin'],
      ['承認依頼', '/museum/draft'],
    ],
    active: history.location.pathname,
    padding: false,
  };


  const canDownload = ('member' === tab || 'guest' === tab) ? true : false;
  const downloadAttribute = {
    type: 'download',
    onClick: () => {
      download(('member' === tab) ? 'museum-member' : 'museum-guest');
    },
    children: ('member' === tab) ? '加盟館情報ダウンロード' : '非加盟館情報ダウンロード',
  };

  return (
    <>
      <nav>
        {canDownload && (<Input.Button {...downloadAttribute} />)}
        <Input.Button to="/museum/register">新規登録</Input.Button>
      </nav>
      <Section.TabLink {...tabAttribute}>
        {'admin' !== tab && (
          <Anchor.Nav data={app.data.areas.map(e => [e[0], `area-${e[1]}`])} />
        )}
      </Section.TabLink>
      {'admin' !== tab ? (
        <MuseumDndBlock />
      ) : (
        <AdminBlock />
      )}
    </>
  );
}


function MuseumDndBlock() {
  const [app, loading, toast] = useContext(AppContext);
  const [state, tab, setState] = useContext(Context);

  if (0 < state.length && 'undefined' === typeof state[0].museums) {
    return null;
  }

  return state.map((area, index) => {
    const {name, id, museums} = area;
    const rows = museums.map((item, index) => {
      return (<Row key={`row${index}`} item={item} tab={tab} />);
    });

    const canDnd = ('draft' === tab ? false : true);

    const saveAttribute = {
      onClick: async () => {
        if (true === app.isLoading) {
          return;
        }

        loading(true);
        const {errors} = await Store.edit(museums);
        if (0 === errors.length) {
          toast('save');
        } else {
          toast('error');
        }
        
        loading(false);
      },
    };

    const dndState = {
      prefix: `area-${index}-dnd`,
      add: false,
      remove: false,
      state: museums,
      callback: (e) => {
        const _state = cloneDeep(state);
        _state[index].museums = e;
        setState(_state);
      },
      dnd: canDnd,
      head: [
        ['美術館名', ScreenStyles.name],
        ['ウェブサイト/SNS', ScreenStyles.link],
        ['TEL', ScreenStyles.tel],
        ['FAX', ScreenStyles.fax],
      ],
    };

    return (
      <section data-type="card" key={`area-${index}`} id={`area-${id}`}>
        <ul className={ScreenStyles.areaTitle}>
          <li><h3>{name}</h3></li>
          <li>{canDnd && (<Input.Button {...saveAttribute}>並び順を登録</Input.Button>)}</li>
        </ul>
        <Dnd.Ul {...dndState}>{rows}</Dnd.Ul>
        <Anchor.Back to={tabElementId}>TOPへ戻る</Anchor.Back>
      </section>
    );
  });
}

function AdminBlock() {
  const [state] = useContext(Context);

  if (0 < state.length && 'undefined' !== typeof state[0].museums) {
    return null;
  }

  const itemJsx = state.map((item, index) => {
    const {_id, id, name, admin} = item;
    return (
      <tr key={`row-${index}`}>
        <td><Input.Link to={`/museum/admin/${_id}`}>{id}</Input.Link></td>
        <td><Input.Link to={`/museum/admin/${_id}`}>{name}</Input.Link></td>
        <td>{admin.name || ''}</td>
      </tr>
    );
  });

  return (
    <section data-type="card">
      <h3>管理者</h3>
      <table className={`${ChartStyles.table} ${ScreenStyles.table}`}>
        <thead>
          <tr>
            <th>ID</th>
            <th>アカウント名</th>
            <th>管理者氏名</th>
          </tr>
        </thead>
        <tbody>
          {itemJsx}
        </tbody>
      </table>
    </section>
  );
}




function MemberChart() {
  const [app] = useContext(AppContext);
  const [state] = useContext(Context);

  const block = state.map((area, index) => {
    const {name, id, museums} = area;
    const rows = museums.map((item, j) => {
      return (<li key={`area-${index}-row-${j}`}><Row item={item} tab={null} /></li>);
    });

    return (
      <section data-type="card" key={`area-${index}`} id={`area-${id}`}>
        <h3>{name}</h3>
        <ul className={ChartStyles.head}>
          <li className={ScreenStyles.name}>美術館名</li>
          <li className={ScreenStyles.link}>ウェブサイト/SNS</li>
          <li className={ScreenStyles.tel}>TEL</li>
          <li className={ScreenStyles.fax}>FAX</li>
        </ul>
        <ul className={ChartStyles.ul}>{rows}</ul>
        <Anchor.Back>TOPへ戻る</Anchor.Back>
      </section>
    );
  });

  return (
    <>
      <section data-type="card" data-padding="false">
        <Anchor.Nav data={(app.data.areas.slice(0, -1)).map(e => [e[0], `area-${e[1]}`])} />
      </section>
      {block}
    </>
  );
}

export default MuseumChart;
