import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import * as storage from 'src/utils/storage';

/**
 * initial state
 */
export const initialState = {
  data: {
    areas: [],
    indents: [],
    prefectures: [],
    roles: [],
    states: [],
    targets: [],
    project: {
      about: [],
      type: [],
    },
    join: {
      state: [],
    },
    questionnaire: {
      type: [],
    },
  },
  isSignin: false,
  isLoading: false,
  isRoleAdmin: false,
  isRoleMember: false,
  isRoleGuest: false,
  modal: null,
  toast: null,
  label: {
    states: {},
    targets: {},
    project: {
      states: {},
    },
  },
  _id: null,
  name: null,
  role: null,
  museumName: null,
};

const roles = [
  ['管理者', 'admin'],
  ['加盟館', 'member'],
  ['非加盟館', 'guest'],
];

const targets = [
  ['全て', 'all'],
  ['加盟館', 'member'],
  ['非加盟館', 'guest'],
];

const states = [
  ['公開', 'public'],
  ['非公開', 'private'],
  ['差戻し', 'remand'],
  ['承認待ち', 'draft'],
  ['取下げ', 'cancel'],
];

const indents = [
  ['本館表示', 0],
  ['分館表示', 1],
];

const project = {
  states: [
    ['公開', 'public'],
    ['非公開', 'private'],
    ['差戻し', 'remand'],
    ['承認待ち', 'draft'],
    ['取下げ', 'cancel'],
    ['一時保存', 'tmp'],
  ],
  about: [
    ['必要に応じて選択', ''],
    ['上旬', '上旬'],
    ['中旬', '中旬'],
    ['下旬', '下旬'],
  ],
  type: [
    ['新規提案企画', 'new'],
    ['開催館募集企画', 'recruit'],
    ['読売新聞社提案企画', 'yomiuri'],
    ['随時開催可能企画', 'any'],
  ],
};

const join = {
  state: [
    ['開催', 'hold'],
    ['開催申請', 'hold-apply'],
    // ['開催差戻し', 'hold-remand'],
    ['検討', 'consider'],
    // ['検討申請', 'consider-apply'],
    // ['検討差戻し', 'consider-remand'],
    ['取下げ', 'cancel'],
  ],
};

const questionnaire = {
  type: [
    ['回答', 'text'],
    ['企画', 'project']
  ],
};

const areas = [
  ['北海道', 1, [1]],
  ['東北', 2, [2, 3, 4, 5, 6, 7]],
  ['関東', 3, [8, 9, 10, 11, 12, 13, 14]],
  ['北陸', 4, [15, 16, 17, 18]],
  ['中部', 5, [19, 20, 21, 22, 23]],
  ['近畿', 6, [24, 25, 26, 27, 28, 29, 30]],
  ['中国', 7, [31, 32, 33, 34, 35]],
  ['四国', 8, [36, 37, 38, 39]],
  ['九州・沖縄', 9, [40, 41, 42, 43, 44, 45, 46, 47]],
  ['読売関連', 10, [99]],
];

const prefectures = [
  ['北海道', 1],
  ['青森県', 2],
  ['岩手県', 3],
  ['宮城県', 4],
  ['秋田県', 5],
  ['山形県', 6],
  ['福島県', 7],
  ['茨城県', 8],
  ['栃木県', 9],
  ['群馬県', 10],
  ['埼玉県', 11],
  ['千葉県', 12],
  ['東京都', 13],
  ['神奈川県', 14],
  ['新潟県', 15],
  ['富山県', 16],
  ['石川県', 17],
  ['福井県', 18],
  ['山梨県', 19],
  ['長野県', 20],
  ['岐阜県', 21],
  ['静岡県', 22],
  ['愛知県', 23],
  ['三重県', 24],
  ['滋賀県', 25],
  ['京都府', 26],
  ['大阪府', 27],
  ['兵庫県', 28],
  ['奈良県', 29],
  ['和歌山県', 30],
  ['鳥取県', 31],
  ['島根県', 32],
  ['岡山県', 33],
  ['広島県', 34],
  ['山口県', 35],
  ['徳島県', 36],
  ['香川県', 37],
  ['愛媛県', 38],
  ['高知県', 39],
  ['福岡県', 40],
  ['佐賀県', 41],
  ['長崎県', 42],
  ['熊本県', 43],
  ['大分県', 44],
  ['宮崎県', 45],
  ['鹿児島県', 46],
  ['沖縄県', 47],
  ['読売関連', 99],
];

















/**
 * update state
 * @param {initialState} state
 * @param {object} action
 * @return {initialState}
 */
export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return reducerTypeInitialize(_state, action);
  case 'loader':
    return reducerTypeLoader(_state, action);
  case 'toast':
    return reducerTypeToast(_state, action);
  case 'modal':
    return reducerTypeModal(_state, action);
  default:
    return _state;
  }
}



function reducerTypeInitialize(state, action) {
  const {me: {_id, name, role, original}} = action.value;
  
  state.data.areas = areas;
  state.data.indents = indents;
  state.data.prefectures = prefectures;
  state.data.roles = roles;
  state.data.states = states;
  state.data.targets = targets;
  
  state.data.project = project;
  state.data.join = join;
  state.data.questionnaire = questionnaire;

  state.isLoading = true;
  state.isSignin = true;
  state._id = _id;
  state.name = name;
  state.role = role;
  
  if (original) {
    state.museumName = original.name.ja;
  }

  switch (role) {
  case 'admin':
    state.isRoleAdmin = true; 
    break;
  case 'member':
    state.isRoleMember = true; 
    break;
  default:
    state.isRoleGuest = true;
    break;
  }

  for (const [name, value] of states) {
    state.label.states[value] = name;
  }

  for (const [name, value] of targets) {
    state.label.targets[value] = name;
  }

  for (const [name, value] of project.states) {
    state.label.project.states[value] = name;
  }

  return state;
}

function reducerTypeLoader(state, action) {
  state.isLoading = action.value;
  return state;
}

function reducerTypeToast(state, action) {
  state.toast = action.value;
  return state;
}

function reducerTypeModal(state, action) {
  state.modal = action.value;
  return state;
}

/**
 * init app
 * @return {boolean} 
 */
export async function init() {
  const query = `{
    me {
      _id
      name
      role
      original {
        name {
          ja
        }
      }
    }
  }`;

  const {errors, data} = await gql.query(query);

  if (0 < errors.length || null === data.me) {
    storage.removeItems(['token', 'exp']);
    errors.push(['init error']); // meが取得できなかった場合もエラーにしたいので
  }

  return {errors, data};
}
