import React, {useEffect, useContext} from 'react';
import * as Styles from 'src/css/components/toast.module.scss';
import AppContext from 'src/app/context';
import anime from 'animejs/lib/anime.es.js';

function Toast() {
  const [app, , toast] = useContext(AppContext);
  const ref = React.createRef();

  useEffect(() => {
    if (null === app.toast) {
      return;
    }

    const timeline = anime.timeline({
      targets: ref.current,
      easing: 'easeInOutQuad',
    });
      
    timeline
    .add({translateY: {value: [-100, 0], duration: 500}})
    .add({translateY: {value: [0, -100], duration: 300}, complete: () => {
      toast(null);
    }}, 2000);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.toast]);
 
  let message = '';
  switch(app.toast) {
  case 'save':
    message = '保存しました';
    break;
  case 'remove':
    message = '削除しました';
    break;
  case 'warning':
    message = '入力に誤りがあります';
    break;
  case 'send':
    message = '送信しました';
    break;
  case 'error':
    message = 'システムエラーが発生しました';
    break;
  default:
    message = app.toast;
    break;
  }

  return (
    <div className={Styles.base}>
      <p ref={ref} data-type={`${app.toast || 'null'}`}>{message}</p>
    </div>
  );
}

export default Toast;
