import * as gql from 'src/utils/gql';

export async function find(_id) {
  const query = `{
    notice(_id: "${_id}") {
      state
      title
      type
      body
      publishedAt
      file
      contact
      registedBy {
        _id
        original {
          name {
            ja
          }
        }
      }
    }
  }`;

  const {errors, data: {notice = {}}} = await gql.query(query);
  return {errors, notice};
}

export async function remove(_id) {
  const query = `removeNotice($_id: ID!) {
    removeNotice(_id: $_id)
  }`;
  
  const {errors} = await gql.mutation(query, {_id});
  return {errors};
}
