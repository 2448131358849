import React, {useState, useEffect, useContext} from 'react';
import moment from 'moment';
import AppContext from 'src/app/context';
import * as Header from 'src/components/header';
import * as Input from 'src/components/input';
import * as store from './store';
import Context from './context';
import Admin from './admin';
import Member from './member';

const limit = moment(new Date()).subtract(1, 'month');

function NoticeChart(props) {
  const [app, loading, toast] = useContext(AppContext);
  const [state, setState] = useState(null);
  const {url, params: {tab = 'default'}} = props.match;

  const init = async () => {
    const {errors, notices} = await store.findAll((app.isRoleAdmin && 'draft' === tab) ? 'draft-only' : tab);

    if (0 < errors.length) {
      setState([]);
      toast('error');
    } else {
      setState(notices);
    }

    loading(false);
  }

  useEffect(() => {
    init();
    
    return () => {
      loading(true);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  
  if (true === app.isLoading || null === state) {
    return null;
  }

  return (
    <Context.Provider value={[state, url, tab, limit]}>
      <Header.Member pathname={props.location.pathname} />
      <nav><Input.Button to={`/notice/register`}>新規登録</Input.Button></nav>
      {'admin' === app.role ? (
        <Admin />
      ) : (
        <Member />
      )}
    </Context.Provider>
  );
}

export default NoticeChart;
