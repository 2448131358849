import * as gql from 'src/utils/gql';

export async function findMember() {
  const query = `{
    notices(view: "remand") {
      _id
    }
    projects(action: "remand") {
      _id
    }
    noticeTop {
      admin {
        _id
        title
        type
        publishedAt
        registedBy {
          role
        }
      }
      member {
        _id
        title
        type
        publishedAt
        registedBy {
          role
        }
      }
    }
  }`;

  const {errors, data: {noticeTop = [], notices = [], projects = []}} = await gql.query(query);
  return {
    errors,
    notice: noticeTop,
    count: {
      remand: {
        notices: notices.length,
        projects: projects.length,
      },
    },
  };
}


export async function findAdmin() {
  const result = {count: {draft: {}, join: {}}};

  {
    const query1 = `{
      museums(target: "draft") {
        _id
      }
      notices(view: "draft-only") {
        _id
      }
      projects(action: "draft-only") {
        _id
      }
    }`;
    const {errors, data: {museums = [], notices = [], projects = []}} = await gql.query(query1);
    if (0 < errors.length) {
      return {errors};
    }

    result.count.draft.museums = museums.length;
    result.count.draft.notices = notices.length;
    result.count.draft.projects = projects.length;
  }

  {
    const query2 = `{
      projects(action: "join-only") {
        _id
      }
      noticeTop {
        admin {
          _id
          title
          type
          publishedAt
          registedBy {
            role
          }
        }
        member {
          _id
          title
          type
          publishedAt
          registedBy {
            role
          }
        }
      }
    }`;
    const {errors, data: {noticeTop = [], projects = []}} = await gql.query(query2);
    if (0 < errors.length) {
      return {errors};
    }

    result.count.join.projects = projects.length;
    result.notice = noticeTop;
  }

  return {errors: [], ...result};
}




