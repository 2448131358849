import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import Context from './context';

const type = 'admin';

function Admin() {
  const [data, dispatch] = useContext(Context);
  const state = data.admin;

  const nameAttribute = {
    name: 'name',
    state: state.name,
    required: true,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const nameKanaAttribute = {
    name: 'nameKana',
    state: state.nameKana,
    required: true,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  const emailAttribute = {
    name: 'email',
    state: state.email,
    width: 300,
    required: true,
    onChange: (e) => dispatch({type: type, name: e.name, value: e.value}),
  };

  return (
    <ul>
      <li><Input.Text {...nameAttribute}>管理者氏名</Input.Text></li>
      <li><Input.Text {...nameKanaAttribute}>ふりがな</Input.Text></li>
      <li><Input.Text {...emailAttribute}>メールアドレス</Input.Text></li>
    </ul>
  );
}

export default Admin;
