import React, {useContext} from 'react';
import moment from 'moment';
import * as Common from 'src/utils/common';
import * as DetailStyles from 'src/css/screens/detail.module.scss';
import * as ScreenStyles from 'src/css/screens/questionnaire-detail.module.scss';
import Context from './context';

function Main() {
  const [, , state] = useContext(Context);
  const item = state.questionnaire;

  const fileJsx = (item.file.filter(e => '' !== e)).map((src, index) => {
    if ('' !== src) {
      const {name, type} = Common.convertFilePathToInfo(src);
      const attribute = {
        'data-icon': type,
        target: '_blank',
        href: src,
      };
      return (<li key={`file${index}`}><a {...attribute}>{name}</a></li>);
    } else {
      return null;
    }
  });

  return (
    <section data-type="card" className={`${DetailStyles.main} ${ScreenStyles.main}`}>
      <div className={ScreenStyles.date}>
        回答期限&nbsp;:&nbsp;
        {moment(item.startAt).format('YYYY.MM.DD')}
        &nbsp;~&nbsp;
        {moment(item.endAt).format('YYYY.MM.DD')}
      </div>
      <h2>{item.title}</h2>
      <div dangerouslySetInnerHTML={{__html : item.body}} />
      {0 < fileJsx.length && (
        <div className={DetailStyles.file}>
          <h3>ファイル</h3>
          <ul>{fileJsx}</ul>
        </div>
      )}
    </section>
  );
}

export default Main;
