import React, {useContext} from 'react';
import * as Input from 'src/components/input';
import * as Section from 'src/components/section';
import AppContext from 'src/app/context';
import Context from './context';
import * as Styles from 'src/css/screens/questionnaire-register.module.scss';

function Main() {
  const [app] = useContext(AppContext);
  const [state, dispatch] = useContext(Context);
  
  const titleAttribute = {
    name: 'title',
    required: true,
    width: 600,
    state: state.title,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const bodyAttribute = {
    name: 'body',
    required: true,
    state: state.body,
    title: state.title.value,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const targetAttribute = {
    name: 'target',
    data: app.data.targets,
    state: state.target,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const stateAttribute = {
    name: 'state',
    data: app.data.states,
    state: state.state,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const typeAttribute = {
    name: 'type',
    data: app.data.questionnaire.type,
    state: state.type,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };
  
  const startAttribute = {
    className: Styles.date,
    name: 'startAt',
    time: true,
    required: true,
    state: state.startAt,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  const endAttribute = {
    className: Styles.date,
    name: 'endAt',
    time: true,
    required: true,
    state: state.endAt,
    onChange: (e) => dispatch({name: e.name, value: e.value}),
  };

  return (
    <Section.Register>
      <ul>
        <li><Input.Select {...targetAttribute}>対象</Input.Select></li>
        <li><Input.Select {...typeAttribute}>カテゴリ</Input.Select></li>
        <li><Input.Select {...stateAttribute}>公開状態</Input.Select></li>
        <li>
          <Input.Date {...startAttribute}>開始日</Input.Date>
          <i />
          <Input.Date {...endAttribute}>終了日</Input.Date>
        </li>
      </ul>
      <div><Input.Text {...titleAttribute}>タイトル</Input.Text></div>
      <div><Input.Editor {...bodyAttribute}>本文</Input.Editor></div>
    </Section.Register>
  );
}

export default Main;
