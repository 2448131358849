import React from 'react';
import * as Common from 'src/utils/common';
import * as Styles from 'src/css/components/input.module.scss';

function Textarea(props) {
  const {
    name = '',
    children = null,
    required = false,
    readOnly = false,
    rows = 4,
    cols = 40,
    state: {
      value = '',
      error = null,
      change = false,
    },
    onChange = () => {},
  } = props;

  const isError = (null !== error ? 'true' : 'false');
  const isChange = (true === change ? 'true' : 'false');
  const isRequired = (true === required && false === readOnly ? 'true' : 'false');

  return (
    <div className={Styles.textarea} data-component="input" data-input-error={isError}>
      {null !== children && (
        <label data-required={isRequired}>
          {children}
        </label>
      )}
      {false === readOnly ? (
        <>
          <textarea name={name} value={value} rows={rows} cols={cols} data-change={isChange} onChange={(e) => {
            onChange({name: e.target.name, value: e.target.value});
          }} />
          <small>{error}</small>
        </>
      ) : (
        <div　styel={{maxWidth: '100%'}}>{Common.convertFeedCodeToJsx(value, name)}</div>
      )}
    </div>
  );
}

export default Textarea;
