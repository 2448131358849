import React from 'react';
import Base from './base';
import * as Styles from 'src/css/components/dnd.module.scss';

const dnd = new Base(['ul', 'li']);

export default function Ul(props) {
  const {
    head = [],
    prefix = 'dnd-li',
    children = [],
    state,
    initialState = null,
    callback,
    dnd: action = true,
    add = true,
    remove = true,
    lineAttribute = null,
  } = props;
  
  const headRows = head.map((item, index) => {
    const [name, className] = item;
    return (<li key={`${prefix}-head-${index}`} className={className}>{name}</li>);
  });

  const rowAttributes = {
    onDragStart: dnd.dragstart,
    onDragLeave: dnd.dragleave,
    onDragOver: dnd.dragover,
    onDragEnd: dnd.dragend,
    onDrop: (e) => {
      callback(dnd.drop(e, state));
      return false;
    },
  };

  const isAction = (action ? 'true' : 'false');

  const dndRows = children.map((item, index) => {
    if (null !== lineAttribute) {
      const keys = Object.keys(lineAttribute);
      for (const key of keys) {
        rowAttributes[`data-${key}`] = lineAttribute[key][index] || null;
      }
    }

    return (
      <li key={`${prefix}-${index}`} data-dnd="row" data-index={index} {...rowAttributes}>
        <div className={Styles.wrap}>
          {/* <span className={Styles.thumb} data-action={isAction}>
            <span onMouseDown={dnd.ready} />
          </span> */}
          {item}
          {(true === add || true === remove) && (
            <span className={Styles.button}>
              {true === add && (<button data-type="+" onClick={() => callback(dnd.addRow(state, index, initialState))} />)}
              {true === remove && (<button data-type="-" onClick={() => callback(dnd.deleteRow(state, index))} />)}
            </span>
          )}
        </div>
      </li>
    );
  });


  return (
    <>
      {0 < headRows.length && (
        <ul className={Styles.head}>
          {/* <li data-action={isAction} /> */}
          {headRows}
          {true === add && (<li />)}
          {true === remove && (<li />)}
        </ul>
      )}
      <ul className={Styles.frame} data-dnd="frame">{dndRows}</ul>
    </>
  );
}
