import React, {useContext, useRef} from 'react';
import * as Styles from 'src/css/components/modal.module.scss';
import AppContext from 'src/app/context';
import * as Input from 'src/components/input';

function Modal() {
  const [app, , , modal] = useContext(AppContext);
  const state = app.modal;

  const {
    type = 'default',
    okHandler = null,
    cancelHandler = null,
    closeHandler = null,
    isOpen = true,
    message = '',
    contents = '',
    jsx = null,
  } = state || {isOpen: false};

  const close = () => {
    if ('function' === typeof closeHandler) {
      closeHandler();
    }

    modal(null);
  };

  let body = '';
  switch(type) {
  case 'image':
    body = (<img src={state.src} alt="" />);
    break;
  case 'image-file':
    body = (<ImageFile state={state} />);
    break;
  case 'remove':
    body = '削除しますか？';
    break;
  case 'system':
    body = 'システムエラーが発生しました';
    break;
  case 'preview':
    if (jsx) {
      body = jsx;
    } else {
      body = (<span dangerouslySetInnerHTML={{__html : contents}} />);
    }
    break;
  default:
    body = (<span dangerouslySetInnerHTML={{__html : message}} />);
    break;
  }

  const isModal = (true === isOpen ? 'true' : 'false');
  

  return (
    <div className={Styles.base} data-type={type} data-modal={isModal} onClick={close}>
      <div className={Styles.wrap}>
        <div><span className={Styles.close} onClick={close} /></div>
        <ul onClick={e => e.stopPropagation()}>
          <li>{body}</li>
          <li>
            {'function' === typeof okHandler && (
              <Input.Button onClick={() => {
                okHandler();
                close();
              }}>はい</Input.Button>
            )}
            {'function' === typeof cancelHandler && (
              <Input.Button onClick={() => {
                cancelHandler();
                close();
              }}>いいえ</Input.Button>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

function ImageFile(props) {
  const ref = useRef();
  const {file} = props.state;

  const reader = new FileReader();
  reader.onload = ((e) => {
    ref.current.src = e.target.result;
  });
  reader.readAsDataURL(file);
 
  return (<img ref={ref} alt="" />);
}

export default Modal;
