import * as gql from 'src/utils/gql';

export async function findAll(tab) {
  const query = `{
    notices(view: "${tab}") {
      _id
      state
      title
      type
      target
      publishedAt
      registedBy {
        role
      }
    }
  }`;

  const {errors, data: {notices = []}} = await gql.query(query);
  return {errors, notices};
}
