import React, {useContext} from 'react';
import moment from 'moment';
import * as Common from 'src/utils/common';
import Badge from 'src/components/badge';
import * as Styles from 'src/css/screens/detail.module.scss';
import * as ScreenStyles from 'src/css/screens/project-detail.module.scss';
import Context from './context';

function Main() {
  const [state] = useContext(Context);
  const project = state.project;
  const join = state.join;

  const file = project.file.filter(e => '' !== e.src);
  const fileJsx = file.map((item, index) => {
    return (
      <li key={`file${index}`}>
        <div style={{backgroundImage: `url(${encodeURI(item.src)})`}} />
        <p>{Common.convertFeedCodeToJsx(item.caption)}</p>
      </li>
    );
  }); 
  
  const period = Common.convertProjectPeriodToText(project.period);

  const member = join.member.filter((item) => {
    switch (item.state) {
    case 'hold':
    case 'consider':
      return true;
    default:
      return false;
    }
  });

  const memberJsx = member.map((item, index) => {
    const {name, museum = null, period, state, new: newBadge} = item;
    const isBadge = (1 === newBadge) ? true : false;

    return (
      <tr key={`member-${index}`}>
        <td>
          {isBadge && (<span className="printOff"><Badge type="new" /></span>)}
          {!museum ? name : museum.original.name.ja}
          {'consider' === state && (<>（予定）</>)}
        </td>
        <td>{Common.convertProjectPeriodToText(period)}</td>
      </tr>
    );
  });

  let subFileJsx = null;
  if ('' !== project.subFile) {
    const src = project.subFile;
    const {name, type} = Common.convertFilePathToInfo(src);

    const attribute = {
      'data-icon': type,
      target: '_blank',
      href: src,
    };
    subFileJsx = (<li><a {...attribute}>{name}</a></li>);
  }

  let proposer = '';
  const {role = null, original = null} = project.registedBy || {};
  if ('admin' === role || !original) {
    proposer = project.proposer;
  } else {
    proposer = original.name.ja;
  }

  return (
    <section data-type="card" className={ScreenStyles.main} data-print="project-detail">
      <h2>{project.title}</h2>
      <h3>{project.subTitle}</h3>
      <h4>展覧会概要</h4>
      <div className={ScreenStyles.body} data-file={fileJsx.length}>
        <div>{Common.convertFeedCodeToJsx(project.body)}</div>
        <ul>{fileJsx}</ul>
      </div>
      <h4>開催可能時期</h4>
      <div>{period}</div>
      <h4>開催検討館/会期（予定）</h4>
      <table className={ScreenStyles.member}><tbody>{memberJsx}</tbody></table>
      <h4>備考</h4>
      <div>{Common.convertFeedCodeToJsx(project.note)}</div>
      {subFileJsx && (
        <div className={Styles.file} data-print="off">
          <h4>資料</h4>
          <ul>{subFileJsx}</ul>
        </div>
      )}
      <h4>提案館</h4>
      <div>{proposer}</div>
      {'' !== project.contact && (
        <>
          <h4>連絡先</h4>
          <div>{Common.convertFeedCodeToJsx(project.contact)}</div>
        </>
      )}
      <ul className={ScreenStyles.at}>
        <li>{moment(project.publishedAt).format('YYYY.MM')} 提案</li>
        <li>{moment(project.updatedAt).format('YYYY.MM')} 更新</li>
      </ul>
    </section>
  );
}

export default Main;
