import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

const initialBasicState = {
  name: {
    ja: {value: '', error: null, change: false},
    en: {value: '', error: null, change: false},
  },
  link: {
    site: {value: '', error: null, change: false},
    tw: {value: '', error: null, change: false},
    fb: {value: '', error: null, change: false},
    ig: {value: '', error: null, change: false},
  },
  zip: [
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
  ],
  area: {value: 1, error: null, change: false},
  prefecture: {value: 1, error: null, change: false},
  address: [
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
  ],
  contact: [],
  staff: [],
};

const initialNotificationState = {
  name: {value: '', error: null, change: false},
  email: {value: '', error: null, change: false},
};

const initialAdminState = {
  name: {value: '', error: null, change: false},
  nameKana: {value: '', error: null, change: false},
  email: {value: '', error: null, change: false},
};

const initialState = {
  role: {value: 'member', error: null, change: false},
  indent: {value: 0, error: null, change: false},
  name: {value: '', error: null, change: false},
  id: {value: '', error: null, change: false},
  password: {value: '', error: null, change: false, confirm: ''},
  admin: cloneDeep(initialAdminState),
  original: cloneDeep(initialBasicState),
  annex: [],
  notification: [cloneDeep(initialNotificationState)],
  note: {value: '', error: null, change: false},
};

const initialContactState = {
  department: {value: '', error: null, change: false},
  tel: [
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
  ],
  fax: [
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
    {value: '', error: null, change: false},
  ],
};

const initialStaffState = {
  position: {value: '', error: null, change: false},
  nameFirst: {value: '', error: null, change: false},
  nameLast: {value: '', error: null, change: false},
  nameFirstKana: {value: '', error: null, change: false},
  nameLastKana: {value: '', error: null, change: false},
  work: {value: '', error: null, change: false},
  field: {value: '', error: null, change: false},
};

export {
  initialContactState,
  initialStaffState,
  initialNotificationState,
};

/**
 * update state
 * @param {initialState} state
 * @param {object} action
 * @return {initialState}
 */
export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return action.initialState || cloneDeep(initialState);
  case 'notification':
    return reducerTypeNotification(_state, action);
  case 'original':
    return reducerTypeOriginal(_state, action);
  case 'annex':
    return reducerTypeAnnex(_state, action);
  case 'admin':
    return reducerTypeAdmin(_state, action);
  case 'user':
    return reducerTypeUser(_state, action);
  default:
    return reducerTypeCustom(_state, action);
  }
}

function reducerTypeBasic(_state, action) {
  const {behavior = null, keys = [], name, value} = action;

  if (null === behavior) {
    switch (keys.length) {
    case 1:
      _state[keys[0]][name].value = value;
      _state[keys[0]][name].error = checkTypeBasic(keys, name, value);
      break;
    case 2:
      _state[keys[0]][keys[1]][name].value = value;
      _state[keys[0]][keys[1]][name].error = checkTypeBasic(keys, name, value);
      break;
    case 3:
      _state[keys[0]][keys[1]][keys[2]][name].value = value;
      _state[keys[0]][keys[1]][keys[2]][name].error = checkTypeBasic(keys, name, value);
      break;
    default:
      _state[name].value = value;

      if ('prefecture' === name && action.area) {
        const {area = null} = action;
        _state.area.value = area;
      }
      break;
    } 
  } else {
    switch (behavior) {
    case 'add':
      switch (name) {
      case 'contact':
        _state[name].push(cloneDeep(initialContactState));
        break;
      case 'staff':
        _state[name].push(cloneDeep(initialStaffState));
        break;
      default:
        break;
      }
      break;
    case 'remove':
      const {index} = action;
      _state[name].splice(index, 1);
      break;
    case 'dnd':
      _state[name] = value;
      break;
    default:
      break;
    }
  }
  return _state;
}

function checkTypeBasic(keys, name, value) {
  let error = null;
  
  switch (keys[0]) {
  case 'name':
    switch (name) {
    case 'ja':
      error = validation(value, {require: true, max: 100});
      break;
    case 'en':
      error = validation(value, {max: 100});
      break;
    default:
      break;
    }
    break;
  case 'link':
    error = validation(value, {max: 500});
    break;
  case 'zip':
    error = validation(value, {require: true, numeric: true, max: 4});
    break;
  case 'address':
    switch (name) {
    case 0:
      error = validation(value, {require: true, max: 100});
       break;
    case 1:
      error = validation(value, {max: 100});
      break;
    default:
      break;
    }
    break;
  case 'contact':
    switch (keys[2]) {
    case 'tel':
    case 'fax':
      error = validation(value, {numeric: true, max: 4});
      break;
    default:
      switch (name) {
      case 'department':
        error = validation(value, {max: 50});
        break;
      default:
        break;
      }
    }
    break;
  case 'staff':
    error = validation(value, {max: 50});
    break;
  default:
    error = true;
    break;
  }

  return error;
}

function reducerTypeOriginal(state, action) {
  reducerTypeBasic(state.original, action.value);
  return state;
}

function reducerTypeAnnex(state, action) {
  const {behavior = null, index = null, value = null} = action;
  const _state = state.annex;

  switch (behavior) {
  case 'add':
    _state.push(cloneDeep(initialBasicState));
    break;
  case 'remove':
    _state.splice(index, 1);
    break;
  default:
    reducerTypeBasic(_state[index], value);
    break;
  }

  return state;
}

function  reducerTypeNotification(state, action) {
  const {behavior = null, index = null, name = null, value = null} = action;
  const _state = state.notification;

  switch (behavior) {
  case 'add':
    _state.push(cloneDeep(initialNotificationState));
    break;
  case 'dnd':
    state.notification = value;
    break;
  default:
    _state[index][name].value = value;
    _state[index][name].error = checkTypeNotification(name, value);
    break;
  }

  return state;
}

function checkTypeNotification(name, value) {
  let error = null;
  switch (name) {
  case 'name':
    error = validation(value, {require: true, max: 100});
    break;
  case 'email':
    error = validation(value, {require: true, email: true, max: 100});
    break;
  default:
    error = true;
    break;
  }

  return error;
}

function  reducerTypeAdmin(state, action) {
  const {name = null, value = null} = action;
  const _state = state.admin;

  _state[name].value = value;
  _state[name].error = checkTypeAdmin(name, value);
  return state;
}

function checkTypeAdmin(name, value) {
  let error = null;
  switch (name) {
  case 'name':
  case 'nameKana':
    error = validation(value, {require: true, max: 100});
    break;
  case 'email':
    error = validation(value, {require: true, email: true, max: 100});
    break;
  default:
    error = true;
    break;
  }

  return error;
}

function  reducerTypeUser(state, action) {
  const {name = null, value = null, mode = null} = action;
  
  switch (name) {
  case 'role':
    state[name].value = value;
    // 管理者権限に変更した場合、不要な情報をクリアする
    if ('admin' === value) {
      state.original = cloneDeep(initialBasicState);
      state.annex = [];
      state.notification = [];
    }
    break;
  case 'rePassword':
    state.password.confirm = value;
    state.password.error = checkTypeUser(name, state.password);
    break;
  default:
    state[name].value = value;
    state[name].error = checkTypeUser(name, value, mode);
    break;
  }

  return state;
}

function checkTypeUser(name, value, mode = null) {
  let error = null;
  switch (name) {
  case 'id':
    error = validation(value, {require: true, alphaNumId: true, max: 40});
    break;
  case 'name':
    error = validation(value, {require: true, max: 100});
    break;
  case 'password':
    if ('register' === mode) {
      error = validation(value, {require: true, alphaNum: true, min: 6, max: 20});
    } else {
      error = validation(value, {alphaNum: true, min: 6, max: 20});
    }
    break;
  case 'rePassword':
    const {value: password, confirm} = value;
    if (password !== confirm) {
      error = 'パスワードが一致しません。';
    }
    break;
  default:
    error = true;
    break;
  }

  return error;
}

function  reducerTypeCustom(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;
  state[name].error = checkTypeCustom(name, value);
  return state;
}

function checkTypeCustom(name, value) {
  let error = null;
  switch (name) {
  case 'note':
    error = validation(value, {max: 1000});
    break;
  default:
    error = true;
    break;
  }

  return error;
}

export async function checkError(state, mode, _id) {
  const _state = cloneDeep(state);
  const role = state.role.value;
  let error = false;

  // アカウント
  const user = ['id', 'name', 'password'];
  for (const key of user) {
    const item = _state[key];
    item.error = checkTypeUser(key, item.value, mode);
    
    if (null !== item.error) {
      error = true;
    }
  }

  // 管理者
  const admin = ['name', 'nameKana', 'email'];
  for (const key of admin) {
    const item = _state.admin[key];
    item.error = checkTypeAdmin(key, item.value);
    
    if (null !== item.error) {
      error = true;
    }
  }

  // 拡張データ
  const custom = ['note'];
  for (const key of custom) {
    const item = _state[key];
    item.error = checkTypeCustom(key, item.value);
    
    if (null !== item.error) {
      error = true;
    }
  }

  // 管理者権限以外の場合
  if ('admin' !== role) {
    
    const checkBasic = (_state) => {
      let error = false;

      const basic = ['name', 'link', 'zip', 'address', 'contact', 'staff'];
      for (const group of basic) {
        switch (group) {
        case 'contact':
          //eslint-disable-next-line
          _state[group].forEach((item, index) => {
            for (const key of ['department', 'tel', 'fax']) {
              switch (key) {
              case 'department':
                item[key].error = checkTypeBasic([group, index], key, item[key].value);
                if (null !== item[key].error) {
                  error = true;
                }
                break;
              default:
                for (const column of item[key]) {
                  column.error = checkTypeBasic([group, index, key], null, column.value);
                  if (null !== column.error) {
                    error = true;
                  }
                }
                break;
              }
            }
          });
          break;
        case 'staff':
          //eslint-disable-next-line
          _state[group].forEach((item, index) => {
            for (const key of ['position', 'nameFirst', 'nameLast', 'nameFirstKana', 'nameLastKana', 'work', 'field']) {
              item[key].error = checkTypeBasic([group, index], key, item[key].value);
              if (null !== item[key].error) {
                error = true;
              }
            }
          });
          break;
        default:
          const groupKey = {
            name: ['ja', 'en'],
            link: ['site', 'tw', 'fb', 'ig'],
            zip: [0, 1],
            address: [0, 1],
          };

          for (const key of groupKey[group]) {
            const item = _state[group][key];
            item.error = checkTypeBasic([group], key, item.value);

            if (null !== item.error) {
              error = true;
            }
          }
          break;
        }
      }

      return error;
    }

    if (checkBasic(_state.original)) {
      error = true;
    }

    for (const item of _state.annex) {
      if (checkBasic(item)) {
        error = true;
      }
    }
    
    const notification = ['name', 'email'];
    for (const item of _state.notification) {
      for (const key of notification) {
        item[key].error = checkTypeNotification(key, item[key].value);
    
        if (null !== item[key].error) {
          error = true;
        }
      }
    }
  }

  if (!error && 'register' === mode) {
    error = await checkId(_state.id);
  }

  if (!error) {
    error = await checkEmail(_state.admin.email, _id);
  }

  return {error, _state};
}

async function checkId(id) {
  if (id.error) {
    return;
  }

  const query = `{
    museumIdDuplicationCheck(id: "${id.value}")
  }`;

  const {errors, data: {museumIdDuplicationCheck = false}} = await gql.query(query);

  if (0 < errors.length || !museumIdDuplicationCheck) {
    id.error = '登録済みのIDです。';
    return true;
  }

  return false;
}


async function checkEmail(email, _id) {
  if (email.error) {
    return;
  }

  const query = `{
    museumEmailDuplicationCheck(email: "${email.value}", _id: "${_id}")
  }`;

  const {errors, data: {museumEmailDuplicationCheck = false}} = await gql.query(query);

  if (0 < errors.length || !museumEmailDuplicationCheck) {
    email.error = '登録済みのメールアドレスです。';
    return true;
  }
  
  return false;
}


const schemaBasic = `
  name {
    ja
    en
  }
  link {
    site
    tw
    fb
    ig
  }
  prefecture
  zip
  area
  address
  contact {
    department
    tel
    fax
  }
  staff {
    position
    nameFirst
    nameLast
    nameFirstKana
    nameLastKana
    work
    field
  }
`;

const schema = `
  id
  name
  role
  indent
  admin {
    name
    nameKana
    email
  }
  original {
    ${schemaBasic}
  }
  annex {
    ${schemaBasic}
  }
  notification {
    name
    email
  }
  note
`;

export async function findMe(_id) {
  const query = `{
    me {${schema}}
    tmpMuseum(baseId: "${_id}") {${schema}}
  }`;

  const {
    errors,
    data: {
      me: museum = null,
      tmpMuseum: tmp = null,
    },
  } = await gql.query(query);

  if (0 < errors.length || null === museum) {
    return {errors, museum}
  }

  if (null === tmp) {
    return {errors, museum: convertToState(museum), tmp: false};
  } else {
    return {errors, museum: diff(convertToState(tmp), museum), tmp: true};
  }
}


export async function findOne(_id) {
  const query = `{
    museum(_id: "${_id}") {${schema}}
    tmpMuseum(baseId: "${_id}") {${schema}}
  }`;

  const {
    errors,
    data: {
      museum = null,
      tmpMuseum: tmp = null,
    },
  } = await gql.query(query);

  if (0 < errors.length || null === museum) {
    return {errors, museum}
  }

  if (null === tmp) {
    return {errors, museum: convertToState(museum), tmp: false};
  } else {
    return { errors, museum: diff(convertToState(tmp), museum), tmp: true};
  }
}

function convertToState(museum) {
  const _state = cloneDeep(initialState);
  const {
    role,
    indent,
    name,
    id,
    admin,
    original,
    annex,
    notification,
    note,
  } = museum; 

  _state.role.value = role;
  _state.indent.value = indent;
  _state.name.value = name;
  _state.id.value = id;
  _state.admin.name.value = admin.name;
  _state.admin.nameKana.value = admin.nameKana;
  _state.admin.email.value = admin.email;
  _state.note.value = note;
  _state.original = convertToBasicState(original);
  
  _state.annex = annex.map((data) => {
    return convertToBasicState(data);
  });

  if (notification && 0 < notification.length) {
    _state.notification = notification.map((data) => {
      const _state = cloneDeep(initialNotificationState);
      _state.name.value = data.name;
      _state.email.value = data.email;
      return _state;
    });
  }

  return _state;
}

function convertToBasicState(data) {
  const _state = cloneDeep(initialBasicState);

  _state.name.ja.value = data.name.ja;
  _state.name.en.value = data.name.en;
  _state.link.site.value = data.link.site;
  _state.link.tw.value = data.link.tw;
  _state.link.fb.value = data.link.fb;
  _state.link.ig.value = data.link.ig;
  _state.area.value = data.area;
  _state.prefecture.value = data.prefecture;
  _state.zip[0].value = data.zip[0];
  _state.zip[1].value = data.zip[1];
  _state.address[0].value = data.address[0];
  _state.address[1].value = data.address[1];
  
  _state.contact = data.contact.map((item) => {
    const _state = cloneDeep(initialContactState);
    _state.department.value = item.department;
    _state.tel[0].value = item.tel[0];
    _state.tel[1].value = item.tel[1];
    _state.tel[2].value = item.tel[2];
    _state.fax[0].value = item.fax[0];
    _state.fax[1].value = item.fax[1];
    _state.fax[2].value = item.fax[2];
    return _state;
  });

  _state.staff = data.staff.map((item) => {
    const _state = cloneDeep(initialStaffState);
    _state.position.value = item.position;
    _state.nameFirst.value = item.nameFirst;
    _state.nameLast.value = item.nameLast;
    _state.nameFirstKana.value = item.nameFirstKana;
    _state.nameLastKana.value = item.nameLastKana;
    _state.work.value = item.work;
    _state.field.value = item.field;
    return _state;
  });

  return _state;
}

function diff(a, b) {
  if (a.name.value !== b.name) a.name.change = true;
  if (a.admin.name.value !== b.admin.name) a.admin.name.change = true;
  if (a.admin.nameKana.value !== b.admin.nameKana) a.admin.nameKana.change = true;
  if (a.admin.email.value !== b.admin.email) a.admin.email.change = true;
  if (a.note.value !== b.note) a.note.change = true;

  diffBasic(a.original, b.original);
  
  for (let i = 0, len = a.annex.length; i < len; i++) {
    if (b.annex[i]) {
      diffBasic(a.annex[i], b.annex[i]);
    }
  }

  for (let i = 0, len = a.notification.length; i < len; i++) {
    const t = a.notification[i]; 
    const {
      name = '',
      email = '',
    } = b.notification[i] || {};

    if (t.name.value !== name) t.name.change = true;
    if (t.email.value !== email) t.email.change = true;
  }

  return a;
}

function diffBasic(a, b) {
  if (a.name.ja.value !== b.name.ja) a.name.ja.change = true;
  if (a.name.en.value !== b.name.en) a.name.en.change = true;
  if (a.link.site.value !== b.link.site) a.link.site.change = true;
  if (a.link.tw.value !== b.link.tw) a.link.tw.change = true;
  if (a.link.fb.value !== b.link.fb) a.link.fb.change = true;
  if (a.link.ig.value !== b.link.ig) a.link.ig.change = true;
  if (a.prefecture.value !== b.prefecture) a.prefecture.change = true;
  if (a.zip[0].value !== b.zip[0]) a.zip[0].change = true;
  if (a.zip[1].value !== b.zip[1]) a.zip[1].change = true;
  if (a.address[0].value !== b.address[0]) a.address[0].change = true;
  if (a.address[1].value !== b.address[1]) a.address[1].change = true;
  
  for (let i = 0, len = a.contact.length; i < len; i++) {
    const t = a.contact[i]; 
    const {
      department = '',
      tel = ['', '', ''],
      fax = ['', '', ''],
    } = b.contact[i] || {};

    if (t.department.value !== department) t.department.change = true;
    if (t.tel[0].value !== tel[0]) t.tel[0].change = true;
    if (t.tel[1].value !== tel[1]) t.tel[1].change = true;
    if (t.tel[2].value !== tel[2]) t.tel[2].change = true;
    if (t.fax[0].value !== fax[0]) t.fax[0].change = true;
    if (t.fax[1].value !== fax[1]) t.fax[1].change = true;
    if (t.fax[2].value !== fax[2]) t.fax[2].change = true;
  }  
  
  for (let i = 0, len = a.staff.length; i < len; i++) {
    const t = a.staff[i]; 
    const {
      position = '',
      nameFirst = '',
      nameLast = '',
      nameFirstKana = '',
      nameLastKana = '',
      work = '',
      field = '',
    } = b.staff[i] || {};

    if (t.position.value !== position) t.position.change = true;
    if (t.nameFirst.value !== nameFirst) t.nameFirst.change = true;
    if (t.nameLast.value !== nameLast) t.nameLast.change = true;
    if (t.nameFirstKana.value !== nameFirstKana) t.nameFirstKana.change = true;
    if (t.nameLastKana.value !== nameLastKana) t.nameLastKana.change = true;
    if (t.work.value !== work) t.work.change = true;
    if (t.field.value !== field) t.field.change = true;
  }
}









export async function registerTmp(state) {
  const query = `createTmpMuseum($input: PostMuseum!) {
    createTmpMuseum(input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  return await gql.mutation(query, {input});
}



export async function register(state) {
  const query = `createMuseum($input: PostMuseum!) {
    createMuseum(input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  const {errors, data: {createMuseum = null}} = await gql.mutation(query, {input});

  let _id = null;
  if (0 === errors.length && createMuseum) {
    _id = createMuseum._id;
  }

  return {errors, _id};
}

export async function edit(state, _id) {
  const query = `updateMuseum($_id: ID!, $input: PostMuseum!) {
    updateMuseum(_id: $_id, input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  const {errors} = await gql.mutation(query, {_id, input});
  return {errors, _id};
}
