import * as storage from 'src/utils/storage';

export function convertToInput(input = {}, state = {}) {
  for (const [key, item] of Object.entries(state)) {
    if (true === Array.isArray(item)) {
      input[key] = [];
      for (let i = 0, len = item.length; i < len; i++) {
        if (true === ('value' in item[i])) {
          input[key].push(item[i].value);
        } else {
          input[key].push(convertToInput({}, item[i])); 
        }
      }
    } else if ('object' === typeof item && false === Array.isArray(item) && false === ('value' in item)) {
      input[key] = convertToInput({}, item); 
    } else {
      input[key] = item.value;
    }
  }

  return input;
}

export async function query(query = '', options = {}) {
  return await post({query: query}, options);
}

export async function mutation(query = '', variables = null, options = {}) {
  return await post({query: `mutation ${query}`, variables: variables}, options);
}

async function post(body, options) {
  const {
    token = storage.findByKey('token'),
  } = options;

  const url = '/graphql';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': token,
      },
      body: JSON.stringify(body),
    })
    .then(response => response.json());

    const {
      errors = [],
      data = {},
    } = response;

    return {
      errors: errors,
      data: data,
    };
  } catch (e) {
    return {
      errors: [e.message],
      data: {},
    };
  }
}
