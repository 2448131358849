import * as storage from 'src/utils/storage';

export default async function upload(files = [], subFile = null) {
  try {
    const url = '/upload';
    const formData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }

    if (subFile) {
      formData.append('files', subFile);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'token': storage.findByKey('token'),
      },
      body: formData,
    })
    .then(response => response.json());

    const {errors = [], data: {uploads = []}} = response;

    const _uploads = [];
    let _subUpload = null;

    if (subFile) {
      _subUpload = (uploads.splice(-1, 1))[0] || null;
    }

    files.forEach((item, index) => {
      if (null === item) {
        _uploads.push('');
      } else {
        _uploads.push((uploads[0] || null));
        uploads.shift();
      }
    });

    return {errors, data: {uploads: _uploads, subUpload: _subUpload}};
  } catch (e) {
    return {
      errors: [e.message],
      data: {},
    };
  }
}
