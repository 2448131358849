import React from 'react';
import {Link} from 'react-router-dom';
import * as Svg from 'src/components/svg';
import * as Styles from 'src/css/components/header.module.scss';

function Guest(props) {
  const {className = ''} = props;

  return (
    <header className={`${Styles.guest} ${className}`}>
      <h1><Link to="/"><Svg.Logo /></Link></h1>
    </header>
  );
}

export default Guest;
