import React, {useContext} from 'react';
import Context from './context';
import Basic from './basic';

const type = 'original';

function Original() {
  const [state, dispatch] = useContext(Context);

  return (
    <section>
      <h3>本館情報</h3>
      <Basic data={state.original} dispatch={(value) => {
        dispatch({type: type, value: value});
      }} />
    </section>
  );
}

export default Original;
