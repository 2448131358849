import React from 'react';
import {Link} from 'react-router-dom';
import * as Styles from 'src/css/components/input.module.scss';

function Button(props) {
  const {
    type = 'default',
    children = null,
    onClick = () => {},
    to = null,
  } = props;

  return (
    <div className={Styles.button}>
      {null === to ? (
        <span data-type={type} onClick={onClick}>{children}</span>
      ) : (
        <Link to={to}>{children}</Link>
      )}
    </div>
  );
}

export default Button;
