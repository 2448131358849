import cloneDeep from 'lodash.clonedeep';
import * as storage from 'src/utils/storage';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

/**
 * initial state
 */
export const initialState = {
  id: {value: '', error: null},
  password: {value: '', error: null},
};

/**
 * update state
 * @param {initialState} state
 * @param {object} action
 * @return {initialState}
 */
export function reducer(state, action) {
  const {
    name,
    value,
  } = action;
  const _state = cloneDeep(state);

  _state[name].value = value;
  _state[name].error = check(name, value);

  return _state;
}

/**
 * input check
 * @param {string} key 
 * @param {string} value
 * @return {*}
 */
function check(key, value) {
  let error = null;

  switch(key) {
  case 'id':
  case 'password':
    error = validation(value, {require: true});
    break;
  default:
    break;
  }

  return error;
}

/**
 * input error check
 * @param {initialState} state
 * @return {boolean}
 */
export function isError(state) {
  return (Object.keys(state)).some((key) => {
    return (null !== check(key, state[key].value));
  });
}

/**
 * signin
 * @param {initialState} state
 * @return {Array}
 */
export async function signin(state) {
  const query = `{
    signin(id: "${state.id.value}", password: "${state.password.value}") {
      token
      exp
    }
  }`;

  const {errors, data} = await gql.query(query);

  if (0 < errors.length || null === data.signin) {
    errors.push('signin error');
    return {errors};
  }

  const {signin: {token, exp}} = data;
  storage.setItems({token, exp});
  return {errors};
}
