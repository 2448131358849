import cloneDeep from 'lodash.clonedeep';
import * as gql from 'src/utils/gql';
import validation from 'src/utils/validation';

export const initialState = {
  text: {value: '', error: null},
  projectId: {value: null, error: null},
};

export function reducer(state, action) {
  const {type = null} = action;
  const _state = cloneDeep(state);

  switch (type) {
  case 'initialize':
    return action.initialState || cloneDeep(initialState);
  default:
    return reducerTypeDefault(_state, action);
  }
}

function reducerTypeDefault(state, action) {
  const {name = null, value = null} = action;
  state[name].value = value;
  state[name].error = checkTypeDefault(name, value);
  return state;
}

function checkTypeDefault(name, value) {
  let error = null;

  switch (name) {
  case 'text':
    error = validation(value, {require: true, max: 1000});
    break;
  default:
    error = true;
    break;
  }
  
  return error;
}

export function checkAll(state) {
  const _state = cloneDeep(state);
  let error = false;

  _state.text.error = checkTypeDefault('text', _state.value);
  return {error, _state};
}

export async function find(_id) {
  const query = `{
    questionnaire(_id: "${_id}") {
      title
      body
      type
      startAt
      endAt
      file
    }
    answer(questionnaire: "${_id}") {
      text
      projectId
    }
  }`;

  const {errors, data: {questionnaire = null, answer = null}} = await gql.query(query);
  return {errors, questionnaire, answer};
}

export async function register(questionnaire, state) {
  const query = `createAnswer($input: PostAnswer!) {
    createAnswer(input: $input) {
      _id
    }
  }`;

  const input = gql.convertToInput({}, state);
  input.questionnaire = questionnaire;

  const {errors, data: {createAnswer = null}} = await gql.mutation(query, {input});
  const _id = (createAnswer ? createAnswer._id : null);
  return {errors, _id};
}

